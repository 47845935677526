import { storage } from "../Config/Firebase-config";

export const UploadImages = async (images, folder) => {
    var data=[];
    const unresolved= images.map(async (e, index) => {
    await storage
      .ref(`ReceiptTask/${folder}/${folder}-${index}`)
      .putString(e, "data_url", { contentType: "image/jpeg" });
  return  storage
      .ref(`ReceiptTask/${folder}`)
      .child(`${folder}-${index}`)
      .getDownloadURL()
      .then((url) => {
          return url
      });
  });
  const resolved = await Promise.all(unresolved);
return [...resolved]
};


