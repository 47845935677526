import Modal from "react-modal";
import { ExportExcel } from "../../../Components/ExportExcel";
import OrderSchema from "../data/OrderSchema.json";
import { PrintButton } from "./PrintButton";
import CustomFileInput from "./CustomInputFile";
import { useContext, useRef, useState } from "react";
import { PointAuth, PointUpload } from "../../../Middleware/TamataPoint";
import { toast } from "react-toastify";
import {
  getSingleDoc,
  setDocumnet,
} from "../../../Middleware/FirebaseFunction";
import { ordersCollections, ordersTestCollections } from "../../../Config/Collections";
import { AuthContext } from "../../../Config/Context";
import { Order_status } from "../../../Assets/DataSchema/OrderStatus";
import { serverTimestamp } from "firebase/firestore";
import { generateSearchTerm } from "../helper/generateSearchTerm";
import { GetLatLong } from "../../../Helper/GetLatLong";
import ReactToPrint from "react-to-print";
import { BsFillPrinterFill } from "react-icons/bs";
import { ReceiptPrint } from "../../../Components/Layout/ReceiptPrint";
import {OrderReceipt} from "./template/OrderReceipt";
export const UploadBulkOrders = ({ isOpen, setIsOpen }) => {
  const [file, setFile] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const { currentUser, setLoading } = useContext(AuthContext);
  const refComp = useRef();

  const customStyles = {
    content: {
      width: "70%", // Set the width of the modal
      margin: "auto", // Center the modal horizontally
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      backgroundColor: "white",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Background overlay color
    },
  };

  const UploadDataHandler = async () => {
    try {
      if (file.length === 0 || isComplete) return;

      setLoading(true);
      var errors = [];
      var token = await PointAuth();
      if (!token) {
        toast.error("حدث خطا اعد التحيل");
        return;
      }
      const unresolved = file.map(async (e, index) => {
        let order = await getSingleDoc(ordersTestCollections, e.id);
        if (!order) {
          var longLat = await GetLatLong(e.location);

          await setDocumnet(ordersTestCollections, e.id, {
            city: e.city || "",
            amount: e.totalPrice || 0,
            vendor: currentUser.name || "",
            vendorId: currentUser.vendorId || "",
            phone: e.phone1.toString() || "",
            name: e.customerName || e.phone1 || "",
            lat: longLat?.lat.toString() || "",
            long: longLat?.long.toString() || "",
            image: "",
            location: e.location || "",
            entry_status: true,
            note: e.note || "",
            takeBy: currentUser.name,
            createBy: currentUser?.name || "",
            status: Order_status.processing,
            job_description: `-`,
            createAt: serverTimestamp(),
            specialPrice: 0,
            searchTerm: [
              ...generateSearchTerm(e.id),
              ...generateSearchTerm(e.phone1.toString() || ""),
            ],
          });
        }

        var raw = JSON.stringify({
          orderId: e.id.trim(),
          merchant:currentUser.name,
          cost: Number(e.totalPrice) || 0,
          phone: `+964${ e.phone1.toString() || ""}`,
          userName: e.customerName || e.phone1 || "",
          latitude:longLat?.lat.toString() || "",
          longitude: longLat?.long.toString() || "",
          address:e.location || "",
          city: e.city || "",
          jobDescription: `false`,
          note: "",
          shippingFee:
          currentUser.city == e.city
          ? currentUser.in_price
          : currentUser.out_price,
          isPickup: false,
        });
        var requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_LINEOPS_SECRET_POINT}`,
            "Content-Type": "application/json",
          },
          body: raw,
          redirect: "follow",
        };
        let response = await fetch(
          "https://us-central1-tamata-points.cloudfunctions.net/lineOpsStaging/line/fillOrder",
          requestOptions
        );
        let res = await response.json();

        toast.success(index + 1);
      });
      const resolved = await Promise.all(unresolved);
      if (errors.length !== 0) ExportExcel([...errors], file.name);
      setIsComplete(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Server Error");
      return;
    }
  };
const handelClose=()=>{
    if (window.confirm("Are you Sure")) {
    setIsOpen(false)
  };
}
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      contentLabel="Example Modal"
      style={customStyles}
    >
      <div>
        <div className="Modal_header">
          <div className="title">
            <span> Bulk import</span>
          </div>
          <div style={{ display: "flex" }}>
            <button
              className="gray_button"
              onClick={() => ExportExcel(OrderSchema, "OrderSchema")}
            >
              Download Template
            </button>
            <CustomFileInput setFile={setFile} vendor={currentUser.name} />
            {/* {file.length !== 0 && (
              <button
                className="green_button"
                onClick={() => UploadDataHandler()}
              >
                Upload Data
              </button>
            )} */}
            {file.length !== 0 &&  (
              <ReactToPrint
                trigger={() => (
                  <div className="print_button">
                    <div>
                      <BsFillPrinterFill />
                    </div>
                    <div>Print</div>
                  </div>
                )}
                content={() => refComp.current}
              />
            )}
          </div>
          <img
            alt=""
            onClick={() => handelClose()}
            width="20"
            height="30"
            src="/Images/close_modal.svg"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Order Id</th>
                <th>Customer Name</th>
                <th>Customer Phone1</th>
                <th>Customer Phone2</th>
                <th>Total Price</th>
                <th>Location</th>
                <th>City</th>
                <th>Qty</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {file.length !== 0 &&
                file.map((e, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{e.barcode}</td>
                    <td>{e.customerName}</td>
                    <td>{e.phone1}</td>
                    <td>{e.phone2}</td>
                    <td>{e.totalPrice}</td>
                    <td>{e.location}</td>
                    <td>{e.city}</td>
                    <td>{e.qty}</td>
                    <td>{e.note}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div id="" ref={refComp}>
        {file.map((e, index) => (
          <OrderReceipt
            key={index}
            task={{
              ...e,
              phone: e.phone1,
              amount: e.totalPrice,
              vendor: currentUser.name,
            }}
            vendorNumber={currentUser.phone||""}
            id={e.barcode}
          />
        ))}
      </div>
    </Modal>
  );
};
