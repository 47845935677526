import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router";
import SideBar from "../../Components/Layout/Sidebar";
import { AuthContext } from "../../Config/Context";
import { Roles } from "../../Config/Role";
import { DataEntry } from "../DataEntry/index";
import { PrivateRoute } from "../../Routes/PrivateRoute";
import Accounting from "../Accounting";
import Home from "../Home";
import { Operation } from "../Operation";
import { VendorsPayments } from "../Payments";
import { PickUp } from "../PickUp";
import { Vendors } from "../Vendors";
import { Archive } from "../Archive/Archive";
import { GlobalBill } from "../GlobalBill";
import { Agents } from "../Agents";
import {Return} from "../Return"
import Draw from "../Draw/Draw";
import {PrintReceipt} from "../PrintReceipt";
import {AccountStatement} from "../AccountStatment";
import {Barcodes} from "../Barcodes";
import {VendorPortal} from "../VendorPortal";
import {ManageAgent} from "../ManageAgent";

const Dashboard = () => {
  // const {  currentUser } = useContext(AuthContext);

  return (
    <>
         <div className="App">
        { <SideBar />}
        <div className="main_contnet">
          <Routes>
            <Route path="/Dashbaord" element={<Home />} />
            <Route
              path="/Vendoraccounting"
              element={
                <PrivateRoute
                  roles={[Roles.Admin, Roles.Accounting, Roles.Viewer]}
                >
                  <Accounting />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage_vendors"
              element={
                <PrivateRoute
                  roles={[
                    Roles.Admin,
                    Roles.Accounting,
                    Roles.Viewer,
                    Roles.Operation,
                  ]}
                >
                  <Vendors />
                </PrivateRoute>
              }
            />
            <Route
              path="/VendorsPayment"
              element={
                <PrivateRoute
                  roles={[Roles.Admin, Roles.Accounting, Roles.Viewer]}
                >
                  <VendorsPayments />
                </PrivateRoute>
              }
            />
            <Route
              path="/complete_pickup"
              element={
                <PrivateRoute
                  roles={[
                    Roles.Admin,
                    Roles.Accounting,
                    Roles.Viewer,
                    Roles.Operation,
                  ]}
                >
                  <PickUp />
                </PrivateRoute>
              }
            />
            <Route path="/operation/*" match element={<Operation />} />
            <Route path="/Data_Entry/*" match element={<DataEntry />} />
            <Route path="/Archive" element={<Archive />} />
            <Route path="/GlobalBill" element={<GlobalBill />} />
            <Route path="/Agents" element={<Agents />} />
            <Route path="/Agents/draw" element={<Draw />} />
            <Route path="/Return" element={<Return />} />
            <Route path="/Print/*" element={<PrintReceipt />} />
            <Route path="/Statement_Account/*" element={<AccountStatement />} />
            <Route path="/Barcodes/*" element={<Barcodes />} />
            <Route path="/vendor/*" element={<VendorPortal />} />
            <Route path="/ManageAgent" element={<ManageAgent />} />
          </Routes>
        </div>

      </div>
    </>
  );
};
export default Dashboard;
