import dayjs from "dayjs";
import {forwardRef} from "react";
import DatePicker from "react-datepicker";
import {FaFilter} from "react-icons/fa";

export const DatePickerOrder = ({startDate,endDate,setDateRange}) => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="date_filter" onClick={onClick} ref={ref}>
        <span>
          <FaFilter />
        </span>
        {dayjs(startDate).format("YYYY-MM-DD")===dayjs(new Date()).format("YYYY-MM-DD")||dayjs(endDate).format("YYYY-MM-DD")===dayjs(new Date()).format("YYYY-MM-DD") ?
        <span>Date Filter</span>
        :
        <span>From <span style={{fontWeight:300,color:"#bbb"}} >{dayjs(startDate).format("YYYY-MM-DD")}</span>-TO <span style={{fontWeight:300,color:"#bbb"}}>{dayjs(endDate).format("YYYY-MM-DD")}</span></span>
        }
      </div>
      ));
    return(
        <>
         <DatePicker
              customInput={<ExampleCustomInput />}

    className="datepicker-here form-control"
    selectsRange={true}
    startDate={startDate}
    endDate={endDate}
    onChange={(update) => {
        setDateRange(update);
    }}
    autoFocus={true}
    withPortal
    placeholderText="From - To"
    // isClearable={true}
    // open={true}
  />
        </>
    );
};
