import { create } from "zustand";

export const VendorStatus={
  New:"New",
  Approved:"Approved",
  Declined:"Declined",
  Disabled:"Disabled",

}


export const vendorRegistrationModalSteps={
  pendingRegistrationRequest:"PENDING_REGISTRATION_REQUEST",
  approveRegistration:"APPROVE_REGISTRATION"
}

export const manageVendorStore = create((set, get) => ({
 vendorModalStep:vendorRegistrationModalSteps.pendingRegistrationRequest,
setVendorModalStep:(payload)=>{
  console.log(payload)
  set({vendorModalStep:payload})
},
vendorRegistrationModalOpen:false,
setVendorRegistrationModalOpen:(payload)=>set({vendorRegistrationModalOpen:payload}),
vendors:[],
setVendors:(payload)=>set({vendors:payload}),
vendorApproveDetails:{},
setVendorApproveDetails:(payload)=>set({vendorApproveDetails:payload})
}));

