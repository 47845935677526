import { limit, orderBy } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Order_status } from "../../Assets/DataSchema/OrderStatus";
import { ordersCollections } from "../../Config/Collections";
import { LimitPagination } from "../../Middleware/FirebaseFunction";
import { ReturnModal } from "./ReturnModal";
import { onSnapshot, query, serverTimestamp, where } from "firebase/firestore";
import {CurrencyIqFormat} from "../../Components/Input/CurrencyIqFormat";
import dayjs from "dayjs";

export const Return = () => {
  const [open, setOpen] = useState(false);
  const [ordersReturn, setOrdersReturn] = useState([]);
  const [search, setSearch] = useState("");

  const getData = async () => {
    const q = query(
      ordersCollections,
      where("status", "==", Order_status.return_vendor),
      orderBy("updateAt", "asc"),
      limit(100)
    );

    const unsubscriber = onSnapshot(q, (snapshot) => {
      var data = [];
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      console.log(data);
      setOrdersReturn(data);
    });
    return unsubscriber;
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div>
        <div className="Header_wrapper">
          <div className="flex">
            <p>Return</p>
          </div>

          <div className="flex">
            <p className="pending" onClick={() => setOpen(true)}>
              Create Return
            </p>
          </div>
        </div>
        <div className="payments_conatiner">
          <div className="table_container">
            <div className="thead">
              <span className=""></span>
              <span className="small_width">#</span>
              <span>Order ID</span>
              <span>Location</span>
              <span>City</span>
              <span className="small_width">Amount</span>
              <span className="small_width">Upload At</span>
              <span className="small_width">status</span>
            </div>
            {ordersReturn.length != 0 ? (
              ordersReturn
                .filter((e) => e.id.includes(search || ""))
                .map((r, index) => (
                  <div className="tbody" key={index * Math.random()}>
                    {/* <span>
                      <input
                        type="checkbox"
                        checked={r.isSelected}
                        value={r.isSelected || false}
                        onChange={(d) =>
                          setOrdersReturn((prevState) => {
                            prevState[
                              prevState.findIndex((c) => r.id == c.id)
                            ].isSelected = d.target.checked;
                            console.log(prevState);
                            return [...prevState];
                          })
                        }
                      />
                    </span> */}
                    <span className="small_width">{index + 1}</span>
                    <span>{`${r.vendor}-${r.id}`}</span>
                    <span>{r.location}</span>
                    <span>{r.city}</span>
                    <span className="small_width">
                      {<CurrencyIqFormat val={r.amount} />}
                    </span>
                    <span className="small_width">
                      {r.updateAt &&dayjs(r.updateAt.seconds * 1000).format("YYYY-MM-DD") ||
                        " "}
                    </span>

                    <span className="small_width alert">{r.status}</span>
               
               
                  </div>
                ))
            ) : (
              <div>No Orders</div>
            )}
          </div>
        </div>
      </div>
      {open && <ReturnModal open={open} setOpen={setOpen} />}
    </>
  );
};
