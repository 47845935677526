import React, { useRef } from "react";
import { ReadExcel } from "../../../Components/ReadExcel";
import Provinces from "../../../Assets/DataSchema/Provinces.json";
import dayjs from "dayjs";
import {isValidAmount, isValidLocation, isValidPhoneNumber} from "./validations";
import { toast } from "react-toastify";

function CustomFileInput({ setFile,vendor }) {
  const fileInputRef = useRef(null);

  const handleFileInputChange = () => {
    fileInputRef.current.click();
  };
const checkValidate=(file)=>{
  if(!file||file.filter(e=>!isValidAmount(e.totalPrice)).length!==0) return false;
  return true;
}
  const handleFileSelected = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = selectedFile.type;
      if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        ReadExcel(e, async (myFile) => {
          console.log(checkValidate(myFile))
         if(!checkValidate(myFile)) {
          toast.error("يرجى ادخال البيانات بشكل صححيح");
          return;
        };

          myFile = myFile.map((r,index) => ({
            ...r,
            barcode:`${vendor.slice(0,4)}-${r.barcode}`,
            city:r.city ||
              Provinces.filter((e) => r.location?.includes(e.label))[0]
                ?.value ||
              
              "",
          }));
          setFile([...myFile]);
        });
        console.log("Selected Excel File:", fileName);
      } else {
        alert("Please select a valid Excel file.");
      }
    }
  };

  return (
    <div className="custom-file-input">
      <div onClick={handleFileInputChange} className="custom-button">
        Choose File
      </div>
      <input
        type="file"
        accept=".xlsx, .xls" // Specify accepted file types for Excel
        ref={fileInputRef}
        onChange={handleFileSelected}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default CustomFileInput;
