import dayjs from "dayjs";
import {  useContext, useState } from "react";
import Button from "../../Components/Button/Button";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import customStyles from "../../Assets/styles/invoiceModal.json";
import Modal from "react-modal";
import { increment } from "firebase/firestore";
import {
  PaymentsCollections,
  ordersCollections,
  vendorCodCollection,
} from "../../Config/Collections";
import {
  updateMultiDoc,
} from "../../Middleware/FirebaseFunction";
import { PrintButton } from "../../Components/Button/PrintButton";
import { AuthContext } from "../../Config/Context";
import { PaymentPrint } from "./PaymentPrint";
import { toast } from "react-toastify";
import { WebcamCapture } from "../../Components/WebcamCapture";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { Permission_type } from "../../Config/Permission";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { BsFileEarmarkExcel } from "react-icons/bs";
import { ExportExcel } from "../../Components/ExportExcel";
import { Order_status } from "../../Assets/DataSchema/OrderStatus";
import { InvoiceSchema } from "../../Assets/DataSchema/InvoiceSchema";
import { paidPaymentAPI } from "../../Middleware/PaidAPI";

export const Invoice = ({ setIsOpen, isOpen, payments }) => {
  const { setLoading } = useContext(AuthContext);

  const [showImage, setShowImage] = useState(false);

  const [imgUrl, setImageUrl] = useState("");

  const refuseInvoice = async (action) => {
    if (window.confirm("Are you Sure")) {
      setLoading(true);
      await updateMultiDoc(PaymentsCollections, payments.id, {
        paid: InvoiceSchema.return,
      });
      const unresolved = payments.orders.map(async (e) => {
        await updateMultiDoc(ordersCollections, e.barcode, {
          status: Order_status.cod,
        });
      });
      const resolved = await Promise.all(unresolved);
      await updateMultiDoc(vendorCodCollection, payments.name, {
        value: increment(payments.orders.length),
      });
      toast.success("الرجاء الاتنتظار");
    }
    setLoading(false);
    setIsOpen(false);
  };

console.log(imgUrl)
  const orderPaid =async(pinCodeMethod) => {
    if(pinCodeMethod){
      const verificationCode=window.prompt("Please Enter Verification Code");
      if(verificationCode!==payments.paymentVerificationCode){
        toast.error("Verification Code is incorrect");
        return;
      }
    }
    if(!imgUrl&&!pinCodeMethod) return;
    
    console.log("done");
      setLoading(true);
      const response = await paidPaymentAPI({
        paymentId: payments.id,
        image: imgUrl||"",
      });
      console.log(response)
      toast.success("Payment Paid");
      setLoading(false);
      setIsOpen(false);
  };
  const Print = () => {
    var content = document.getElementById("Invoice_print");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  const ExportData = () => {
    ExportExcel([
      ...payments.orders.map((e) => ({
        barcode: e.barcode,
        amount: e.amount,
        city: e.city,
        agent_received: e.agent_received,
        shipping: e.shipping,
        job_address: e.job_address,
        customer_phone: e.customer_phone,
        order_date: e.createAt,
      })),
    ]);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        <PaymentPrint
          vendorPrice={payments.vendor}
          vendor={payments.vendor}
          id={payments.id}
          status={payments.paid}
          vendorOrders={payments.orders}
        />
        <div className="modal_container">
          <div className="close_img">
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              alt="close"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="payment_modal" style={{
            display:"flex",
            justifyContent:"space-around"
          }}>
            <div>
              <p>Invoice ID : {payments.id}</p>
              <p>Create At : {payments.createAt}</p>
            </div>
            <div>
              {payments.paid != "delete" && !payments.paid ? (
                <>
                  <PermissionsGate permission={Permission_type.approve_payment}>
                    <div className="payment_actions">
  
                      <Button
                        style="button"
                        color="c8597A5"
                        title="Retrun"
                        onclick={() => refuseInvoice("return")}
                      />
                      {!payments.paid&&imgUrl  && (
                        <Button
                          style="button"
                          color="c70b44d"
                          title="Confirm"
                          onclick={() => orderPaid(false)}
                        />
                      )}
                      {!payments.paid&&!imgUrl  && (
                        <Button
                          style="button"
                          color="c70b44d"
                          title="Confirm By Barcode"
                          onclick={() => orderPaid(true)}
                        />
                      )}
                    </div>
                  </PermissionsGate>
                </>
              ) : (
                <>
                  {payments.paid == true ? (
                    <div className="payment_actions">
                      <Button
                        style="button"
                        color="c8597A5"
                        title={`${showImage ? "Close" : "Show"} Image`}
                        onclick={() => setShowImage((r) => !r)}
                      />
                      {showImage && (
                        <PhotoProvider>
                          <PhotoView src={payments.payment_url}>
                            <img src={payments.payment_url} alt="" />
                          </PhotoView>
                        </PhotoProvider>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        margin: "10px",
                        color: "red",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Payment is {payments.paid}
                    </div>
                  )}
                </>
              )}

    
            </div>
          </div>
          {!payments.paid && (
                <>
                <WebcamCapture
                  setImageUrl={setImageUrl}
                  imgUrl={imgUrl}
                   isWebcamOpen={true}
                />
                </>
              )}
          <PermissionsGate permission={Permission_type.approve_payment}>
            <PrintButton onclick={() => Print()} />
            <BsFileEarmarkExcel
              onClick={() => ExportData()}
              fill="green"
              size={"30px"}
              style={{ cursor: "pointer", margin: "3px" }}
            />
          </PermissionsGate>

          <div style={{ marginTop: "40px" }}>
            <table style={{ width: "100%" }}>
              <thead style={{ fontSize: "12px" }}>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                      border: "1px solid #f3f2f2",
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                      border: "1px solid #f3f2f2",
                    }}
                  >
                    Order Id
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                      border: "1px solid #f3f2f2",
                    }}
                  >
                    Paid Amount
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                      border: "1px solid #f3f2f2",
                    }}
                  >
                    Shipping
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                      border: "1px solid #f3f2f2",
                    }}
                  >
                    City
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                      border: "1px solid #f3f2f2",
                    }}
                  >
                    Total Paid
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                      border: "1px solid #f3f2f2",
                    }}
                  >
                    Comment
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "12px" }}>
                {payments.orders.map((e, index) => (
                  <tr key={index}>
                    <td style={{ padding: "2px", border: "1px solid #f3f2f2" }}>
                      {dayjs(new Date(e.createAt.seconds * 1000)).format(
                        "YYYY-MM-DD"
                      )}
                    </td>
                    <td style={{ padding: "2px", border: "1px solid #f3f2f2" }}>
                      {e.barcode}
                    </td>
                    <td style={{ padding: "2px", border: "1px solid #f3f2f2" }}>
                      {<CurrencyIqFormat val={e.agent_received || 0} />}
                    </td>
                    <td style={{ padding: "2px", border: "1px solid #f3f2f2" }}>
                      {<CurrencyIqFormat val={e.shipping} />}
                    </td>
                    <td style={{ padding: "2px", border: "1px solid #f3f2f2" }}>
                      {e.city}
                    </td>

                    <td style={{ padding: "2px", border: "1px solid #f3f2f2" }}>
                      {
                        <CurrencyIqFormat
                          val={e.agent_received - parseInt(e.shipping)}
                        />
                      }
                    </td>
                    <td style={{ padding: "2px", border: "1px solid #f3f2f2" }}>
                      {e.specialPrice > 900 && "Big Order"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};