var paymentStatus={
  true:"Paid",
  false:"Un Paid",
  delete:"Delete",
  return:"Return",
}
export const PaidStatus = ({text}) => {
  return (
    <>
      <span className={`${text}`}>{paymentStatus[text]}</span>
    </>
  );
};
