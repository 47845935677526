import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../Components/ui/table";
import { Badge } from "../../../Components/ui/badge";
import { AgentStatus, manageAgentStore } from "../store";
import { cn, getAgentStatusStyle } from "../../../lib/utils";
import { Button } from "../../../Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../Components/ui/dropdown-menu";
import { YesOrNo } from "../../../Components/ui/YesOrNo";
import { approveAgent } from "../../../Services/Mutations";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Loader2 } from "lucide-react";
import {AgentActionDropMenu} from "./AgentActionDropMenu";

export const AgentTable = () => {
  const { allAgents, setRefetchGetAllAgent, refetchGetAllAgent } =
    manageAgentStore();

  const approveAgentMutation = useMutation({
    mutationFn: approveAgent,
    onSuccess: () => {
      console.log(refetchGetAllAgent)
      setRefetchGetAllAgent(!refetchGetAllAgent);
      toast.success("Agent Approved Successfully");
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        "An error occurred while processing your request.";
      toast.error(errorMessage);
    },
  });

  return (
    <>
      <div className="flex items-start  overflow-y-auto w-full border-2 ">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>PhoneNumber</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>City</TableHead>
              <TableHead>VehicleType</TableHead>

              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {allAgents?.map((e, index) => (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                <TableCell>{e.name}</TableCell>
                <TableCell>{e.phoneNumber}</TableCell>
                <TableCell>{e.city}</TableCell>
                <TableCell>
                  <Badge className={cn(getAgentStatusStyle(e.status))}>
                    {e.status}
                  </Badge>
                </TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline">Actions</Button>
                    </DropdownMenuTrigger>
           <AgentActionDropMenu agent={e} key={index}/>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
