import React from "react";
import NumberFormat from "react-number-format";

export const CurrencyInput = ({
  value=0,
  defaultValue=0,
  onchange,
  style ,
  disabled,
  suffix="IQD"
}) => {
  return (
    <NumberFormat
      disabled={disabled || false}
      defaultValue={defaultValue}
      value={value}
      onValueChange={onchange}
      thousandSeparator={true}
      prefix={`${suffix}  `}
      // suffix={` ${suffix}`}
      className={style}
    />
  );
};