import React from "react";
import { Route, Routes } from "react-router-dom";
import { Spinner } from "./Components/Spinner";
import { Toast } from "./Components/Toast";
import {  AuthProvider } from "./Config/Context";
import Dashboard from "./Pages/Dashboard/index";
import Login from "./Pages/Login";
import {ReceiptsEntry} from "./Pages/Operation/ReceiptsEntry";
import { QueryClientProvider } from "@tanstack/react-query";
import {queryClient} from "./lib/react-query";


function App() {
//a
  return (
    <>
      <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Toast />
        <Spinner />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="tamata/receipts_entry" element={<ReceiptsEntry />} />  
          <Route path="/*" element={<Dashboard />} />
        </Routes>
        </QueryClientProvider>

        {/* <Footrer /> */}
      </AuthProvider>
    </>
  );
}

export default App;
