import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { entryArchive, vendorCollection } from "../../Config/Collections";
import {
  getSingleDoc,
  updateDocument,
  updateMultiDoc,
} from "../../Middleware/FirebaseFunction";
import dayjs from "dayjs";
import { AuthContext } from "../../Config/Context";
import { onSnapshot, query, where } from "firebase/firestore";
import { Permission_type } from "../../Config/Permission";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { TimeDelayH } from "../../Helper/DateTime";

export const PendingReceipt = ({ isOpen, setIsOpen, setTasks, setBatID ,setVendorInfo}) => {
  const { setLoading } = useContext(AuthContext);
  const [receipt, setReceipts] = useState([]);

  useEffect(() => {
    const q = query(entryArchive, where("entry_complete", "==", false));
    const unsubscriber = onSnapshot(q, (snapshot) => {
      var myData = [];
      snapshot.docs.map((doc) => {
        myData.push({ ...doc.data(), id: doc.id });
      });
      console.log([...myData]);
      setReceipts([...myData]);
    });
    return unsubscriber;
  }, []);
  const handelStart = async (Batchs) => {
    try {
      console.log(Batchs)
      setLoading(true);
      setBatID(Batchs.id);
      console.log(Batchs);
      var data = Batchs.completeData.filter(e=>!e.entry_status).map((e, index) => ({
        ...e,
        vendorId:Batchs.vendorId||"",
        isSpecial: Batchs.specialOrder.isSpecial,
        specialPrice: Batchs.specialOrder.specialPrice,
        vendor: Batchs.vendor,
        takeBy:Batchs.createBy
      }));
      let vendor=await getSingleDoc(vendorCollection,Batchs.vendorId);
      
      setVendorInfo(vendor)
      setTasks([...data]);
      if(!Batchs.startAt){
        await updateDocument(
          entryArchive,
          Batchs.id,
          "startAt",
          dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss")
        );

      }
      setLoading(false);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      return;
    }
  };
  const handelDisable = async (e) => {
    await updateMultiDoc(entryArchive, e.id, {
      entry_complete: true,
    });
  };
  return (
    <>
      <Modal isOpen={isOpen} ariaHideApp={false} contentLabel="Example Modal">
        <div>
          <div className="Modal_header">
            <div className="title">
              <span> Pending Receipt Task</span>
            </div>
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              alt="close_icon"
              height="30"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="payments_conatiner">
            <div className="table_container">
              <div className="thead">
                <span className="small_width">#</span>
                <span className="small_width">ID</span>
                <span className="small_width">Total Task</span>
                <span className="small_width">Total Task Done</span>
                <span className="small_width">Create By</span>
                <span className="small_width">Delay</span>
                <span className="small_width">Status</span>
                <PermissionsGate permission={Permission_type.disable_entry}>
                  <span className="small_width"></span>
                </PermissionsGate>
                <span className="small_width"></span>
              </div>
              {receipt.length !== 0 ? (
                receipt.map((e, index) => (
                  <div className="tbody" key={index + 1}>
                    <span className="small_width">{index + 1}</span>
                    <span className="small_width">{e.id}</span>
                    <span className="small_width">{e.completeData.length}</span>
                    <span className="small_width">
                      {e.completeData.filter((e) => e.entry_status).length}
                    </span>
                    <span className="small_width">{e.createBy}</span>
                    <span className="small_width">
                      {TimeDelayH(new Date(), e.createAt)} Hours
                    </span>
                    <span
                      className={`${
                        e.startAt ? "started" : "Pending"
                      } small_width`}
                    >
                      {e.startAt ? "Started" : "Pending"}
                    </span>
                    <PermissionsGate permission={Permission_type.disable_entry}>
                      <span
                        className="small_width green"
                        onClick={() => {
                          handelDisable(e);
                        }}
                      >
                        Disable
                      </span>
                    </PermissionsGate>
                    <PermissionsGate permission={Permission_type.start_entry}>
                      <span
                        className="small_width green"
                        onClick={() => {
                          handelStart(e);
                        }}
                      >
                        Start
                      </span>
                    </PermissionsGate>
                  </div>
                ))
              ) : (
                <div>No Orders</div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
