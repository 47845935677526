import {useMutation, useQuery} from "@tanstack/react-query";
import { Dialog, DialogContent } from "../../../Components/ui/dialog";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../../../Components/ui/table"
import {useContext, useState} from "react";
import {AuthContext} from "../../../Config/Context";
import {fetchAllVendors} from "../../../Services/Fetchers";
import {VendorStatus, manageVendorStore, vendorRegistrationModalSteps} from "../store";
import {cn, getVendorStatusStyle} from "../../../lib/utils";
import {Badge} from "../../../Components/ui/badge";
import {YesOrNo} from "../../../Components/ui/YesOrNo";
import {Button} from "../../../Components/ui/button";
import {declineVendor} from "../../../Services/Mutations";
import {toast} from "react-toastify";
import {Loader2} from "lucide-react";
export const PendingRegistrationRequest=()=>{
  const {setLoading}=useContext(AuthContext);
  const {setVendorModalStep,setVendorApproveDetails}=manageVendorStore();
  const [vendors,setVendors]=useState([]);

  const { refetch } = useQuery({
    queryKey: ["fetchVendors"],
    queryFn: async () => {
      console.log("done")
      setLoading(true);
      const response = await fetchAllVendors(0, 50,VendorStatus.New);
      console.log(response)
    
      if (response.length == 0) {
        setLoading(false)
        return "Error";
      }
      setVendors(response.data);
      setLoading(false)
      return response;
    },
  });
const declineVendorMutation = useMutation({
  mutationFn: declineVendor,
  onSuccess: () => {
    toast.success("Vendor Decline Successfully");
    refetch();
  },
  onError: (error) => {
    const errorMessage =
      error?.response?.data?.message ||
      "An error occurred while processing your request.";
    toast.error(errorMessage);
  },
});
const handleApprove=(vendor)=>{
  setVendorModalStep(vendorRegistrationModalSteps.approveRegistration);
  setVendorApproveDetails(vendor)
  
}
    return(
        <>
              <DialogContent className={"lg:max-w-screen-lg overflow-y-scroll max-h-screen "}>
              <div className="flex items-start  overflow-y-auto w-full border-2  m-2">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>
              <TableHead>English Name</TableHead>
              <TableHead>Arabic Name</TableHead>
              <TableHead>Phone Number</TableHead>
              <TableHead>City</TableHead>
              <TableHead>NearSet Point</TableHead>
              <TableHead>Status</TableHead>

              <TableHead></TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {vendors?.map((e, index) => (
              <TableRow key={index}>
                <TableCell>{index+1}</TableCell>
                <TableCell>{e.englishName}</TableCell>
                <TableCell>{e.arabicName}</TableCell>
                <TableCell>{e.phoneNumber }</TableCell>
                <TableCell>{e.city}</TableCell>
                <TableCell>{e.nearestPoint}</TableCell>
                <TableCell>
                  <Badge className={cn(getVendorStatusStyle(e.status))}>
                    {e.status}
                  </Badge>
                </TableCell>
                <TableCell>
             <Button className="bg-green-700" onClick={()=>handleApprove(e)}>Approve</Button>
                </TableCell>
                <TableCell>
                  
                  <YesOrNo
                  question="Are You Sure ?"
                  information={`This request will be decline request`}
                  toDoText="Confirm"
                  toDoFunction={ ()=>declineVendorMutation.mutateAsync(e.id)}
                >
                  <Button className="bg-red-600" disabled={declineVendorMutation.isPending}>
                    Decline
                    {declineVendorMutation.isPending && (
                      <div className="flex h-full  items-center justify-center">
                        <Loader2 className=" animate-spin w-4 h-4 mx-2" />
                      </div>
                    )}
                  </Button>
                </YesOrNo>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
</DialogContent>

        </>
    )
}