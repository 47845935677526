import React from "react";

export const AccessDenied = () => {


  return (
    <div className="Denied_page">
      <div>
        <p>Access denied</p>
        <p>You don't have permission to access this page</p>{" "}
      </div>
    </div>
  );
};