import dayjs from "dayjs";
import "../../Assets/styles/Invoice.scss";
import { MdOutlineNotListedLocation } from "react-icons/md";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";

import { useBarcode } from "react-barcodes";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { totalPaidPrice } from "../../Middleware/OrdersCal";
export const PaymentPrint = ({
  vendorPrice,
  vendorOrders,
  id,
  status,
}) => {
  const { inputRef } = useBarcode({
    value: id,
    options: {
      background: "white",
      height: 50,
    },
  });
  return (
    <>
      <div
        style={{
          padding: "0 20px",
          width: "210mm",
          margin: "0 auto",
        }}
        id="Invoice_print"
      >
        {[true, "delete", "return"].includes(status) && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#D3D4D6",
              height: "100%",
              width: "100%",
              fontSize: "21rem",
              transform: "rotate(-45deg)",
              zIndex: "-1000",
              textTransform: "uppercase",
            }}
          >
            {status == true ? "PAID" : status}
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <p
                style={{
                  color: "#ff1d43",
                  fontWeight: "800",
                  fontSize: "42px",
                  display: "flex",
                  alignItems: "start",
                  lineHeight: "42px",
                }}
              >
                Hello
              </p>
            </div>
            <p
              style={{ color: "#ff1d43", fontWeight: "800", fontSize: "28px" }}
            >
              This is your payment Statement
            </p>
          </div>
          <div></div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span>{status == "delete" && "PAYMENT DELETE"}</span>
          <span style={{ display: `${status == "delete" && "none"}` }}>
            <svg ref={inputRef} />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          <div>
            <p>Vendor Name : {vendorPrice.value}</p>
            <p>Vendor Address :{vendorPrice.address}</p>
            <p>Vendor Phone :{vendorPrice.phone}</p>
          </div>
          <div>
            <p>Print Date :{dayjs(new Date()).format("YYYY-MM-DD")}</p>
            <p>Statement No :{id?.replace(/ /g, "")}</p>
            <p></p>
          </div>
        </div>
        <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // width: "500px",
              margin: "40px auto",
            }}
          >
            <div>
              <p style={{ marginBottom: "10px",fontWeight:900,fontSize:"20px",borderBottom:"3px solid black" }}>اسم التاجر</p>
            </div>
            <div>
            <p style={{ marginBottom: "10px",fontWeight:900,fontSize:"20px",borderBottom:"3px solid black" }}> التوقيع</p>

            </div>
            <div>
              <p style={{ marginBottom: "10px",fontWeight:900  ,fontSize:"20px"}}>تاريخ الاستلام</p>
              <span>ٍ</span>
            </div>
          </div>
        <div style={{ marginTop: "20px" }}>
          <table style={{ width: "100%", textAlign: "center" }}>
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "#EC1035",
                    color: "white",
                    padding: "2px",
                    border: "1px solid #f3f2f2",
                  }}
                >
                  #
                </th>
                <th
                  style={{
                    backgroundColor: "#EC1035",
                    color: "white",
                    padding: "2px",
                    border: "1px solid #f3f2f2",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    backgroundColor: "#EC1035",
                    color: "white",
                    padding: "2px",
                    border: "1px solid #f3f2f2",
                  }}
                >
                  Order ID
                </th>
                <th
                  style={{
                    backgroundColor: "#EC1035",
                    color: "white",
                    padding: "2px",
                    border: "1px solid #f3f2f2",
                  }}
                >
                  Paid Amount
                </th>
                <th
                  style={{
                    backgroundColor: "#EC1035",
                    color: "white",
                    padding: "2px",
                    border: "1px solid #f3f2f2",
                  }}
                >
                  Shipping
                </th>
                <th
                  style={{
                    backgroundColor: "#EC1035",
                    color: "white",
                    padding: "2px",
                    border: "1px solid #f3f2f2",
                  }}
                >
                  City
                </th>

                <th
                  style={{
                    backgroundColor: "#EC1035",
                    color: "white",
                    padding: "2px",
                    border: "1px solid #f3f2f2",
                  }}
                >
                  Total Received
                </th>
                <th
                  style={{
                    backgroundColor: "#EC1035",
                    color: "white",
                    padding: "2px",
                    border: "1px solid #f3f2f2",
                  }}
                >
                  Comment
                </th>
              </tr>
            </thead>
            <tbody>
              {vendorOrders?.map((e, index) => (
                <tr key={index}>
                  <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                    {index}
                  </td>
                  <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                    {dayjs(new Date(e.createAt.seconds*1000)).format("YYYY-MM-DD")}
                  </td>
                  <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                    {e.barcode}
                  </td>
                  <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                    {<CurrencyIqFormat val={e.agent_received || 0} />}
                  </td>
                  <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                    {<CurrencyIqFormat val={e.shipping} />}
                  </td>
                  <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                    {e.city?.length > 20 ? "" : e.city}
                  </td>
                  <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                    {
                      <CurrencyIqFormat
                        val={e.agent_received - parseInt(e.shipping)}
                      />
                    }
                  </td>
                  <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                    {e.note ? e.note : e.specialPrice > 900 && "Big Order"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <div>
                <p>
                  Total Paid Amount :
                  <CurrencyIqFormat val={totalPaidPrice(vendorOrders)} />
                </p>
              </div>
              <div>
                <p>
                  Total Shipping :
                  <CurrencyIqFormat
                    val={vendorOrders.reduce(
                      (a, b) => a + parseInt(b.shipping),
                      0
                    )}
                  />
                </p>
              </div>
              <div>
                <p>
                  Total Vendor Received :
                  <CurrencyIqFormat
                    val={
                      totalPaidPrice(vendorOrders) -
                      vendorOrders.reduce((a, b) => a + parseInt(b.shipping), 0)
                    }
                  />
                </p>
              </div>
            </div>
          </div>
          
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "50px",
          }}
        >
          <p>
            {" "}
            <span style={{ marginRight: "10px" }}>
              <MdOutlineNotListedLocation fill="red" />
            </span>
            Arasat Al Hindia, Baghdad Iraq
          </p>
          <p>
            {" "}
            <span style={{ marginRight: "10px" }}>
              <AiFillPhone fill="red" />
            </span>
            (+964) 780 423 1234
          </p>
          <p>
            <span style={{ marginRight: "10px" }}>
              <AiOutlineMail fill="red" />
            </span>
            support@tamata.com
          </p>
        </div>
        <div style={{pageBreakAfter:"always"}}>
          
        </div>
      </div>
    </>
  );
};
