import Modal from "react-modal";
import DatePicker from "react-datepicker";
import {  useContext, useEffect, useState } from "react";
import {getVendorOrdersByDate} from "../../../Middleware/FirebaseFunction";
import {AuthContext} from "../../../Config/Context";

export const DateFilterComponents = ({ isOpen, setIsOpen ,callback}) => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const {currentUser}=useContext(AuthContext)


  const customStyles = {
    content: {
      width: "50%", // Set the width of the modal
      margin: "auto", // Center the modal horizontally
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      backgroundColor: "white",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Background overlay color
    },
  };


const handelClose=()=>{
    // if (window.confirm("Are you Sure")) {
    setIsOpen(false)
  // };
}
const DownloadData=async()=>{
    let response=await getVendorOrdersByDate(currentUser.name,startDate,endDate,null,10000);
    callback(response)
    return 
}
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      contentLabel="Example Modal"
      style={customStyles}
    >
      <div>
        <div className="Modal_header">
          <div className="title">
            <span> Download Orders By Date import</span>
          </div>
          <div style={{ display: "flex" }}>
            
              <button
                className="green_button"
                onClick={() => DownloadData()}
              >
                Download Data
              </button>
          
          </div>
          <img
            alt=""
            onClick={() => handelClose()}
            width="20"
            height="30"
            src="/Images/close_modal.svg"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            margin:"20px auto",
            
        }}>
        <DatePicker
              className="datepicker-here form-control"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                  setDateRange(update);
              }}
              withPortal
              placeholderText="From - To"
              />
        </div>

        

      </div>
    
    </Modal>
  );
};
