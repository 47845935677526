import {useContext} from "react";
import {AccessDenied} from "../Components/AccessDenied ";
import {AuthContext} from "../Config/Context";
import { Navigate,  useLocation } from "react-router-dom";

export const PrivateRoute = ({ children, roles }) => {
    let location = useLocation();
    const { currentUser } = useContext(AuthContext);
    const userHasRequiredRole = currentUser && roles.includes(currentUser.role) ? true : false;
    if (!currentUser) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    if (!userHasRequiredRole) {
      return <AccessDenied state={{ from: location }} />;
    }
    return children;
  };
  ['admin','guest'].includes("admin")

