import React, { useState, useRef, useEffect, useContext } from "react";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import { ExportExcel } from "../../../Components/ExportExcel";
import { ExportCSV } from "../../../Components/ExportCSV";
import { ReadExcel } from "../../../Components/ReadExcel";
import LoadingBar from "react-top-loading-bar";
import { GetLatLong } from "../../../Helper/GetLatLong";
import { FetchAPI } from "../../../Helper/FetchAPI";
import UserSchema from "../../../Assets/DataSchema/CustomHeader.json";
import { Task } from "./Task";
import "../../../index.css";
import Select from "react-select";
import { getAllDoc, getDocumnet } from "../../../Middleware/FirebaseFunction";
import { vendorCollection } from "../../../Config/Collections";
import { custom_style_vendor } from "../../../Assets/styles/custom_select";
import {AuthContext} from "../../../Config/Context";

export const MainVendor = () => {
  const [csvFile, setCsvFile] = useState([]);
  const refComp = useRef();
  const [progress, setProgress] = useState(0);
  const [vendor, setVendor] = useState(null);
  const {vendors}=useContext(AuthContext)

  const readUploadFile = (e) => {
    e.preventDefault();
    ReadExcel(e, async (file) => {
      if (!file) return;
      file.map((obj) => {
        Object.keys(obj).forEach((key) => {
          var replacedKey = key.trim().replace(/\s\s+/g, "");
          if (key !== replacedKey) {
            obj[replacedKey] = obj[key];
            delete obj[key];
          }
        });
      });
      if (
        file.filter((e) => !e.Vname || e.Vname.length < 3 || e.Number == "")
          .length !== 0
      ) {
        toast.error("اسم التاجر لا يوجد او رقم الفاتورة خطا تاكد ");
        return;
      }
    file = file.map((e) => ({
        ...e,
        Vname: vendor ? vendor.value : e.Vname.trim(),
        vId: vendor ? vendor?.id.toString() : "",
        vendorNumber: vendor ? vendor?.phone.toString() : "",
        Number: e.Number.toString().replace(/\s/g, "").trim().toLowerCase(),
        Line_task: /^[0-9]+$/.test(
          e.Number.toString().replace(/\s/g, "").trim().toLowerCase()
        )
          ?( vendor ? vendor.value : e.Vname.trim()).toLowerCase().trim().substring(0, 3) +
            "-" +
            e.Number.toString().replace(/\s/g, "").trim().toLowerCase()
          : e.Number.toString().replace(/\s/g, "").trim().toLowerCase(),
        TotalPrice: parseInt(e.TotalPrice || e["TotalPrice "] || 0),
        lat: "",
        long: "",
        regionName: "",
      }));
      const resolved = file.map(async (item, index) => {
        let longLat = await GetLatLong(item.Location);
        let res = await FetchAPI(
          "https://api.tamata.com/v2/find_region_from_points",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              points: [
                {
                  latitude: longLat?.lat || "",
                  longitude: longLat?.long || "",
                },
              ],
              api_key: "533b38d7a41f551f4111283c10102c4418e0c4ff2cdf",
            }),
          }
        );
      if(!vendor){
        let data=vendors.find(e=>e.value.trim()==item.Vname.trim());
        file[index].vId=data?.id ||false;
        file[index].vendorNumber=data?.phone ||false;
        
      }
        var prec = (index / (file.length - 1)) * 100;
        setProgress(prec);
        if (res) {
          file[index].regionName = res.data[0]?.region_name || "";
          file[index].lat = longLat?.lat || "";
          file[index].long = longLat?.long || "";
          return;
        }
      });

      const unResolved = await Promise.all(resolved);

      console.log(file);
      setCsvFile([...file.filter(e=>e.vId)]);
    if([...file.filter(e=>!e.vId)].length!==0)  ExportExcel([...file.filter(e=>!e.vId)],"EORRR")
    });
  };

  const CreateTasks = async () => {
    if (csvFile.length === 0) return;
console.log(csvFile)
    ExportExcel(csvFile, csvFile[0].Vname + "-");
  };
  return (
    <>
      <LoadingBar
        color="#f11946"
        height={5}
        loaderSpeed={300}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content_container">
        <div className="upload_container">
          <input
            onChange={(e) => readUploadFile(e)}
            accept=".xlsx"
            id="file"
            type="file"
          />
          <button className="submit_button" onClick={() => CreateTasks()}>
            Create Excel
          </button>
          <ReactToPrint
            trigger={() => (
              <button className="submit_button">Create PDF</button>
            )}
            content={() => refComp.current}
          />
        </div>
        <div style={{ marginLeft: "40px" }}>
          <Select
            options={vendors}
            name="colors"
            value={vendor}
            onChange={(val) => {
              setVendor(val);
            }}
            isClearable={true}
            placeholder="Vendor Name"
            styles={custom_style_vendor()}
          />{" "}
        </div>
        <div
          className="download_button"
          onClick={() => ExportExcel(UserSchema, "UserSchema")}
        >
          Download Example
        </div>
      </div>
      <div id="printAble" ref={refComp}>
        {csvFile.map((e, index) => (
          <Task task={e} id={index} key={index} />
        ))}
      </div>
    </>
  );
};
