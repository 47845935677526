import React, { useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { MapContainer, TileLayer, FeatureGroup, Polygon } from "react-leaflet";
import {
  onSnapshot,
  query,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";


import { EditControl } from "react-leaflet-draw";
import DrawModal from "./DrawModal";

import randomColor from "randomcolor";
import { toast } from "react-toastify";
import { agentsDb } from "../../Config/Firebase-config";
import { citiesCollection } from "../../Config/Collections";

// const center = [38.053668, -93.909945]; // USA
const center = [33.330887, 44.388519]; // IRAQ
export default function Draw() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState([]);
  const [regions, setRegions] = useState([]);

  let docId = "";
  const fetchRegions = async () => {
    const q = query(citiesCollection);

    const unsubscriber = onSnapshot(q, (snapshot) => {
      var data = [];
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setRegions([...data]);
    });

    return unsubscriber;
  };
  useEffect(() => {
    fetchRegions();
  }, []);
  return (
    <>
      <Box borderRadius={50}>
        <MapContainer center={center} zoom={12} style={{ height: "100vh" }}>
          <TileLayer
            url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=wrxuGvfJz8EmbFxu9S1e"
            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
          />

          {regions.length > 0 && (
            <FeatureGroup>
              {regions?.map((state) => {
                let color = randomColor({ luminosity: "light" });

                return (
                  <Box key={state.id}>
                    <Polygon
                      pathOptions={{
                        fillColor: color,
                        fillOpacity: 0.5,
                        weight: 2,
                        opacity: 1,
                        dashArray: 3,
                        color: "white",
                      }}
                      positions={[
                        ...state.coordinates.map((e) => [e.lat, e.lng]),
                      ]}
                      eventHandlers={{
                        add: (e) => {
                          const layer = e.target;
                          layer.bindTooltip(state.name, {
                            direction: "center",
                            permanent: true,
                            className: "tooltip_style",
                          });
                        },
                        mouseover: (e) => {
                          docId = state.id;
                          const layer = e.target;
                          layer.bindTooltip(state.properties?.name, {
                            direction: "center",
                            permanent: true,
                            className: "labelstyle",
                          });
                          layer.setStyle({
                            dashArray: "",
                            fillColor: "#ffffff",
                            fillOpacity: 0.5,
                            weight: 2,
                            opacity: 1,
                            color: "white",
                          });
                        },
                        mouseout: (e) => {
                          const layer = e.target;
                          layer.setStyle({
                            fillOpacity: 0.5,
                            weight: 2,
                            dashArray: "3",
                            color: "white",
                            fillColor: color,
                          });
                        },
                        click: (e) => {
                          docId = state.id;
                          toast.info(state.name + " City is Selected");
                        },
                      }}
                    />
                  </Box>
                );
              })}
              <EditControl
                position="topright"
                onEdited={async (x) => {
                  let newCoordinates = x.layers
                    .getLayers()[0]
                    ?._latlngs[0].map((e) => ({
                      lat: e.lat,
                      lng: e.lng,
                    }));

                  try {
                    setLoading(true);
                    const docRef = doc(agentsDb, "cities", docId);

                    await updateDoc(docRef, {
                      coordinates: [...newCoordinates],
                    });
                    setLoading(false);
                    setOpen(false);
                    toast.info(`City Edited Successfully`);
                  } catch (err) {
                    setLoading(false);
                    toast.warning(
                      `Sorry, there was a problem; Please, try again later.`
                    );
                  }
                }}
                onCreated={(x) => {
                  setCoordinates(
                    x.layer._latlngs[0].map((e) => ({
                      lat: e.lat,
                      lng: e.lng,
                    }))
                  );
                  setOpen(true);
                }}
                onDeleted={async () => {
                  try {
                    setLoading(true);
                    const docRef = doc(agentsDb, "cities", docId);

                    await deleteDoc(docRef);
                    setLoading(false);
                    setOpen(false);
                    toast.info(`City Deleted Successfully`);
                  } catch (err) {
                    setLoading(false);
                    toast.warning(
                      `Sorry, there was a problem; Please, try again later.`
                    );
                  }
                }}
              />
            </FeatureGroup>
          )}
        </MapContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DrawModal open={open} setOpen={setOpen} coordinates={coordinates} />
      </Box>
    </>
  );
}
