import {axiosClient} from "../lib/axios";

export const fetchFmTasks = async (skip,take,status,from,to) => {
  console.log(from,to)
    return (
      await axiosClient.get("/api/getLineCoreOrders", {
        params: { skip,take,status,from,to },
      })
    ).data;
  };

export const fetchAllAgents = async (skip,take,searchTerm) => {
    return (
      await axiosClient.get("/api/getAgents", {
        params: { skip,take,searchTerm },
      })
    ).data;
  };
export const fetchAllVendors = async (skip,take,status) => {
    return (
      await axiosClient.get("/api/getVendors", {
        params: { skip,take,status },
      })
    ).data;
  };