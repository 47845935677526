import dayjs from "dayjs";
import { useBarcode } from "react-barcodes";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { totalPaidPrice } from "../../Middleware/OrdersCal";

export const PaymensLabel = ({ vendorPrice, vendorOrders, id }) => {
  const { inputRef } = useBarcode({
    value: id,
    options: {
      background: "white",
      height: 50,
      },
  });
  return (
    <>
      <div
        style={{
          padding: "0 20px",
          width: "80mm",
          margin: "0 auto",
        }}
        // id="Invoice_print"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <p
                style={{
                  fontWeight: "800",
                  //   fontSize: "16px",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <img
                  src="./Images/tamata_ine_logo.png"
                  width="140px"
                //   height="150px"
                  alt="tamata_logo"
                />
              </p>
            </div>
          </div>
          <div></div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <span>
            <svg ref={inputRef} />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
            fontWeight: "600",
            margin: "20px 0",
          }}
        >
          <div>
            <p style={{ margin: "20px 0" }}>
              Vendor Name : {vendorPrice.value}-{vendorPrice.label||""}
            </p>
            <p style={{ margin: "20px 0" }}>
              Vendor Address :{vendorPrice.address}
            </p>
            <p style={{ margin: "20px 0" }}>
              Vendor Phone :{vendorPrice.phone}
            </p>
            <p style={{ margin: "20px 0" }}>
              Print  Date ::{dayjs(new Date()).format("YYYY-MM-DD")}</p>
         
          </div>
        </div>
        <div style={{ margin: "20px 0" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            <p
              style={{
                border: "1px solid black",
                padding: "5px",
                marginBottom: "5px",
              }}
            >
               Total Vendor Received :
                  <CurrencyIqFormat
                    val={
                      totalPaidPrice(vendorOrders) -
                      vendorOrders.reduce((a, b) => a + parseInt(b.shipping), 0)
                    }
                  />
            </p>
          </div>
        </div>

        <div style={{ pageBreakAfter: "always" }}></div>
      </div>
    </>
  );
};
