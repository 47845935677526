import dayjs from "dayjs";
import React, { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import customStyles from "../../Assets/styles/custom_modal.json";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import { vendorCodCollection, vendorCollection } from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import { setDocumnet, updateMultiDoc } from "../../Middleware/FirebaseFunction";
import Provinces from "../../Assets/DataSchema/Provinces.json";
import Select from "react-select";
import { custom_select_country } from "../../Assets/styles/custom_select";

export const NewVendor = ({ isOpen, setIsOpen, vendor, vendors }) => {
  useEffect(() => {
    if (!vendor) {
      setAdress("");
      setLabel("");
      setValue("");
      setInPrice(0);
      setOutPrice(0);
      setPhone("");
      setCity(null);
      return;
    }
    setAdress(vendor.address);
    setLabel(vendor.label);
    setValue(vendor.value);
    setInPrice(vendor.in_price);
    setOutPrice(vendor.out_price);
    setPhone(vendor.phone);
    setCity({
      label: vendor.city,
      value: vendor.city,
    });
    setFreeOrder(vendor.free_order || 0);
    setIsActive(vendor.status)
  }, []);
  const { currentUser, setLoading } = useContext(AuthContext);
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAdress] = useState("");
  const [in_price, setInPrice] = useState(0);
  const [out_price, setOutPrice] = useState(0);
  const [freeOrder, setFreeOrder] = useState(0);
  const [city, setCity] = useState(null);
  const[isActive,setIsActive]=useState(false)
  const handelSubmit = async () => {
    console.log(address);
    if (!label || !phone || !address || !value || !city || freeOrder < 0) {
      return;
    }
    setLoading(true);
    if (vendor.label) {
      console.log("update");
      await updateMultiDoc(vendorCollection, vendor.id.toString(), {
        phone,
        out_price: out_price || 0,
        in_price: in_price || 0,
        address: address,
        free_order: Number(freeOrder),
        label: label,
      });
      toast.success("Successful");
      setIsOpen(false);
    } else {
      if (
        ![...vendors.map((e) => e.value?.toLowerCase())]
          .toString()
          .includes(value?.toLowerCase()) &&
        vendors.filter((e) =>
          value?.toLowerCase().includes(e.value.toLowerCase())
        ).length == 0
      ) {
        await setDocumnet(
          vendorCollection,
          `${Math.floor(10000000 + Math.random() * 999999999)}`,
          {
            value,
            label,
            createdAt: dayjs(new Date()).format("YYYY-MM-DD"),
            in_price,
            address,
            out_price,
            status: true,
            phone,
            city: city.value,
            free_order: Number(freeOrder),
            createBy: currentUser.name,
          }
        );
        await setDocumnet(vendorCodCollection,value,{
        value:0
        })
        setLoading(false);
        toast.success("Successful");
        setIsOpen(false);
        return;
      }
      toast.error("يوجد تشابه في الاسم");
      setLoading(false);
      return;
    }
    setLoading(false);
  };
  const UpdateStatus=async()=>{
    if(!vendor) return;
    setLoading(true)
    await updateMultiDoc(vendorCollection,vendor.id,{
      status:!vendor.status
    });
    toast.success("تمت العملية بنجاح");
    setLoading(false)
    setIsOpen(false)
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div>
          <div className="Modal_header">
            <div>Add New Vendor</div>
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              height="30"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="Modal_content">
          <div onClick={()=>UpdateStatus()} style={{
            color:"blue",
            borderBottom:"1px solid blue",
            width:"fit-content",
            fontWeight:700,cursor:"pointer"
          }}>
          {isActive?"Disable":"Enable"}
          </div>
            <div>
              <span>Name In English</span>
              <Input
                style="input"
                placeholder="Vendor English"
                type="text"
                defaultValue={value}
                onchange={(e) => setValue(e)}
              />
            </div>
            <div>
              <span>Name In Arabic</span>
              <Input
                style="input"
                placeholder="مثل...طماطة"
                type="text"
                defaultValue={label}
                onchange={(e) => setLabel(e)}
              />
            </div>
            <div>
              <span>Location</span>
              <Input
                style="input"
                placeholder="Like Bagahdad...الكرادة"
                type="text"
                defaultValue={address}
                onchange={(e) => setAdress(e)}
              />
            </div>
            <div>
              <span>Phone</span>
              <Input
                style="input"
                placeholder="Phone"
                type="text"
                defaultValue={phone}
                onchange={(e) => setPhone(e)}
              />
            </div>
            <div>
              <span>In Price</span>
              <input
                className="input"
                placeholder="In Price"
                type="number"
                defaultValue={in_price}
                onChange={(e) => setInPrice(parseFloat(e.target.value))}
              />
            </div>
            <div>
              <span>out Price</span>
              <input
                className="input"
                placeholder="out Price"
                type="number"
                defaultValue={out_price}
                onChange={(e) => setOutPrice(parseFloat(e.target.value))}
              />
            </div>
            <div>
              <span>Free Order</span>
              <input
                className="input"
                placeholder="Free Order"
                type="number"
                defaultValue={freeOrder}
                onChange={(e) => setFreeOrder(parseFloat(e.target.value))}
              />
            </div>
          </div>
          <div>
            <Select
              options={Provinces}
              defaultValue={city}
              onChange={(e) => {
                setCity(e);
              }}
              name="colors"
              isClearable={true}
              placeholder="Country"
              styles={custom_select_country()}
            />
          </div>
    
        </div>
        <Button
          title={`${vendor.value ? "Update" : "Create"}`}
          style="button"
          color="c70b44d"
          onclick={() => handelSubmit()}
        />
      </Modal>
    </>
  );
};
