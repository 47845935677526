import imageCompression from "browser-image-compression";
import { dataURLtoFile } from "../Helper/Base64ToImage";

export const ImageComperssor = async (image) => {
  var file = dataURLtoFile(image);

  const imgBlob = new Blob([file], {
    type: "image/jpeg",
  });

  const options = {
    maxSizeMB:0.6,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imgBlob, options);
    return await imageCompression.getDataUrlFromFile(compressedFile);
  } catch (error) {
    console.log(error);
  }
};
