import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { requestsCollection } from "../../Config/Collections";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import { agentsStorage } from "../../Config/Firebase-config";
import { updateMultiDoc } from "../../Middleware/FirebaseFunction";

export default function RequestDialog({ info, open, setOpen }) {
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState("");

  function uploadFileFirebase() {
    return new Promise(function (resolve, reject) {
      if (!file) return;

      const storageRef = ref(
        agentsStorage,
        `/ajmitest/${info.name}-||-${info.id}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error) => {
          console.log("ERRRRR!!!!!!");
          reject();
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);

            resolve(downloadURL);
          });
        }
      );
    });
  }

  const handleClose = () => {
    setFile("");
    setOpen(false);
  };
  const handleApprove = async () => {
    try {
      setLoading(true);
      let fileUrl = "";
      if (file) {
        fileUrl = await uploadFileFirebase();
      }

      await updateMultiDoc(requestsCollection, info.id, {
        status: "approved",
        reviewedAt: serverTimestamp(),
        fileUrl,
      });
      setLoading(false);

      setOpen(false);
    } catch (err) {
      console.log(err.toString());
      toast.warning(`Sorry, there was a problem; Please, try again later.`);
    }
    setLoading(false);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Tamata Agent Subscription Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tamata is Iraq's largest marketplace for intracity logistics,
            providing the fastest, cheapest, & easiest way to transport goods &
            deliver courier!
          </DialogContentText>
          <TextField
            disabled
            margin="dense"
            id="name"
            label={"Name"}
            value={info?.name}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            disabled
            margin="dense"
            id="phoneNumber"
            label={"Phone Number"}
            value={info?.phoneNumber}
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            disabled
            margin="dense"
            id="date"
            type="text"
            label={"Created At"}
            value={
              info?.createdAt.toDate().toLocaleDateString("en-US") +
              " || " +
              info?.createdAt.toDate().toLocaleTimeString("en-US")
            }
            fullWidth
            variant="standard"
          />
          <TextField
            disabled
            margin="dense"
            id="carInfo"
            label={"Car Info"}
            value={info?.carInfo}
            type="text"
            fullWidth
            variant="standard"
          />

          {info?.notes !== "" && (
            <TextField
              disabled
              margin="dense"
              id="notes"
              label={"Notes"}
              value={info?.notes}
              type="text"
              fullWidth
              variant="standard"
            />
          )}
          {info?.qualifications !== "" && (
            <TextField
              disabled
              margin="dense"
              id="qualifications"
              label={"Qualifications"}
              value={info?.qualifications}
              type="text"
              fullWidth
              variant="standard"
            />
          )}
          <TextField
            disabled
            margin="dense"
            id="address"
            label={"Address"}
            value={"العراق, " + (info?.province || "بغداد") + ", " + info?.city}
            type="text"
            fullWidth
            variant="standard"
          />

          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={200}>Contract Image</Typography>
            <TextField
              // disabled={info.fileUrl && true}
              onChange={(x) => {
                setFile(x.target.files[0]);
              }}
              margin="dense"
              id="image"
              type="file"
              fullWidth
              variant="standard"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <LoadingButton
            // disabled={(info.status !== "pending" && info?.fileUr!=="") }
            loading={loading}
            onClick={handleApprove}
          >
            {info.status === "pending" ? "Approve" : "Approved"}
          </LoadingButton> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
