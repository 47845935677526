import { getVendorOrders } from "./FirebaseFunction";
import { Order_status } from "../Assets/DataSchema/OrderStatus";
import dayjs from "dayjs";

export async function FetchCodTask(name,startDate,endDate,dateFilterType) {
  console.log(startDate,dayjs(startDate).format("YYYY-MM-DD"))
  if(dateFilterType==="deliveredAt"){
    startDate =dayjs(startDate).format("YYYY-MM-DD")
    endDate =dayjs(endDate).format("YYYY-MM-DD")
  }
  const codOrders = await getVendorOrders(name, Order_status.cod,startDate,endDate,dateFilterType);
if(!codOrders) return [];
  return codOrders.map((e) => ({
    ...e,
    barcode:e.id,
    specialPrice: e.job_description?.includes(true)
      ? parseInt(e.job_description.split("-")[1])
      : 0,
  }));
}
