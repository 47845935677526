import { collection } from "firebase/firestore";
import { agentsDb, db } from "./Firebase-config";

export const vendorCollection = collection(db, "vendors");
export const vendorCodCollection = collection(db, "vendors-cod");
export const PaymentsCollections = collection(db, "payments");
export const Paymentsv2Collections = collection(db, "paymentv2");
export const GlobalBillingCollection = collection(db, "GlobalBilling");
export const deilveryPCollection = collection(db, "deilveryPending");
export const tookanUpdateCollections = collection(db, "tookanUpdate");
export const usersCollection = collection(db, "users");
export const entryArchive = collection(db, "entryArchive");
export const paymentOut = collection(db, "paymentOut");
export const ordersCollections= collection(db, "orders");
export const ordersTestCollections= collection(db, "orders-test");
export const paymentTestCollections=collection(db,"payments-test");
export const barcodesCollections= collection(db, "barcodes");


// Agents DB
export const requestsCollection = collection(agentsDb, "requests");
export const citiesCollection = collection(agentsDb, "cities");
