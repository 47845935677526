import dayjs from "dayjs";
import React from "react";
import { useBarcode } from "react-barcodes";
import "../../Assets/styles/ReceiptPrintStylee.scss";
import {CurrencyIqFormat} from "../Input/CurrencyIqFormat";
export const ReceiptPrint = ({ task, id }) => {
  const { inputRef } = useBarcode({
    value: id,
    options: {
      background: "white",
      height: 50,
    },
  });

  return (
    <>
      <div className="Main" id={id}>
        <div className="barcode_container">
          <img src="./Images/tamata_line.svg" width="150px" alt="tamata_logo" />
          <div style={{ borderBottom: "2px solid #333" }}>
            Date :{dayjs(new Date()).format("YYYY-MM-DD")}
          </div>
        </div>
        <div className="barCode">
          <svg ref={inputRef} />
        </div>
        <div className="title_header">
          <p className="title">Number :{id}</p>
          <p className="title">Vendor :{task.vendor}</p>
        </div>
        <div>
          <div className="info_header">
            <div>CUSTOMER INFORMATION</div>
            <div>VENDOR NUMBER : {task.vendorPhone}</div>
          </div>
          <div className="info_field">
            <div className="Location">
              CUSTOMER :
              <span className="arabic">
                {task.cusName || task.name || task.phone}
              </span>
            </div>
          </div>
          <div className="info_field">
            <div>
              LOCATION :
              <span className="arabic" lang="ar">
                {task.cusAddress || task.location}
              </span>
            </div>
          </div>
          <div className="info_field">
            <div>CUSTOMER PHONE : {task.cusPhone || task.phone} </div>
          </div>
          <div className="info_field">
            <div>
              CITY :<span className="arabic">{task.province || task.city}</span>
            </div>
            <div>QTY : </div>
          </div>
          <div className="info_field">
            <div>PRICE :</div>
            <div>DELIVERYPRICE :</div>
          </div>
          <div className="info_field">
            <div>TOTAL : <CurrencyIqFormat val={task.amount}/> IQD{}</div>
          </div>
          <div className="info_field">
            <div style={{ backgroundColor: "#2f2f2f", color: "#fff" }}>
              NOTE:<span className="arabic note">{task.note || ""}</span>
            </div>
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </div>
    </>
  );
};
