import {Badge} from "../../../Components/ui/badge";
import {AgentStatus, manageAgentStore} from "../store";
import {cn, getAgentStatusStyle} from "../../../lib/utils";
import {Button} from "../../../Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../Components/ui/dropdown-menu";
import {YesOrNo} from "../../../Components/ui/YesOrNo";
import {approveAgent, blockAgent} from "../../../Services/Mutations";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {Loader2} from "lucide-react";
export const AgentActionDropMenu = ({agent}) => {
  const {allAgents, setRefetchGetAllAgent, refetchGetAllAgent} = manageAgentStore();
  const approveAgentMutation = useMutation({
    mutationFn: approveAgent,
    onSuccess: () => {
      console.log(refetchGetAllAgent)
      setRefetchGetAllAgent(!refetchGetAllAgent);
      toast.success("Agent Approved Successfully");
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        "An error occurred while processing your request.";
      toast.error(errorMessage);
    },
  });
  const blockAgentMutation = useMutation({
    mutationFn: blockAgent,
    onSuccess: () => {
      console.log(refetchGetAllAgent)
      setRefetchGetAllAgent(!refetchGetAllAgent);
      toast.success("Agent Block Successfully");
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        "An error occurred while processing your request.";
      toast.error(errorMessage);
    },
  });

  const actions = [{
    label: "Approve",
    mutateFun: approveAgentMutation,
    activeRule: ["New","Blocked"],
    style: "bg-green-700"

  },
  {
    label: "Block",
    mutateFun: blockAgentMutation,
    activeRule: ["Approved"],

    style: "bg-red-700"
  }]
  return (
    <>
      {actions.map(e => (
        <>
          {
          e.activeRule.includes(agent.status)    && <DropdownMenuContent className="w-56">
              <div>
                <YesOrNo
                  question="Are You Sure ?"
                  information={`This request will be ${e.label}`}
                  toDoText="Confirm"
                  toDoFunction={() =>
                    e.mutateFun.mutateAsync(agent.id)
                  }
                >
                  <Button className={`${e.style} w-full`} disabled={e.mutateFun.isPending}>
                    {e.label}
                    {e.mutateFun.isPending && (
                      <div className="flex h-full  items-center justify-center">
                        <Loader2 className=" animate-spin w-4 h-4 mx-2" />
                      </div>
                    )}
                  </Button>
                </YesOrNo>
              </div>
            </DropdownMenuContent>
          }
        </>
      ))}

    </>
  )
}