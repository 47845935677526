import dayjs from "dayjs";
import React from "react";
import { useBarcode } from "react-barcodes";

export const EarthlinkTask = ({ task }) => {
  const { inputRef } = useBarcode({
    value: task.Line_task,
    options: {
      background: "white",
      height: 50,
    },
  });
  return (
    <>
      <div className="A4">
        <div className="barcode_container">
          <img src="./tamata_logo.png" alt="tamata_logo"/>
          <div>Date :{dayjs(new Date()).format("YYYY-MM-DD")}</div>
        </div>
        <div className="barCode">
          <svg ref={inputRef} />
        </div>
        <div className="title_header">
          <p className="title">Number : {task.number}</p>
          <p className="title">Vendor : Earthlink</p>
        </div>
        <div className="content_earthlink">
          <div className="info_header">
            <div>VENDOR INFORMATION</div>
            <div>CUSTOMER INFORMATION</div>
          </div>
          <div className="info_fieldE">
            <div>Date :{dayjs(new Date()).format("YYYY-MM-DD")}</div>
          </div>
          <div className="info_fieldE ">
            <div className="Location">
              CUSTOMER : <span className="arabic"> {task['Affiliate Name'] }</span>
            </div>
          </div>
          <div className="info_fieldE">
            <div> PRICE :</div>
          </div>
          <div className="info_fieldE">
            <div>DELIVERY PRICE :</div>
          </div>
          <div className="info_fieldE">
            <div>TOTAL PRICE :</div>
          </div>
          <div className="info_fieldE">
            <div>
              LOCATION :
              <span className="arabic" lang="ar">
                {task['Location']}
              </span>
            </div>
          </div>
          <div className="info_fieldE">
            <div>PHONE : {task.Phone}</div>
          </div>

          <div className="info_fieldE">
            <div>
              CUSTOMER NOTE:<span className="arabic note">{task['ملاحظات الوكيل']|| ""}</span>
            </div>
          </div>

          <div className="info_fieldE">
            <div>
              EARTLINK NOTE :
              <span className="arabic note">{task['ملاحظات موظف ايرثلنك']||""}</span>
            </div>
          </div>
       
        </div>
        <div className="html2pdf__page-break"></div>
      </div>
    </>
  );
};
