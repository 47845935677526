import React, { useContext, useEffect, useState } from "react";
import { onSnapshot } from "firebase/firestore";
import {
  barcodesCollections,
  vendorCollection,
} from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import { SearchInput } from "../../Components/Input/SearchInput";
import Button from "../../Components/Button/Button";
import { ExportExcel } from "../../Components/ExportExcel";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { Permission_type } from "../../Config/Permission";
import { BiEditAlt } from "react-icons/bi";
import {
  deleteDocument,
  LimitPagination,
  updateArrayDoc,
  updateMultiDoc,
} from "../../Middleware/FirebaseFunction";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  custom_select_country,
  custom_style_select,
} from "../../Assets/styles/custom_select";
import { BarcodeModal } from "./BarcodeModal";
import dayjs from "dayjs";

export const Barcodes = () => {
  const { setLoading, vendors, currentUser } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const [barcodes, SetBarcodes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [vendor, setVendor] = useState({});
  const fetchBarcodes = async () => {
    setLoading(true);
    let data = await LimitPagination(barcodesCollections, "createAt", 200);
    SetBarcodes([...data]);
    setLoading(false);
  };
  useEffect(() => {
    fetchBarcodes();
  }, []);
  const updateVendorBarcodes = async (barcode, vendor) => {
    try {
      updateMultiDoc(barcodesCollections, barcode, {
        vendor: vendor?.value || "",
        assginAt: dayjs(new Date()).format("YYYY-MM-DD hh:mm"),
        assginBy: currentUser.name,
      });
      if (vendor) {
        updateArrayDoc(vendorCollection, vendor.id, "barcodes", [barcode]);
      }
      toast.success("تم العملية بنجاح");
    } catch (error) {
      toast.error("حدث خطا");
    }
  };
  const ExportData = async () => {
    setLoading(true);
    let data = await LimitPagination(barcodesCollections, "createAt", 500);
    ExportExcel(data);
    setLoading(false);
  };

  console.log(barcodes);
  return (
    <>
      <div>
        <div className="Header_wrapper">
          <div className="flex">
            <p> Manange Barcodes</p>
            <div className="search_container">
              <SearchInput
                className="res_input"
                placeholder="Search For Barcode"
                value={search}
                onchange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>

          <div className="flex">
            <p className="Export_green" onClick={() => setIsOpen(true)}>
              Upload Bulk
            </p>
            <Button
              style="button"
              color="ff1d43"
              title="Export"
              onclick={() => ExportData()}
            />
          </div>
        </div>


        <table id="customers" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>Barcodes</th>
              <th>Vendor</th>
              <th>Create By</th>
              <th>Assgin By</th>
              <th>Create At</th>
              <th>Assgin At</th>
            </tr>
          </thead>
          <tbody>
            {barcodes
              .filter((e) => e.id.includes(search || ""))
              ?.map((e, index) => (
                <tr key={index + 1}>
                  <td>{index + 1}</td>
                  <td>{e.id}</td>
                  <td style={{ width: "400px" }}>
                    <Select
                      options={vendors}
                      onChange={(val) => {
                        SetBarcodes((prev) => {
                          prev[prev.findIndex((bar) => bar.id == e.id)].vendor =
                            val ? val.value : "";
                          return [...prev];
                        });
                        updateVendorBarcodes(e.id, val);
                      }}
                      // styles={custom_style_select()}
                      isClearable={true}
                      value={
                        e.vendor
                          ? {
                              label: e.vendor,
                              value: e.vendor,
                            }
                          : null
                      }
                    />
                  </td>
                  <td>{e.createBy}</td>
                  <td>{e.assginBy}</td>
                  <td>{e.createAt}</td>
                  <td>{e.assginAt}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {isOpen && <BarcodeModal setIsOpen={setIsOpen} isOpen={isOpen} />}
    </>
  );
};
{
}
