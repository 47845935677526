import {
  Dialog,
  DialogOverlay,
  DialogHeader,
  DialogContent,
} from "../../../Components/ui/dialog";
import { Input } from "../../../Components/ui/input";
import { Button } from "../../../Components/ui/button";
import { manageAgentStore } from "../store";
import { useMutation } from "@tanstack/react-query";
import { addAgent } from "../../../Services/Mutations";
import { toast } from "react-toastify";
import { Loader2 } from "lucide-react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
export const AddAgent = () => {
  const {
    addAgentModalOpen,
    setRefetchGetAllAgent,
    refetchGetAllAgent,
    setAddAgentModalOpen,
    agentDetails,
    setAgentDetails,
  } = manageAgentStore();
  const addAgentMutation = useMutation({
    mutationFn: addAgent,
    onSuccess: () => {
      console.log(refetchGetAllAgent);
      setAgentDetails({});
      setRefetchGetAllAgent(!refetchGetAllAgent);
      setAddAgentModalOpen(false);
      toast.success("Agent Add Successfully");
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        "An error occurred while processing your request.";
      toast.error(errorMessage);
    },
  });
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(" Name is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    password: Yup.string().required("Password is required"),
    city: Yup.string().required("Location is required"),
  });
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    addAgentMutation.mutateAsync(data);
  };
  return (
    <>
      <Dialog
        open={addAgentModalOpen}
        onOpenChange={(e) => setAddAgentModalOpen(e)}
      >
        <DialogContent className="sm:max-w-[425px]">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col border justify-between w-full gap-4 p-2 my-4"
          >
            <div className="flex flex-col  items-start w-full gap-4 my-4">
              <div className="w-full flex flex-col justify-start items-start ">
                <div className="flex justify-between w-full">
                  <p className="mx-2">Agent Name : </p>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <Input
                        className="border border-[#D0D7DC] rounded-md px-2"
                        {...field}
                        placeholder="Name"
                      />
                    )}
                  />
                </div>
                <p className="text-xs text-red-700">
                  {errors.fullName && <p>{errors.fullName.message}</p>}
                </p>
              </div>

              <div className="w-full flex flex-col justify-start items-start">
                <div className="flex justify-between w-full">
                  <p className="mx-2">Phone Number: </p>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <Input
                        className="border border-[#D0D7DC] rounded-md px-2"
                        {...field}
                        placeholder="Phone Number"
                      />
                    )}
                  />
                </div>
                <p className="text-xs text-red-700">
                  {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
                </p>
              </div>

              <div className="w-full flex flex-col justify-start items-start">
                <div className="flex justify-between w-full">
                  <p className="mx-2">Password : </p>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input
                        className="border border-[#D0D7DC] rounded-md px-2"
                        {...field}
                        placeholder="password"
                      />
                    )}
                  />
                </div>
                <p className="text-xs text-red-700">
                  {errors.password && <p>{errors.password.message}</p>}
                </p>
              </div>

              <div className="w-full flex flex-col justify-start items-start">
                <div className="flex justify-between w-full">
                  <p className="mx-2">Location : </p>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <Input
                        className="border border-[#D0D7DC] rounded-md px-2"
                        {...field}
                        placeholder="Location"
                      />
                    )}
                  />
                </div>
                <p className="text-xs text-red-700">
                  {errors.city && <p>{errors.city.message}</p>}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-center">
              <button
                disabled={addAgentMutation.isPending}
                type="submit"
                onClick={() => handleSubmit(onSubmit)}
                className="bg-green-600 w-[50%] text-lg text-white p-2 hover:opacity-80"
              >
                {addAgentMutation.isPending ? (
                  <div className="flex h-full  items-center justify-center">
                    <Loader2 className=" animate-spin w-4 h-4 mx-2" />
                  </div>
                ):"Submit"}
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
