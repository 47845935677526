import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { AuthContext } from "../../Config/Context";
import dayjs from "dayjs";
import { fetchPickup } from "../../Middleware/FetchPickUp";
import { ExportCSV } from "../../Components/ExportCSV";
import Button from "../../Components/Button/Button";
import { IoIosAlert } from "react-icons/io";
import {FetchCpickups} from "../../Middleware/FetchCpickups";
import {LineCoreStatus} from "../../Assets/DataSchema/LineCoreStatus";
import {useQuery} from "@tanstack/react-query";
import {fetchFmTasks} from "../../Services/Fetchers";

export const PendingPickUp = ({ isOpen, setIsOpen }) => {
  const [pickUps, setPickUps] = useState([]);
  const { setLoading } = useContext(AuthContext);

  
  const { refetch } = useQuery({
    queryKey: ["fetchFmTasks", LineCoreStatus.Created],
    queryFn: async () => {
      setLoading(true)
      const response = await fetchFmTasks(0, 10, LineCoreStatus.Created,dayjs(new Date()).subtract(4,'day').format("YYYY-MM-DD"),dayjs(new Date()).add(1,'day').format("YYYY-MM-DD"));
      if (response.length == 0) {
        setLoading(false)
        return "Error";
      }
      console.log(response);
      setPickUps(response.data);
      setLoading(false)
      return response;
    },
  });
  const export_report = () => {
    var data = pickUps.map((r) => {
      return {
        name: r.name,
        location: r.location,
        totlOrders: pickUps.filter((e) => e.vendor == r.vendor).length,
        phone: r.phone,
      };
    });
    data = [
      ...new Map(data.map((item, key) => [item["phone"], item])).values(),
    ];
    ExportCSV(data, "pickUps");
  };

  return (
    <>
      <Modal isOpen={isOpen} ariaHideApp={false} contentLabel="Example Modal">
        <div>
          <div className="Modal_header">
            <div className="title">
              <span> PickUps Tasks</span>
              <Button
                style="button"
                color="c70b44d"
                title="Export Report"
                onclick={() => export_report()}
              />
            </div>
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              height="30"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="payments_conatiner">
            <div className="table_container">
              <div className="thead">
                <span className="small_width">#</span>
                <span className="small_width">Vendor</span>
                <span className="small_width">Vendor Phone </span>
                <span>Barcode</span>
                <span>Create AT</span>
                <span>Status</span>
                <span></span>
              </div>
              {pickUps.length != 0 ? (
                pickUps.map((e, index) => (
                  <div className="tbody" key={index + 1}>
                    <span className="small_width">{index + 1}</span>
                    <span className="small_width">{e.vendor}</span>
                    <span className="small_width">{e.vendorPhone}</span>
                    <span>{e.barcode}</span>
                    <span>{dayjs(e.createAt).format("YYYY-MM-DD")}</span>
                    <span>{"Pending"}</span>
            
                    <span className="alert">{new Date(e.createAt) < new Date() &&<IoIosAlert/>}</span>
                  </div>
                ))
              ) : (
                <div>No Orders</div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
