import dayjs from "dayjs";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportExcel = (csvData, fileName = "tamata") => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  try {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      fileName + dayjs(new Date()).format("YYYY-MM-DD") + fileExtension
    );
  } catch (error) {
    return;
  }
};