import dayjs from "dayjs";
import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { deilveryPCollection, ordersCollections } from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import { updateDocument } from "../../Middleware/FirebaseFunction";
import { IoIosAlert } from "react-icons/io";
import Select from "react-select";
import Provinces from "../../Assets/DataSchema/Provinces.json";
import { ExportExcel } from "../../Components/ExportExcel";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { Permission_type } from "../../Config/Permission";
import {TaskAPI} from "../../Middleware/TaskAPI";
import {Order_status} from "../../Assets/DataSchema/OrderStatus";
export const DeilveryPending = () => {
  const [tasks, setTasks] = useState([]);
  const [citySearch, setCitySearch] = useState(null);
  const { setLoading } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    const q = query(ordersCollections,limit(700),orderBy("createAt","asc"));
    const unsubscriber = onSnapshot(q, (snapshot) => {
      var myData = [];
      snapshot.docs.map((doc) => {
        myData.push({ ...doc.data(), id: doc.id });
      });
      console.log(myData)
      setTasks([...myData]);
      setLoading(false);
    });
    return unsubscriber;
  }, []);


  let data = tasks.filter((e) => e.city.includes(citySearch?.value || ""));

  const UploadDelivery = async () => {

    if (data.length == 0  ) {
      toast.error("حدث خطا");
      return;
    }
    if (window.confirm("Are you sure ?")) {
      try {
        setLoading(true);
        data = data.filter(d=>d.vendor.includes("Earthlink")).map((r) => ({
          order_id:`T-${r.vendor}${r.id}`,
          job_description: `${
            r.isSpecial ? `${r.isSpecial}-${r.specialPrice}` : ""
          }`,
          customer_username: r.name || r.phone,
          customer_phone: `+964${r.phone}`,
          customer_address: r.location,
          latitude: r.lat || 33.33,
          longitude: r.long || 44.44,
          barcode: r.id,
          job_delivery_datetime: dayjs(new Date()).format(
            "YYYY-MM-DD 23:59:20"
          ),
          custom_field_template: "order_details",
          meta_data: [
            {
              label: "Amount",
              data: r.amount || 0,
            },
            {
              label: "City",
              data: r.city,
            },
          ],
          team_id: "",
          auto_assignment: "0",
          has_pickup: "0",
          has_delivery: "1",
          layout_type: "0",
          tracking_link: 1,
          timezone: "-180",
          fleet_id: "",
          notify: 0,
          tags: "",
          geofence: 0,
        }));
        console.log(data)
        var ErrorsTasks = [];
        const unresolved = data.map(async (e, index) => {
          let res = await TaskAPI("create_task","POST", e);
          if (!res) {
            ErrorsTasks = [...ErrorsTasks, e];
            toast.error("error");
            return;
          }

          toast.success(index + 1);
        });
        const resolved = await Promise.all(unresolved);
 

        if (ErrorsTasks.length !== 0) ExportExcel(ErrorsTasks, "notUpload");
        toast.success("Upload Successful");
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Server Error");
      }
    }
  };

  return (
    <>
      <div>
        <div className="entry_menu">
          <div onClick={()=>ExportExcel(tasks.map(e=>({id:e.id})))}>
            <p className="work">Pending Delivery</p>
          </div>
          <p>
            Total Orders :
            <span style={{ color: "red", fontWeight: "900" }}>
              {data.length}
            </span>
          </p>
          <div>
     
            <PermissionsGate permission={Permission_type.upload_task}>
              <p className="export" onClick={()=>UploadDelivery()}>
                Upload Task
              </p>
            </PermissionsGate>
          </div>
        </div>
        <Select
          options={Provinces}
          value={citySearch}
          onChange={(val) => {
            setCitySearch(val);
          }}
          isClearable={true}
          placeholder="City"
        />
        <div className="payments_conatiner">
          <div className="table_container">
            <div className="thead">
              <span className="small_width">#</span>
              <span>Order ID</span>
              <span>Location</span>
              <span>City</span>
              <span className="small_width">Amount</span>
              <span className="small_width">CreateBy</span>
              <span className="small_width">completeAt</span>
              <span className="small_width"></span>
            </div>
            {data.length != 0 ? (
              data.map((r, index) => (
                <div className="tbody" key={index * Math.random()}>
                  <span className="small_width">{index + 1}</span>
                  <span>{`${r.vendor}-${r.id}`}</span>
                  <span>{r.location}</span>
                  <span>{r.city}</span>
                  <span className="small_width">
                    {<CurrencyIqFormat val={r.amount} />}
                  </span>
                  <span className="small_width">{r.createBy || " "}</span>
                  <span className="small_width">{dayjs(r.createAt.seconds*1000).format("YYYY-MM-DD") || " "}</span>

                  <span className="small_width alert">
                    {r.isSpecial && <IoIosAlert />}
                  </span>
                </div>
              ))
            ) : (
              <div>No Orders</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
