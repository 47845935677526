import dayjs from "dayjs";
import {
  limit,
  onSnapshot,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import {
  deilveryPCollection,
  ordersCollections,
} from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import {
  getDocArrayContains,
  getQustomQuery,
  updateDocument,
} from "../../Middleware/FirebaseFunction";

import { ExportExcel } from "../../Components/ExportExcel";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { FaImage } from "react-icons/fa";
import { TaskAPI } from "../../Middleware/TaskAPI";
import TaskStatus from "../../Assets/DataSchema/TaskStatus.json";
import { SearchInput } from "../../Components/Input/SearchInput";
import ReactToPrint from "react-to-print";
import { ReceiptPrint } from "../../Components/Layout/ReceiptPrint";
import DatePicker from "react-datepicker";
import { Order_status } from "../../Assets/DataSchema/OrderStatus";

export const Archive = () => {
  const [tasks, setTasks] = useState([]);
  const { setLoading, vendors } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const refComp = useRef();

  const fetchOrders = async () => {
    setLoading(true);
    const q = query(
      ordersCollections,
      orderBy("createAt", "desc"),
      where(
        "createAt",
        ">=",
        startDate
          ? new Date(dayjs(startDate).format("YYYY-MM-DD").valueOf())
          : new Date(dayjs(new Date()).subtract(2, "day").valueOf())
      ),
      where(
        "createAt",
        "<=",
        endDate
          ? new Date(
              dayjs(endDate).add(1, "day").format("YYYY-MM-DD").valueOf()
            )
          : new Date(dayjs(new Date()).add(1, "day").valueOf())
      )
    );
    const unsubscriber = onSnapshot(q, (snapshot) => {
      var myData = [];
      snapshot.docs.map((doc) => {
        myData.push({ ...doc.data(), id: doc.id });
      });
      console.log(myData);
      setTasks([...myData]);
    });
    setLoading(false);

    return unsubscriber;
  };
  useEffect(() => {
    fetchOrders();
  }, [startDate, endDate]);

  const UploadDelivery = async () => {
    if (window.confirm("Are you sure ?")) {
      try {
        setLoading(true);
        var data = tasks
          .filter(
            (d) =>
              d.vendor.includes("Earthlink") &&
              dayjs(d.createAt.seconds * 1000).format("YYYY-MM-DD") ==
                dayjs(new Date()).format("YYYY-MM-DD")
          )
          .map((r) => ({
            order_id: `T-${r.vendor}${r.id}`,
            job_description: `${
              r.isSpecial ? `${r.isSpecial}-${r.specialPrice}` : ""
            }`,
            customer_username: r.name || r.phone,
            customer_phone: `+964${r.phone}`,
            customer_address: r.location,
            latitude: r.lat || 33.33,
            longitude: r.long || 44.44,
            barcode: r.id,
            job_delivery_datetime: dayjs(new Date()).format(
              "YYYY-MM-DD 23:59:20"
            ),
            custom_field_template: "order_details",
            meta_data: [
              {
                label: "Amount",
                data: r.amount || 0,
              },
              {
                label: "City",
                data: r.city,
              },
            ],
            team_id: "",
            auto_assignment: "0",
            has_pickup: "0",
            has_delivery: "1",
            layout_type: "0",
            tracking_link: 1,
            timezone: "-180",
            fleet_id: "",
            notify: 0,
            tags: "",
            geofence: 0,
          }));
        var ErrorsTasks = [];
        const unresolved = data.map(async (e, index) => {
          let res = await TaskAPI("create_task", "POST", e);
          if (!res) {
            ErrorsTasks = [...ErrorsTasks, e];
            toast.error("error");
            return;
          }

          toast.success(index + 1);
        });
        const resolved = await Promise.all(unresolved);

        if (ErrorsTasks.length !== 0) ExportExcel(ErrorsTasks, "notUpload");
        toast.success("Upload Successful");
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Server Error");
      }
    }
  };

  const handelSearch = async (e) => {
    if (e.key === "Enter") {
      console.log(search.length);
      if (search.length < 3) return;

      let data = await getQustomQuery(
        ordersCollections,
        "searchTerm",
        "array-contains",
        search
      );

      setTasks((prev) => [
        ...prev,
        ...data.map((e) => ({
          ...e,
        })),
      ]);
    }
  };
  const ExportData = () => {
    var Vendors_Obj = vendors.reduce((a, b) => ({ ...a, [b.value]: b }), {});
    ExportExcel(
      tasks.map((e) => ({
        vendor: e.vendor,
        order_id: `T-${e.vendor}${e.id}`,
        barcode: e.id,
        job_delivery_datetime:
          dayjs(e.createAt.seconds * 1000).format("YYYY-MM-DD") || " ",
        amount: Number(e.amount || 0),
        shipping:
          e.city == "Baghdad"
            ? Vendors_Obj[e.vendor]?.in_price
            : Vendors_Obj[e.vendor]?.out_price,
        createAt: dayjs(e.createAt.seconds * 1000).format("YYYY-MM-DD") || " ",
        customer_username: e.name,
        customer_address: e.location,
        completeBy: e.createBy || "",
        custom_field_template: "",
        entry_status: e.entry_status || true,
        takeBy: e.takeBy,
        status: e.status,
        searchTerm: e.searchTerm,
        job_description: e.job_description,
        createBy: e.createBy,
        longitude: e.longitude,
        image: e.image,
        city: e.city,
        latitude: e.latitude,
        customer_phone: e.phone,
        vendorId: e.vendorId,
      }))
    );
  };

  const openAttachment = (attachment) => {
    window.open(attachment);
  };
  return (
    <>
      <div>
        <div className="entry_menu">
          <div>
            <p className="work">Archive</p>
          </div>
          <input
            placeholder="Search For Invoice"
            value={search}
            type="text"
            className="search_order"
            onKeyPress={(e) => handelSearch(e)}
            onChange={(e) => setSearch(e.target.value)}
          />
          <p>
            Total Orders :
            <span style={{ color: "red", fontWeight: "900" }}>
              {tasks.length}
            </span>
          </p>
          <span style={{ fontSize: "15px", marginLeft: "20px" }}>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              withPortal
              className="date_pick"
              placeholderText="From - To"
              isClearable={true}
            />
          </span>
          <div className="flex" style={{ height: "40px" }}>
            <ReactToPrint
              trigger={() => (
                <button className="print-button">
                  <span className="print-icon"></span>
                </button>
              )}
              content={() => {
                return refComp.current;
              }}
            />
            {/* <PermissionsGate permission={Permission_type.upload_task}> */}
            <p className="delivery" onClick={() => ExportData()}>
              Export
            </p>
            <p className="export" onClick={() => UploadDelivery()}>
              Upload Task
            </p>
            {/* </PermissionsGate> */}
          </div>
        </div>

        <div className="payments_conatiner">
          <div className="table_container">
            <div className="thead">
              <span></span>
              <span className="small_width">#</span>
              <span>Order ID</span>
              <span>Location</span>
              <span>City</span>
              <span className="small_width">Amount</span>
              <span className="small_width">CreateBy</span>
              <span className="small_width">Upload At</span>
              <span className="small_width">Status</span>
              <span className="small_width">Image</span>
              <span className="small_width"></span>
            </div>
            {tasks.length != 0 ? (
              tasks
                .filter((e) => e.id.includes(search || ""))
                .map((r, index) => (
                  <div className="tbody" key={index * Math.random()}>
                    <span>
                      <input
                        type="checkbox"
                        checked={r.isSelected}
                        value={r.isSelected || false}
                        onChange={(d) =>
                          setTasks((prevState) => {
                            prevState[
                              prevState.findIndex((c) => r.id == c.id)
                            ].isSelected = d.target.checked;
                            console.log(prevState);
                            return [...prevState];
                          })
                        }
                      />
                    </span>
                    <span className="small_width">{index + 1}</span>
                    <span>{`${r.vendor}-${r.id}`}</span>
                    <span>{r.location}</span>
                    <span>{r.city}</span>
                    <span className="small_width">
                      {<CurrencyIqFormat val={r.amount} />}
                    </span>
                    <span className="small_width">{r.createBy || " "}</span>
                    <span className="small_width">
                      {dayjs(r.createAt.seconds * 1000).format("YYYY-MM-DD") ||
                        " "}
                    </span>

                    <span className="small_width alert">{r.status}</span>
                    <span
                      className="small_width "
                      onClick={() => openAttachment(r.image)}
                    >
                      <FaImage />
                    </span>

                    <span className="small_width ">
                      {(r.status == Order_status.invoice ||
                        r.status == Order_status.paid) &&
                        r.paymentId}
                    </span>
                  </div>
                ))
            ) : (
              <div>No Orders</div>
            )}
          </div>
        </div>
        <div id="Invoice_print" ref={refComp}>
          {tasks
            .filter((e) => e.isSelected)
            .map((e, index) => (
              <ReceiptPrint key={index} task={e} id={e.id} />
            ))}
        </div>
      </div>
     
    </>
  );
};
