import dayjs from "dayjs";

import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import customStyles from "../../Assets/styles/custom_modal.json";
import Button from "../../Components/Button/Button";
import { ExportExcel } from "../../Components/ExportExcel";
import { ReadExcel } from "../../Components/ReadExcel";
import { AuthContext } from "../../Config/Context";
import { TaskAPI } from "../../Middleware/TaskAPI";
import {
  getAllDoc,
  getSingleDoc,
  setDocumnet,
  updateMultiDoc,
} from "../../Middleware/FirebaseFunction";
import {
  addDataIntoCache,
  getDataInCache,
} from "../../Middleware/addDataIntoCache";
import { Roles } from "../../Config/Role";
import {
  barcodesCollections,
  deilveryPCollection,
  paymentOut,
  tookanUpdateCollections,
} from "../../Config/Collections";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { Permission_type } from "../../Config/Permission";
import { query } from "firebase/firestore";
export const BarcodeModal = ({ setIsOpen, isOpen }) => {
  const { setLoading, currentUser } = useContext(AuthContext);
  const [newBarcodes, setNewBarcodes] = useState([]);
  const uploadData = async () => {
    let errors = [];
    try {
      setLoading(true);
      const unresolved = newBarcodes.map(async (e, index) => {
        let barcode = await getSingleDoc(barcodesCollections, e.barcode.toString());
        if (!barcode) {
          await setDocumnet(barcodesCollections, e.barcode.toString(), {
            createBy: currentUser.name,
            createAt: dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            status: false,
          });
          toast.success(index + 1);
          return;
        }
        toast.error(index + 1);
      });

      const resolved = await Promise.all(unresolved);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("error");
    }
  };

  const uploadCsv = async (e) => {
    e.preventDefault();
    ReadExcel(e, async (file) => {
      if (!file) return;
      setNewBarcodes([...file.filter(e=>e.barcode)]);
    });
  };

  return (
    <>
      <div>
        <div className="flex">
          <p className="pending" onClick={() => setIsOpen(true)}>
            Import Bulk
          </p>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className="Modal_header">
          <div>Import Bulk</div>
          <img
            onClick={() => setIsOpen(false)}
            width="20"
            height="30"
            src="/Images/close_modal.svg"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            height: "80%",
          }}
        >
          <input
            onChange={async (e) => {
              uploadCsv(e);
            }}
            accept=".xlsx"
            id="file"
            type="file"
          />

          <Button
            style="button"
            color="c70b44d"
            id="Import"
            title="Update Barcode"
            onclick={() => {
              uploadData();
            }}
          />
        </div>
        <div
          style={{
            color: "blue",
            borderBottom: "1px solid blue",
            width: "fit-content",
          }}
          onClick={() => ExportExcel([{ barcode: "" }])}
        >
          Download Example
        </div>
      </Modal>
    </>
  );
};
