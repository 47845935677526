import { Routes, Route, useNavigate } from "react-router";
import { MainVendor } from "./Main/Index";
import { Earthlink } from "./Earthlink";
import "../../index.css";
export const PrintReceipt = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="navbar">
        <img
          src="/Images/tamata_logo.png"
          alt="image_logo"
          width={"10%"}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("")}
        />
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate("Earthlink")}
        >
          <img src="/Images/Earthlink_Logo.png" alt="image_logo" />
        </div>
      </div>
      <Routes>
        <Route path="" element={<MainVendor />} />
        <Route path="/Earthlink" element={<Earthlink />} />
      </Routes>
    </>
  );
};
