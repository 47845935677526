import {axiosClient} from "../lib/axios";

export const addAgent = async (data) => {
    return (await axiosClient.post("/api/addAgent", data)).data;
  };
export const approveAgent = async (agentId) => {
    return (await axiosClient.put("/api/approveAgent", {agentId})).data;
  };
export const blockAgent = async (agentId) => {
  console.log(agentId)
    return (await axiosClient.put("/api/blockAgent", {agentId})).data;
  };
export const declineVendor = async (vendorId) => {
    return (await axiosClient.put("/api/declineVendor", {vendorId})).data;
  };
export const approveVendor = async (vendorId) => {
    return (await axiosClient.put("/api/approveVendor", {vendorId})).data;
  };