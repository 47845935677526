export function isValidPhoneNumber(phoneNumber) {
    const validPhoneNumberRegex = /^\d{10,11}$/;
  
    return validPhoneNumberRegex.test(phoneNumber);
  }
 export function isValidAmount(amount) {
    const nonNegativeNumberRegex = /^(?:\d*\.)?\d+$/;
  
    return nonNegativeNumberRegex.test(amount);
  }
  export function isValidLocation(location) {
    // Regular expression to match a non-empty string
    const nonEmptyStringRegex = /\S/;
  
    // Use the regular expression to test the location
    return nonEmptyStringRegex.test(location);
  }