import { useContext, useEffect, useState } from "react";
import { custom_style_vendor } from "../../Assets/styles/custom_select";
import { vendorCollection, ordersCollections } from "../../Config/Collections";
import {
  getAllDoc,
  getDocArrayContains,
  getSingleDoc,
} from "../../Middleware/FirebaseFunction";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import dayjs from "dayjs";
import { BsFileEarmarkExcel } from "react-icons/bs";
import { ExportExcel } from "../../Components/ExportExcel";
import { AuthContext } from "../../Config/Context";
import {
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { PointAuth } from "../../Middleware/TamataPoint";
import PointStatus from "../../Assets/DataSchema/PointStatus.json";
import TaskStatus from "../../Assets/DataSchema/TaskStatus.json";
import { GroupBy } from "../../Helper/GroupBy";
import { TaskAPI } from "../../Middleware/TaskAPI";

export const AccountStatement = () => {
  const [selectVendor, setSelectVendor] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [orders, setOrders] = useState([]);
  const { setLoading, vendors } = useContext(AuthContext);

  const fetchTransactions = async () => {
    var data = await getDocArrayContains(
      ordersCollections,
      "vendorId",
      "==",
      selectVendor.value,
      startDate,
      new Date(dayjs(endDate).add(1, "day")).toString()
    );
    setOrders(data);
  };

  useEffect(() => {
    if (!selectVendor || !startDate || !endDate) {
      setOrders([]);
      return;
    }
    fetchTransactions();
  }, [selectVendor, startDate, endDate]);
  const ExportStatement = async () => {
    setLoading(true);
ExportExcel(orders)

    setLoading(false);
  };
  console.log();
  return (
    <>
      <div>
        <div className="Header_wrapper">
          <div className="flex">
            <p>Account Statement</p>
            <div className="search_container">
              <Select
                options={vendors.map((e) => ({
                  label: e.value,
                  value: e.id,
                }))}
                defaultValue={selectVendor}
                onChange={(val) => {
                  console.log(val)
                  setSelectVendor(val);
                }}
                className=""
                placeholder="Account Name"
                isClearable={true}
                styles={custom_style_vendor()}
              />
            </div>
            <div style={{ marginLeft: "50px" }}>
              <DatePicker
                className="datepicker-here form-control"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                withPortal
                placeholderText="From - To"
                isClearable={true}
              />
            </div>
            {orders && (
              <BsFileEarmarkExcel
                onClick={() => ExportStatement()}
                fill="green"
                size={"30px"}
                style={{ cursor: "pointer", margin: "3px" }}
              />
            )}
            <div></div>
          </div>
        </div>

        <table id="customers" style={{ margin: "20px auto", width: "100%" ,padding:"10px"}}>
          <thead>
            <tr>
              <th style={{ width: "10%" }}>#</th>
              <th style={{ width: "10%" }}>Date</th>
              <th style={{ width: "10%" }}>Barcode</th>
              <th style={{ width: "10%" }}>Amount</th>
              <th style={{ width: "10%" }}>Vendor Receive Amount</th>
              <th style={{ width: "10%" }}>City</th>
              <th style={{ width: "10%" }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((e, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {dayjs(e.createAt.seconds * 1000).format("YYYY-MM-DD") || " "}
                </td>
                <td>{e.id}</td>
                <td>{e.name}</td>
                <td>
                  <CurrencyIqFormat val={e.agent_received} />
                </td>
                <td>
                  <CurrencyIqFormat val={e.paidValue} />
                </td>
                <td>{e.city}</td>
                <td>{e.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
