import dayjs from "dayjs";
import React from "react";
import { useBarcode } from "react-barcodes";

export const Task = ({ task, id }) => {
  const { inputRef } = useBarcode({
    value: task.Line_task,
    options: {
      background: "white",
      height: 50,

    },
  });
  return (
        <>
        <div className="Main" id={id}>
        <div className="barcode_container">
          {/* <img      src="/Images/tamata_logo.png"
          alt="image_logo"
          width={"30%"} /> */}
          <div>Date :{dayjs(new Date()).format("YYYY-MM-DD")}</div>
        </div>
        <div className="barCode" style={{fontSize:"18px",fontWeight:"600",letterSpacing:"1px"}}>
          Region :{task.regionName||""}
        </div>
        <div className="barCode">
          <svg ref={inputRef} />
        </div>
        <div className="title_header">
          <div className="title">Number :{task.Number}</div>
          <div className="title">Vendor  :{task.Vname}</div>
        </div>
        <div>
          <div className="info_header">
            <div> CUSTOMER INFORMATION</div>
            <div>VENDOR NUMBER : {task.vendorNumber}</div>
          </div>
          <div className="info_field">
            <div className="Location">
              CUSTOMER :  <span className="arabic" > {task.Cname}</span>
            </div>
          </div>
          <div className="info_field">
            <div >LOCATION :  <span className="arabic" lang="ar">{task.Location}</span></div>
          </div>
          <div className="info_field">
            <div >
            CUSTOMER  PHONE : {task.phone1} -  {task.phone2 ||" "}
            </div>
          </div>
          <div className="info_field">
            <div >
              CITY :<span className="arabic">{task.City}</span>
            </div>
            <div>QTY :{task.Qty} </div>
          </div>
          <div className="info_field">
            <div>PRICE :{task.Price}</div>
            <div>DELIVERYPRICE :{task.DeliveryPrice}</div>
          </div>
          <div className="info_field">
            <div>TOTAL :{task.TotalPrice}</div>
        
          </div>
          <div className="info_field" >
            <div style={{backgroundColor:"rgb(47 47 47 / 79%)",color:"#fff"}}>
              NOTE:<span className="arabic note">{task.Note}</span>
            </div>
          </div>
  
        </div>
      <div className="html2pdf__page-break"></div>
      </div>

      </>

    
     
  );
};
