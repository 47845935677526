export const custom_style_select = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "25px",
      fontSize: "10px",
      height: "25px",
      width: "150px",
      boxShadow: state.isFocused ? null : null,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,

      fontSize: "10px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "25px",
      // padding: "0 6px",
      fontSize: "10px",
    }),
    option: (provided, state ) => {
      return {
        ...provided,
        cursor:"pointer",
        color: "#000",
        isDisable:false
      };
    },
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      fontSize: "10px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "25px",
      fontSize: "10px",
    }),
  };
};
export const custom_style_vendor = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "25px",
      fontSize: "10px",
      height: "25px",
      width: "150px",
      boxShadow: state.isFocused ? null : null,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,

      fontSize: "10px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "25px",
      // padding: "0 6px",
      fontSize: "10px",
    }),
    option: (provided, state ) => {
      return {
        ...provided,
        cursor:"pointer",
        // display:!state.data.active&&"none",
        color: "#000",
        isDisable:false
      };
    },
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      fontSize: "10px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "25px",
      fontSize: "10px",
    }),
  };
};
export const custom_select_style = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "50px",
      fontSize: "15px",
      height: "50px",
      width: "150px",
      boxShadow: state.isFocused ? null : null,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,

      fontSize: "15px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "50px",
      // padding: "0 6px",
      fontSize: "15px",
    }),
    option: (provided, state ) => {
      return {
        ...provided,
        cursor:"pointer",
        // display:!state.data.active&&"none",
        color: "#000",
        isDisable:false
      };
    },
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      fontSize: "15px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "50px",
      fontSize: "15px",
    }),
  };
};
export const custom_select_country = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: "30px",
      width: "100%",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };
};
