import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { storage } from "../Config/Firebase-config";
import Webcam from "react-webcam";

export const WebcamCapture = ({ setImageUrl,imgUrl ,isWebcamOpen}) => {
  const webcamRef = useRef(null);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((e) => console.log("Camera Permission  "))
        .catch((error) => {
          alert("Permissin the Camera ");
        });
    }
  }, []);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageUrl(imageSrc);
  }, [webcamRef, setImageUrl]);
  return (
    <>
    <div style={{ display: isWebcamOpen ? 'block' : 'none' }} >
      

    <div style={{display:"flex"}}>
      <div>
      <div className="capture_img">
        <Webcam
      
   width={340}
   open={true}
          // mirrored={true}
          audio={false}
          forceScreenshotSourceSize={true}
          videoConstraints={{
            height: 720 ,
            width:1280 
          }}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
        />
        <div>
          <button style={{ marginLeft: "10px" }} onClick={() => capture()}>
            Capture photo
          </button>
          
        </div>
      </div>
      </div>
      <div>
        <img src={imgUrl} width={"400px"} alt="image"/>
      </div>
    </div>
    </div>
    </>
  );
};
