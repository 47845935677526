import { useCallback, useContext, useEffect, useState } from "react";
import { MobileCam } from "../../Components/MobileCam";
import ImageViewer from "react-simple-image-viewer";
import Select from "react-select";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Button";
import "../../responive_stylee.scss";
import { ImageButton } from "../../Components/Button/ImageButton";
import { AuthContext } from "../../Config/Context";
import { getAllDoc, setDocumnet } from "../../Middleware/FirebaseFunction";
import { entryArchive, vendorCollection } from "../../Config/Collections";
import { MdDeleteForever } from "react-icons/md";
import { UploadImages } from "../../Helper/UploadMultiImage";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Config/Firebase-config";
import { CurrencyInput } from "../../Components/Input/CurrencyInput";
import {custom_style_vendor} from "../../Assets/styles/custom_select";
export const ReceiptsEntry = () => {
  const { setLoading, currentUser, setCurrentUser,vendors } = useContext(AuthContext);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [itemsNo, setItemNo] = useState(0);
  const [extraCharge, setExtraChrage] = useState(0);
  const [receipts, setReceipts] = useState([]);
  const [vendor, setVendor] = useState(null);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const navigate = useNavigate();
  console.log(vendor);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    if (!currentUser) {
      navigate("/Login");
      return;
    }
  }, []);
  const uploadTask = async () => {
    if (
      !vendor ||
      receipts.length != itemsNo ||
      itemsNo < 1 ||
      (extraCharge != 0 && extraCharge < 900)
    ) {
      toast.error("خطا في ادخال المعلومات");
      return;
    }

    setLoading(true);
    try {
      let data = await UploadImages(
        receipts,
        `${vendor.value.replace(/ /g, "")}-${dayjs(new Date()).format(
          "YY-MM-DD hh:mm"
        )}-${Math.floor(Math.random() * 100 + 1) * 99}`
      );
      await setDocumnet(
        entryArchive,
        `${vendor.value}-${dayjs(new Date()).format("YYYY-MM-DDhh:mm:ss")}-${
          Math.floor(Math.random() * 10000 + 1) * 9999
        }`,
        {
          entry_complete: false,
          completeData: data.map((e) => ({ image: e, entry_status: false })),
          startAt: "",
          specialOrder: {
            isSpecial: extraCharge > 0 ? true : false,
            specialPrice: extraCharge,
          },
          vendorId: vendor.id,
          vendor: vendor.value,
          createBy: currentUser.name,
          createAt: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        }
      );
      setLoading(false);
      toast.success("Successful");
      Refresh();
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Server Error");
    }
  };
  window.addEventListener("beforeunload", (event) => {
    event.returnValue = `Are you sure you want to leave?`;
  });
  const Refresh = () => {
    setReceipts([]);
    setVendor(null);
    setExtraChrage(0);
    setItemNo(0);
  };
  useEffect(() => {
    if (receipts.length == itemsNo) {
      setCameraOpen(false);
    }
  }, [receipts]);

  const handelLogout = () => {
    navigate("/Login");
    setCurrentUser(null);
    auth.signOut();
  };

  return (
    <>
      <div>
        <div className="res_header">
          <span>{currentUser?.name}</span>
          <span>Tamata Line</span>
          <span onClick={() => handelLogout()}>Logout</span>
        </div>
        <div className="res_container">
          <div>
            <span>Vendor Name</span>
            <span>
              <Select
                options={vendors.filter(e=>e.status)}
                name="colors"
                value={vendor}
                onChange={(val) => {
                  setVendor(val);
                }}
                isClearable={true}
               placeholder="Vendor Name"
              //  className="res_input"
            // styles={custom_style_vendor()}
              />
            </span>
          </div>
          <div>
            <div>
              <span>Number of Items</span>
            </div>
            <div className="res_div">
              <span>
                <Input
                  type="text"
                  style="res_input"
                  value={itemsNo}
                  onchange={(e) => setItemNo(e)}
                />
              </span>
            </div>
          </div>
          <div>
            <div>
              <span>Extra Delivery Charges</span>
            </div>
            <div className="res_div">
              <span>
                <CurrencyInput
                  style="res_input"
                  onchange={(e) => setExtraChrage(e.floatValue)}
                  value={extraCharge}
                />
              </span>
            </div>
          </div>
          <div
            className={`${
              receipts.length == 0 ? "camera_container" : "img_container"
            }`}
          >
            <div>
              {true && (
                <>
                  <ImageButton
                    onclick={() =>
                      setCameraOpen((r) => {
                        if (itemsNo > 0 && vendor) {
                          setCameraOpen((r) => !r);
                        }
                      })
                    }
                  />
                  {receipts?.map((e, index) => (
                    <span
                      key={index + 1}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <img
                        src={e}
                        alt="image"
                        height="100px"
                        onClick={() => openImageViewer(index)}
                        style={{ margin: "0px 1px" }}
                      />
                      <span
                        className="delete_icon"
                        onClick={() => {
                          setReceipts((r) => r.filter((b) => b != e));
                        }}
                      >
                        <MdDeleteForever />
                      </span>
                    </span>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="cam_contaienr">
          {cameraOpen && (
            <MobileCam
              setCameraOpen={setCameraOpen}
              setReceipts={setReceipts}
            />
          )}
        </div>
        <div
          className="res_div"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          Number of image : {receipts.length}
        </div>
        <div className="fixed">
          <Button
            style="button"
            color="outline_button"
            title="Refresh "
            onclick={() => Refresh()}
          />
          <Button
            style="button"
            color="ff1d43"
            title="Upload Task"
            onclick={() => uploadTask()}
          />
        </div>

        {isViewerOpen && (
          <ImageViewer
            src={receipts}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </>
  );
};
