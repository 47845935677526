import { useRef, useState } from "react";
import {ReadExcel} from "../../../Components/ReadExcel"
import EarthlinkSchema from "../../../Assets/DataSchema/EarthlinkSchema.json"
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import { EarthlinkTask } from "./EarthlinkTask";
import {read,utils} from "xlsx/xlsx";
import dayjs from "dayjs";
import {ExportExcel} from "../../../Components/ExportExcel"
import {ExportCSV} from "../../../Components/ExportCSV"
import   "../../../index.css"

export const Earthlink = () => {
  const [csvFile, setCsvFile] = useState([]);
  const refComp = useRef();
  const checkCsv = (data) => {
    if (data.length === 0) return true;
    var valueArr = data.map((item) => item.Line_task);
    if (new Set(valueArr).size !== valueArr.length) {
      toast.error("Invoices Duplicated");
      return true;
    }
  };
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        console.log(json);
        let myData = json.map((e, index) => {
          console.log(index)
          var x = dayjs(new Date()).format("YYMMDDHH") + index;
          return {
            ...e,
            number: x,
            Line_task: "Earth-" + x,
          };
        });
        setCsvFile([...myData]);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const CreateTasks = async () => {
    if (csvFile.length === 0) return;
    let data = csvFile.map((e) => ({
      Date: dayjs(new Date()).format("YYYY-MM-DD"),
      vId:"773092241",
      Number: e.Line_task,
      Cname: e["Affiliate Name"],
      Location: e.Location,
      phone1: e.Phone,
      phone2: "",
      Price: "",
      TotalPrice: "",
      Vname: "Earthlink_BG",
      Note: "",
      City: "",
      Qty: "",
      DeliveryPrice: "",
      Line_task: e.Line_task.toLowerCase(),
      vendorId:"773092241"
    }));
    console.log(csvFile);
    ExportExcel(data, "Earthlink-");
  };
  return (
    <>
      <div className="content_container earthlink_container">
        <div className="Earthlink">
        <img src="/Images/Earthlink_Logo.png" alt="image_logo" />
        </div>
        <div className="upload_container">
          <input onChange={readUploadFile} id="file" type="file" />
          <button className="submit_button" onClick={() => CreateTasks()}>
            Create Excel
          </button>
          <ReactToPrint
            trigger={() => (
              <button className="submit_button">Create Earthlink PDF</button>
            )}
            content={() => refComp.current}
          />
        </div>
        <p
          className="download_button"
          onClick={() => ExportCSV(EarthlinkSchema, "Earthlink")}
        >
          Download Earthlink Example
        </p>
      </div>
      <div id="printAble" ref={refComp}>
        {csvFile.map((e, index) => (
          <EarthlinkTask task={e} id={index} key={index} />
        ))}
      </div>
    </>
  );
};
