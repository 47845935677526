import React, { ReactNode } from "react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./alert-dialog";
export const YesOrNo=({
    question,
    information,
    toDoText,
    toDoFunction,
    children,
})=>{
    
return(
    <div className="">
    <AlertDialog  >
      <AlertDialogTrigger asChild>
        {children}
      </AlertDialogTrigger>
    <AlertDialogContent className="border-2  w-fit h-fit">
        <AlertDialogHeader>
          <AlertDialogTitle>{question}</AlertDialogTitle>
          <AlertDialogDescription>{information}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={toDoFunction}>
            {toDoText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent> 
    </AlertDialog>
    </div>
)
}