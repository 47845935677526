import dayjs from "dayjs";
import React from "react";
import { useBarcode } from "react-barcodes";
import  "./index.css"
import {CurrencyIqFormat} from "../../../../Components/Input/CurrencyIqFormat";

export const OrderReceipt=({ task, id,vendorNumber })=>{
    const { inputRef } = useBarcode({
        value: id,
        options: {
          background: "white",
          height: 50,
    
        },
      });
      console.log(task)
      return (
        <>
        
        <div className="" id={id}>
        <div className="barcode_container">
          <img      src="./Images/tamata_line.svg"
          alt="image_logo"
          width="150px" />
          <div>Date :{dayjs(new Date()).format("YYYY-MM-DD")}</div>
        </div>
       
        <div className="barCode">
          <svg ref={inputRef} />
        </div>
        <div className="title_header">
          <div className="title">Number :{id}</div>
          <div className="title">Vendor  :{task.vendor}</div>
        </div>
        <div>
          <div className="info_header">
            <div> CUSTOMER INFORMATION</div>
            <div>VENDOR  INFORMATION :{vendorNumber}</div>
          </div>
          <div className="info_field">
            <div className="Location">
              CUSTOMER :  <span className="arabic" > {task.customerName}</span>
            </div>
          </div>
          <div className="info_field">
            <div >LOCATION :  <span className="arabic" lang="ar">{task.location}</span></div>
          </div>
          <div className="info_field">
            <div >
            CUSTOMER   PHONE : {task.phone1}-{task.phone2}
            </div>
          </div>
        
          <div className="info_field">
            <div >
              CITY :<span className="arabic">{task.city}</span>
            </div>
            <div>QTY :{task.qty} </div>
          </div>
        
          <div className="info_field">
            <div>TOTAL : <CurrencyIqFormat val={task.amount}/></div>
        
          </div>
          <div className="info_field" >
            <div style={{backgroundColor:"black",color:"white"}}>
              NOTE:<span className="arabic note">{task.note}</span>
            </div>
          </div>
  
        </div>
      <div className="html2pdf__page-break"></div>
      </div>

      </>

    
     
  );
}