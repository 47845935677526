import { useQuery } from "@tanstack/react-query";
import { Button } from "../../Components/ui/button";
import { AddAgent } from "./components/AddAgent";
import { AgentTable } from "./components/AgentTable";
import { Header } from "./components/Header";
import { fetchAllAgents } from "../../Services/Fetchers";
import { manageAgentStore } from "./store";
import { useContext } from "react";
import { AuthContext } from "../../Config/Context";

export const ManageAgent = () => {
  const { setAllAgents, searchTermsFilter, refetchGetAllAgent } =
    manageAgentStore();
  const { setLoading } = useContext(AuthContext);
  console.log(refetchGetAllAgent);
  const { refetch } = useQuery({
    queryKey: ["fetchAgents", refetchGetAllAgent],
    queryFn: async () => {
      setLoading(true);
      const response = await fetchAllAgents(0, 50, searchTermsFilter);

      if (response.length == 0) {
        setLoading(false);
        return "Error";
      }
      console.log(response.data);
      setAllAgents(response.data);
      setLoading(false);
      return response;
    },
  });
  return (
    <>
      <div className="flex flex-col h-screen gap-2 p-2">
        <Header />
        <AgentTable />
      </div>
      <AddAgent />
    </>
  );
};
