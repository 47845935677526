export const paidPaymentAPI=async(data,method="PUT")=>{
  const res=await fetch(`${process.env.REACT_APP_LINE_API_BASE_URL}/api/paidPayment`, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data}),
  });
  const response=await res.json();
  return response;
   
}