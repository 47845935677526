

export const FetchById = (method = "POST", data) => {
  console.log(data)
  if(data.length==0) return []
  return fetch("https://api.tamata.com/v2/get_job_details_by_order_id", {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
      api_key: process.env.REACT_APP_API_TOOKAN,
      include_task_history: 1,
    }),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (responseData.status != 200) return [];
      return [
        ...responseData.data.map((e) => ({
          job_id: e.job_id,
          order_id: e.order_id,
          is_paid_or_unpaid: e.is_paid_or_unpaid,
          amount: e.amount || 0,
          province: e.custom_field[0]?.fleet_data || e.province || " ",
          barcode: e.barcode || " ",
          partially_completed_flag: e.partially_completed_flag,
          partially_completed_amount: e.partially_completed_amount,
          updateAt: e.updated_at,
          driver_comment:e.task_history.find(e=>e.description=="Failed at")?.reason||"",
          status:e.job_status
        
        })),
      ];
    })
    .catch((error) => {
      console.log(error);
      return;
    });
};
