import {manageVendorStore} from "../store";
import { Dialog, DialogOverlay,DialogHeader ,DialogContent} from "../../../Components/ui/dialog";
import {PendingRegistrationRequest} from "./PendingRegistrationRequest";
import {ApproveRegistration} from "./ApproveRegistration";
import {vendorRegistrationModalSteps} from "../store";

export const VendorRequest=()=>{
    const {vendorModalStep,vendorRegistrationModalOpen,setVendorRegistrationModalOpen,setVendorModalStep}=manageVendorStore();
    const renderStep = () => {
        switch (vendorModalStep) {
          case "PENDING_REGISTRATION_REQUEST":
            return <PendingRegistrationRequest />;
          case "APPROVE_REGISTRATION":
            return <ApproveRegistration />;
        }
      };
      //
    return(
        <>
         <Dialog
        open={vendorRegistrationModalOpen}
        onOpenChange={(e) => {
          setVendorModalStep(vendorRegistrationModalSteps.pendingRegistrationRequest)
          return setVendorRegistrationModalOpen(e);
        }}
        modal={true}
      >
                
      {renderStep()}
      </Dialog>
        </>
    )
}