import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Divider,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { onSnapshot, orderBy, query } from "firebase/firestore";
import RequestDialog from "./requestModal";
import { citiesCollection, requestsCollection } from "../../Config/Collections";
import { toast } from "react-toastify";
import { theme } from "../../Config/Theme";
import { useNavigate } from "react-router-dom";
import { ExportExcel } from "../../Components/ExportExcel";
import dayjs from "dayjs";

const getCreatedAtDate = (x) => {
  return x.row.createdAt.toDate();
};
const getReviewedAtDate = (x) => {
  if (x.row.reviewedAt != undefined) {
    return x.row.reviewedAt.toDate();
  } else {
    return "Not Reviewed Yet!";
  }
};

const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    editable: true,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    minWidth: 125,
    editable: true,
  },
  {
    field: "qualifications",
    headerName: "Qualifications",
    minWidth: 200,
    editable: true,
  },
  {
    field: "vehicleType",
    headerName: "Vehicle Type",
    minWidth: 200,
    editable: true,
  },

  {
    field: "province",
    headerName: "Province",
    minWidth: 100,
    editable: true,
  },
  {
    field: "city",
    headerName: "City",
    minWidth: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 150,
    renderCell: (params) => (
      <Button color={params.row.status === "pending" ? "warning" : "success"}>
        {params.row.status}
      </Button>
    ),
  },

  {
    field: "createdAt",
    headerName: "Created At",
    type: "dateTime",
    minWidth: 200,
    valueGetter: getCreatedAtDate,
  },
  {
    field: "reviewedAt",
    headerName: "Reviewed At",
    type: "dateTime",
    minWidth: 200,
    valueGetter: getReviewedAtDate,
  },

  {
    field: "fileUrl",
    headerName: "Contract File",
    type: "file",
    minWidth: 150,
    renderCell: (params) => (
      <Button
        color="inherit"
        disabled={!(params.row.fileUrl !== "" && params.row.fileUrl)}
        onClick={() => {
          if (params.row.fileUrl !== "" && params.row.fileUrl) {
            window.open(params.row.fileUrl);
          } else {
            toast.warn("There is no contract assigned to this person.");
          }
        }}
      >
        Download File
      </Button>
    ),
  },
];

export function Agents() {
  const [requests, setRequests] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [info, setInfo] = React.useState({});
  const navigate = useNavigate();

  const exportRegions = () => {
    ExportExcel(
      [...requests.map((e) => ({
        ...e,
        createdAt: dayjs(e.createdAt.seconds * 1000).format("YYYY-MM-DD") || " ",
      }))],
      "Agent"
    );
  };

  const fetchRequests = () => {
    const q = query(requestsCollection,orderBy("createdAt","desc"));

    const unsubscriber = onSnapshot(q, (snapshot) => {
      var data = [];
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setRequests([...data]);
    });

    return unsubscriber;
  };
  React.useEffect(() => {
    fetchRequests();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Stack paddingX={1} paddingY={1}>
        <Stack
          direction={"row"}
          paddingBottom={1}
          justifyContent={"space-between"}
        >
          <Typography
            flex={1}
            fontSize={"20px"}
            variant="h1"
            fontWeight={"300"}
            color={"#848484"}
          >
            Tamata Agent Requests
          </Typography>

          <Box width={10} />
          <Button onClick={exportRegions} color="success" variant="contained">
            Export Regions
          </Button>
          <Box width={10} />
          <Button onClick={() => navigate("draw")} variant="contained">
            Add New City
          </Button>
        </Stack>

        <Divider />
        <Box paddingBottom={1} />
        <Box sx={{ height: "80vh", width: "100%" }}>
          <DataGrid
            onCellClick={(x) => {
              if (x.field === "status") {
                setInfo(x.row);
                setOpen(true);
              }
            }}
            disableSelectionOnClick
            disableColumnSelector
            components={{ Toolbar: GridToolbar }}
            rows={requests}
            columns={columns}
            pageSize={20}

            // rowsPerPageOptions={[5]}
          />
        </Box>
      </Stack>

      {open && <RequestDialog open={open} setOpen={setOpen} info={info} />}
    </ThemeProvider>
  );
}
