import dayjs from "dayjs";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { useContext, useEffect, useRef, useState } from "react";
import { DatePickerOrder } from "./components/DatePicker";
import {
  getQustomQuery,
  getRangeOrderByDate,
  getVendorOrdersByDate,
} from "../../Middleware/FirebaseFunction";
import { PrintButton } from "./components/PrintButton";
import { AuthContext } from "../../Config/Context";
import { ReceiptPrint } from "../../Components/Layout/ReceiptPrint";
import { ExportExcel } from "../../Components/ExportExcel";
import { UploadBulkOrders } from "./components/UploadBulkOrders";
import ReactToPrint from "react-to-print";
import { BsFillPrinterFill } from "react-icons/bs";
import { ordersCollections, ordersTestCollections } from "../../Config/Collections";
import {DateFilterComponents} from "./components/DateFilterComponets";
import {toast} from "react-toastify";
import { Order_status } from "../../Assets/DataSchema/OrderStatus";
import Select from "react-select";
import { custom_select_style, custom_style_vendor } from "../../Assets/styles/custom_select";
import {OrderReceipt} from "./components/template/OrderReceipt";

export const VendorPortal = () => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [orders, setOrders] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState("");
  const { setLoading, currentUser } = useContext(AuthContext);
  const [openUploadOrdersModal, setOpenUploadOrdersModal] = useState(false);
  const [openDateFilterOrderModal, setOpenDateFilterOrderModal] = useState(false);
  const [cursor, setCursor] = useState(null);
  const [currentPage, setCurrentPage] = useState({
    number:1,
    action:"inc"
  });
  const[orderStatus,setOrderStatus]=useState(null)
  const limit = 50;

  const refComp = useRef();

  const fetchOrders = async () => {
    setLoading(true);
    const response = await getVendorOrdersByDate(
      currentUser.name,
      startDate,
      endDate,
      cursor,
      limit
    );
    console.log(response)
    setOrders(prev=>{
     return [...prev,...response.map((e) => ({ ...e, isSelected: false }))]
    })
    setLoading(false);
    if (response.length !== limit) {
      setCursor(null);
      return;
    };
    setCursor(response[response.length - 1]);
  };
  useEffect(() => {
    if (!startDate || !endDate) return;
    fetchOrders();
  }, [dateRange]);


  const selectItem = (item, value) => {
    var index = orders.findIndex((e) => e.id === item.id);
    console.log(index);
    setOrders((prev) => {
      prev[index].isSelected = value;
      return [...prev];
    });
  };
  const handelSearch = async (e) => {
    if (e.key === "Enter") {
      if (searchCriteria.length < 4) return;

      let data = await getQustomQuery(
        ordersTestCollections,
        "searchTerm",
        "array-contains",
        searchCriteria
      );
      console.log(data);
      data = data.filter((e) => e.vendor === currentUser.name);
      if (!data) return;
      setOrders((prev) => [
        ...data.map((e) => ({ ...e, isSelected: false })),
        ...prev,
      ]);
    }
  };
  var ordersView=currentPage.action==="inc" ?orders.slice(0,currentPage.number*limit):orders.slice(0,-limit);
  const incrementCurrentPage = () => {
    if (!cursor) return;
    if(orders.length===ordersView.length) {
      fetchOrders();
    };
    setCurrentPage((prev) =>({
      number:prev.number+1,
      action:"inc"
    }));

  };
  const decrementCurrentPage = () => {
    if (currentPage.number <= 1) return;
    setCurrentPage((prev) =>({
      number:prev.number-1,
      action:"dec"
    }));
  };
  const ExportData = (data) => {
    console.log(data)
    if(!data) {
      toast.error("No Data Found")
      return;
    };
    toast.success("Export Done")
    ExportExcel([
      ...data.map((e) => ({
        vendor: e.vendor,
        order_id: `T-${e.vendor}${e.id}`,
        barcode: e.id,
        amount: Number(e.amount || 0),
        createAt: dayjs(e.createAt.seconds * 1000).format("YYYY-MM-DD") || " ",
        customer_username: e.name,
        customer_address: e.location,
        status: e.status,
        longitude: e.longitude,
        city: e.city,
        latitude: e.latitude,
        customer_phone: e.phone,
      })),
    ]);
  };

  return (
    <>
      <div className="vendorPortal">
        <div className="header">
          <div className="order_count">
            <span>Total Order :</span>
            <span>{ordersView.length}</span>
          </div>
          <div style={{ display: "flex" }}>
            <button
              className="green_button"
              onClick={() => setOpenUploadOrdersModal(true)}
            >
              Create Order
            </button>
            {ordersView.length!==0&&    <ReactToPrint
              trigger={() => (
                <div className="print_button">
                  <div>
                    <BsFillPrinterFill />
                  </div>
                  <div>Print</div>
                </div>
              )}
              content={() => refComp.current}
            />}
        
            <button className="gray_button" onClick={() =>setOpenDateFilterOrderModal(true)}>
              Export
            </button>
          </div>
        </div>
        <div className="table_content">
          <div className="filters_components">
            <DatePickerOrder
              startDate={startDate}
              endDate={endDate}
              setDateRange={setDateRange}
            />
                    <div className="select_style">
          <Select
            options={Object.keys(Order_status).map(e=>({
              label:e.toUpperCase(),
              value:e
            }))}
            name="colors"
            value={orderStatus}
            onChange={(val) => {
             setOrderStatus(val)
            }}
            isClearable={true}
            placeholder="Status"
            styles={custom_select_style()}
          />
        </div>

            <div className="text_filter">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M13.4097 14.8822C11.7399 16.1799 9.63851 16.7922 7.53338 16.5942C5.42824 16.3963 3.47766 15.403 2.07881 13.8166C0.679961 12.2303 -0.0619809 10.1701 0.00405863 8.05565C0.0700982 5.94118 0.939153 3.9314 2.43427 2.43552C3.92939 0.939633 5.93814 0.0701341 8.05152 0.00406071C10.1649 -0.0620127 12.224 0.680308 13.8096 2.07987C15.3951 3.47944 16.3879 5.43102 16.5857 7.53723C16.7836 9.64345 16.1717 11.7459 14.8745 13.4166L19.6936 18.2201C20.1016 18.6267 20.1022 19.2872 19.695 19.6946C19.2878 20.1021 18.6273 20.1017 18.2204 19.6939L13.4201 14.8822H13.4097ZM8.31916 14.5495C9.13773 14.5495 9.94829 14.3882 10.7045 14.0748C11.4608 13.7614 12.148 13.302 12.7268 12.7229C13.3056 12.1438 13.7647 11.4563 14.078 10.6996C14.3913 9.94298 14.5525 9.13201 14.5525 8.31302C14.5525 7.49403 14.3913 6.68306 14.078 5.92641C13.7647 5.16976 13.3056 4.48225 12.7268 3.90314C12.148 3.32402 11.4608 2.86465 10.7045 2.55123C9.94829 2.23782 9.13773 2.07651 8.31916 2.07651C6.66598 2.07651 5.08051 2.73356 3.91153 3.90314C2.74256 5.07271 2.08583 6.659 2.08583 8.31302C2.08583 9.96705 2.74256 11.5533 3.91153 12.7229C5.08051 13.8925 6.66598 14.5495 8.31916 14.5495Z"
                    fill="#4F5B67"
                  />
                </svg>
              </span>
              <input
                onKeyPress={(e) => handelSearch(e)}
                placeholder="Search For Order Id,Phone Number"
                onChange={(e) => setSearchCriteria(e.target.value)}
                value={searchCriteria}
              />
            </div>
          </div>
          <div className="bottom_border"></div>

          <div className="table_container">
            <div className="table_header">
              <span className="small_width">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setOrders((prev) => {
                      prev = prev.map((od) => ({
                        ...od,
                        isSelected: e.target.checked,
                      }));
                      return [...prev];
                    });
                  }}
                />
              </span>
              <span className="small_width">#</span>
              <span>Order ID</span>
              <span>Location</span>
              <span>City</span>
              <span className="small_width">Amount</span>
              <span>Customer</span>
              <span>Phone</span>
              <span>Date</span>
              <span>Status</span>
            </div>
            {ordersView.length !== 0 &&
              ordersView
                .filter((od) =>
                  od.searchTerm.toString().includes(searchCriteria)&&(orderStatus?od.status===orderStatus.value:od)
                )
                .map((od, index) => (
                  <div className="table_body" key={index}>
                    <span className="small_width">
                      <input
                        type="checkbox"
                        checked={od.isSelected}
                        onChange={(e) => selectItem(od, e.target.checked)}
                      />
                    </span>
                    <span
                      className="small_width"
                      style={{ fontWeight: "bold" }}
                    >
                      {index + 1}
                    </span>
                    <span>{od.id}</span>
                    <span>{od.location} </span>
                    <span>{od.city}</span>
                    <span className="small_width">
                      <CurrencyIqFormat val={od.amount} />
                    </span>
                    <span>{od.name}</span>
                    <span>{od.phone}</span>
                    <span>
                      {dayjs(od.createAt.seconds * 1000).format(
                        "YYYY-MM-DD hh:mm"
                      )}
                    </span>
                    <span className={`order_status ${od.status}`}>
                      {od.status==="processing"?od.pointStatus||od.status:od.status}
                    </span>
                  </div>
                ))}
          </div>
        </div>
        <div className="footer">
          <div className="content">
            <div
              onClick={() => decrementCurrentPage()}
              className={` ${
                currentPage.number <= 1 ? "disable_button" : ""
              } previous_pagination_button`}
            >
              <img alt="" src="/Images/pagination_previous_icon.svg" />
            </div>
            <div
              className={` ${
                !cursor&&currentPage.number>1 ? "disable_button" : ""
              } next_pagination_button`}
              onClick={() => incrementCurrentPage()}
            >
              <img alt="" src="/Images/pagination_next_icon.svg" />
            </div>
          </div>
        </div>
      </div>
      <div id="Invoice_print" ref={refComp}>
        {orders
          .filter((e) => e.isSelected)
          .map((e, index) => (
            <OrderReceipt key={index} task={e} id={e.id}  vendorNumber={currentUser.phoneNumber||""}/>
          ))}
      </div>
      {openUploadOrdersModal && (
        <UploadBulkOrders
          isOpen={openUploadOrdersModal}
          setIsOpen={setOpenUploadOrdersModal}
        />
      )}
      {openDateFilterOrderModal && (
        <DateFilterComponents
          isOpen={openDateFilterOrderModal}
          setIsOpen={setOpenDateFilterOrderModal}
          callback={ExportData}
        />
      )}
    </>
  );
};
