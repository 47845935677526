import { useMutation, useQuery } from "@tanstack/react-query";
import { Dialog, DialogContent } from "../../../Components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../Components/ui/table";
import { useContext, useState } from "react";
import { AuthContext } from "../../../Config/Context";
import { fetchAllVendors } from "../../../Services/Fetchers";
import {
  VendorStatus,
  manageVendorStore,
  vendorRegistrationModalSteps,
} from "../store";
import { cn, getVendorStatusStyle } from "../../../lib/utils";
import { Badge } from "../../../Components/ui/badge";
import { YesOrNo } from "../../../Components/ui/YesOrNo";
import { approveVendor, declineVendor } from "../../../Services/Mutations";
import { toast } from "react-toastify";
import { Loader2 } from "lucide-react";
import { Input } from "../../../Components/ui/input";
import { Button } from "../../../Components/ui/button";
import Provinces from "../../../Assets/DataSchema/Provinces.json";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { setDocumnet } from "../../../Middleware/FirebaseFunction";
import {
  vendorCodCollection,
  vendorCollection,
} from "../../../Config/Collections";
import dayjs from "dayjs";
export const ApproveRegistration = () => {
  const { vendors, currentUser,setLoading } = useContext(AuthContext);
  const { setVendorApproveDetails, vendorApproveDetails ,setVendorRegistrationModalOpen} = manageVendorStore();

  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required("English Name is required"),
    arabicName: Yup.string().required("Arabic Name is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    location: Yup.string().required("Location is required"),
    inPrice: Yup.number()
      .typeError("In Price must be a number")
      .positive("In Price must be a positive number")
      .required("In Price is required"),
    outPrice: Yup.number()
      .typeError("Out Price must be a number")
      .positive("Out Price must be a positive number")
      .required("Out Price is required"),
    freeOrder: Yup.number()
      .typeError("freeOrder must be a number")
      .positive("freeOrder must be a positive number")
      .default(0)
      .min(0),
    city: Yup.object().nullable().required("City is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:{
      inPrice:0,
      arabicName:vendorApproveDetails.arabicName,
      englishName:vendorApproveDetails.englishName,
     phoneNumber:vendorApproveDetails.phoneNumber,
     location:vendorApproveDetails.nearestPoint,

    }
  });

  console.log(vendorApproveDetails)
  const createLocalVendor = async (data) => {
    console.log(data)
    if (
      ![...vendors.map((e) => e.value?.toLowerCase())]
        .toString()
        .includes(data.englishName?.toLowerCase()) &&
      vendors.filter((e) =>
        data.englishName?.toLowerCase().includes(e.value.toLowerCase())
      ).length == 0
    ) {
      await setDocumnet(
        vendorCollection,
        `${Math.floor(10000000 + Math.random() * 999999999)}`,
        {
          value: data.englishName,
          label: data.arabicName,
          createdAt: dayjs(new Date()).format("YYYY-MM-DD"),
          in_price: data.inPrice,
          address: data.location,
          out_price: data.outPrice,
          status: true,
          phone: data.phoneNumber,
          city: data.city.value,
          free_order: Number(data.freeOrder),
          createBy: currentUser.name,
          lineCoreId:vendorApproveDetails.id,
        }
      );
      await setDocumnet(vendorCodCollection, data.englishName, {
        value: 0,
      });
    }
  };

  const approveVendorMutation = useMutation({
    mutationFn: () => approveVendor(vendorApproveDetails.id),
    onSuccess: async() => {
      {
        setLoading(true);
        const payload=getValues();
        console.log(payload)
       await createLocalVendor(payload);
       toast.success("setVendorRegistrationModalOpen");
       setVendorRegistrationModalOpen(false);
        setLoading(false);
      }
    },
  });

  const onSubmit = (data) => {
    console.log(vendorApproveDetails)
    approveVendorMutation.mutateAsync();
  };

  return (
    <>
      <DialogContent className={" overflow-y-scroll max-h-screen h-[70vh]  "}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col border items-start w-full gap-4 p-2 my-4"
        >
          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-between w-full">
              <p className="mx-2">English Name : </p>
              <Controller
                name="englishName"
                control={control}
                render={({ field }) => (
                  <Input
                    className="border border-[#D0D7DC] rounded-md px-2"
                    {...field}
                    placeholder="English Name"
                  />
                )}
              />
            </div>
            <p className="text-xs text-red-700">
              {errors.englishName && <p>{errors.englishName.message}</p>}
            </p>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-between w-full">
              <p className="mx-2">Arabic Name : </p>
              <Controller
                name="arabicName"
                control={control}
                render={({ field }) => (
                  <Input
                    className="border border-[#D0D7DC] rounded-md px-2"
                    {...field}
                    placeholder="Arabic Name"
                  />
                )}
              />
            </div>
            <p className="text-xs text-red-700">
              {errors.arabicName && <p>{errors.arabicName.message}</p>}
            </p>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-between w-full">
              <p className="mx-2">Phone Number : </p>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <Input
                    className="border border-[#D0D7DC] rounded-md px-2"
                    {...field}
                    placeholder="Phone Number"
                  />
                )}
              />
            </div>
            <p className="text-xs text-red-700">
              {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
            </p>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-between w-full">
              <p className="mx-2">Location : </p>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Input
                    className="border border-[#D0D7DC] rounded-md px-2"
                    {...field}
                    placeholder="Location"
                  />
                )}
              />
            </div>
            <p className="text-xs text-red-700">
              {errors.location && <p>{errors.location.message}</p>}
            </p>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-between w-full">
              <p className="mx-2">In Price : </p>
              <Controller
                name="inPrice"
                control={control}
                render={({ field }) => (
                  <Input
                    className="border border-[#D0D7DC] rounded-md px-2"
                    {...field}
                    placeholder="In Price"
                  />
                )}
              />
            </div>
            <p className="text-xs text-red-700">
              {errors.inPrice && <p>{errors.inPrice.message}</p>}
            </p>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-between w-full">
              <p className="mx-2">Out Price : </p>
              <Controller
                name="outPrice"
                control={control}
                render={({ field }) => (
                  <Input
                    className="border border-[#D0D7DC] rounded-md px-2"
                    {...field}
                    placeholder="Out Price"
                  />
                )}
              />
            </div>
            <p className="text-xs text-red-700">
              {errors.outPrice && <p>{errors.outPrice.message}</p>}
            </p>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-between w-full">
              <p className="mx-2">Free Order : </p>
              <Controller
                name="freeOrder"
                control={control}
                render={({ field }) => (
                  <Input
                    className="border border-[#D0D7DC] rounded-md px-2"
                    {...field}
                    placeholder="Free Order"
                  />
                )}
              />
            </div>
            <p className="text-xs text-red-700">
              {errors.freeOrder && <p>{errors.freeOrder.message}</p>}
            </p>
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-between w-full">
              <p className="mx-2">City : </p>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={Provinces}
                    isClearable={true}
                    className="w-[100%]"
                    placeholder="Country"
                  />
                )}
              />
            </div>
            <p className="text-xs text-red-700">
              {errors.city && <p>{errors.city.message}</p>}
            </p>
          </div>
          <div className="w-full flex justify-center">
            <button
            disabled={approveVendorMutation.isPending}
              type="submit"
              onClick={()=>handleSubmit(onSubmit)}
              className="bg-green-600 w-[50%] text-lg text-white p-2 hover:opacity-80"
            >
              Submit
              {approveVendorMutation.isPending && (
                      <div className="flex h-full  items-center justify-center">
                        <Loader2 className=" animate-spin w-4 h-4 mx-2" />
                      </div>
                    )}
            </button>
          </div>
        </form>
      </DialogContent>
    </>
  );
};
