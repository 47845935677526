import customStyles from "../../Assets/styles/ModalStyle.json";
import Modal from "react-modal";
import ReactToPrint from "react-to-print";
import { useEffect, useRef, useState } from "react";
import { GlobalPaymentPrint } from "./GolbalPaymentPrint";
import dayjs from "dayjs";
import { BsPlusLg } from "react-icons/bs";
import { RiSave3Line } from "react-icons/ri";
import {
  GlobalBillingCollection,
  PaymentsCollections,
} from "../../Config/Collections";
import {
  getSingleDoc,
  updateMultiDoc,
} from "../../Middleware/FirebaseFunction";

import { CurrencyInput } from "../../Components/Input/CurrencyInput";
import { MdDeleteForever } from "react-icons/md";
import { useContext } from "react";
import { AuthContext } from "../../Config/Context";
import { PaymentPrint } from "../Payments/PaymentPrint";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { Permission_type } from "../../Config/Permission";
import { toast } from "react-toastify";
import { PaymensLabel } from "../Payments/PaymentLabel";
import { BsFileEarmarkExcel } from "react-icons/bs";
import { ExportExcel } from "../../Components/ExportExcel";
export const BillModal = ({ isOpen, setIsOpen, billView }) => {
  const [bill, setBill] = useState(billView);
  const { setLoading, currentUser } = useContext(AuthContext);
  const [payments, setPayments] = useState([]);
  const [isChange, setIsChange] = useState(true);
  const refComp = useRef();
  const refLabels = useRef();
  const getData = async () => {
    let pays = [];
    setLoading(true);
    const unresolved = bill.payments.map(async (e, index) => {
      let data = await getSingleDoc(PaymentsCollections, e.payId);
      if (data) pays = [...pays, data];
    });
    const resolved = await Promise.all(unresolved);
    setLoading(false);
    setPayments([...pays]);
  };
  useEffect(() => {
    if (currentUser.permission.includes("create_invoice")) {
      getData();
    }
  }, []);
  const saveBill = async () => {
    setLoading(true)
    await updateMultiDoc(GlobalBillingCollection, bill.id, {
      payments: [
        ...bill.payments.map((pay) => ({
          ...pay,
          payCreateAt:
          pay.payCreateAt || dayjs(new Date()).format("YYYY-MM-DD"),
        })),
      ],
    });
    setIsChange(false);
    setLoading(false);
    toast.success("تم الحفظ")
  };
const ExportOrders=()=>{
  var data=payments.map(e=>e.orders).flat();
  ExportExcel(data.map(e=>({
    createAt:dayjs(e.createAt).format("YYYY-MM-DD"),
    barcode:e.orderId,
    agent_received:e.agent_received,
    shipping:e.shipping+e.specialPrice||0,
    city:e.city,
    note:e.note
  })))
  console.log(data)
}
  return (
    <>
      <Modal
        isOpen={isOpen}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        <div className="modal_container" style={{ height: "500px" }}>
          <div className="close_img">
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              alt="close"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="payment_modal">
            <div>
              <p>Bill ID : {bill.id}</p>
              <p>Create At : {bill.createAt}</p>
              <p>Total : {bill.payments.length}</p>
            </div>
            <PermissionsGate permission={Permission_type.create_invoice}>
              <div className="flex">
                {bill.payments.filter((pay) => !pay.payId || !pay.vendorName)
                  .length == 0 &&
                  JSON.stringify([
                    ...bill.payments.map((e) => e.payId || ""),
                  ]) !=
                    JSON.stringify([
                      ...billView.payments.map((e) => e.payId || ""),
                    ]) &&
                  isChange && (
                    <span className="save_btn" onClick={() => saveBill()}>
                      <RiSave3Line
                        className="hover_icon"
                        fill="#1C89FF"
                        radius={"20px"}
                      />
                    </span>
                  )}

                <span
                  className="add_container"
                  onClick={() =>
                    setBill((r) => ({
                      ...r,
                      payments: [
                        ...r.payments,
                        {
                          vendorNum: "",
                          vendorAdd: "",
                        },
                      ],
                    }))
                  }
                >
                  <BsPlusLg
                    className="hover_icon"
                    fill="#4d9429"
                    radius={"20px"}
                  />
                </span>

                <ReactToPrint
                  trigger={() => (
                    <button className="flex">
                      <div className="pending">Print All Payment</div>
                    </button>
                  )}
                  content={() => refComp.current}
                />
                <ReactToPrint
                  trigger={() => (
                    <button className="flex">
                      <div className="Export_green">Print Lables</div>
                    </button>
                  )}
                  content={() => refLabels.current}
                />
                    <button className="flex" onClick={()=>ExportOrders()}>
                      <div className="Export_green" >Download Orders</div>
                    </button>
                 <BsFileEarmarkExcel
                onClick={() => ExportExcel([...bill.payments])}
                fill="green"
                size={"30px"}
                style={{ cursor: "pointer", margin: "3px" }}
              />
               
              </div>
            </PermissionsGate>
          </div>
          <div style={{ marginTop: "40px" }}>
            <table style={{ width: "100%" }}>
              <thead style={{ fontSize: "12px" }}>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                    }}
                  >
                    Id
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                    }}
                  >
                    Paid Amount
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                    }}
                  >
                    Vendor Name
                  </th>
                  <th
                    style={{
                      backgroundColor: "#EC1035",
                      color: "white",
                      padding: "2px",
                    }}
                  >
                    Status{" "}
                  </th>
                  <th
                    style={{
                      backgroundColor: "white",
                      color: "white",
                      padding: "2px",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "12px", textAlign: "center" }}>
                {payments.length !== 0 &&
                  bill.payments.map((e, index) => (
                    <tr key={index}>
                      <td
                        style={{ padding: "2px", border: "1px solid #f3f2f2" }}
                      >
                        {dayjs(new Date(e.payCreateAt || new Date())).format(
                          "YYYY-MM-DD"
                        )}
                      </td>
                      <td
                        style={{
                          padding: "2px",
                          border: "1px solid #f3f2f2",
                          textAlign: "center",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            display: "flex",
                            border: "none",
                            height: "20px",
                            zIndex: "100",
                            position: "relative",
                            backgroundColor: "white",
                          }}
                          value={e.payId || ""}
                          disabled={e.payCreateAt ? true : false}
                          onChange={(e) =>
                            setBill((prev) => {
                              prev.payments[index].payId = e.target.value;
                              return { ...prev };
                            })
                          }
                        />
                      </td>
                      <td
                        style={{
                          padding: "2px",
                          border: "1px solid #f3f2f2",
                          textAlign: "center",
                        }}
                      >
                        {
                          <CurrencyInput
                            disabled={e.payCreateAt ? true : false}
                            value={e.totalPrice || 0}
                            onchange={(e) => {
                              setBill((prev) => {
                                prev.payments[index].totalPrice = e.floatValue;
                                return { ...prev };
                              });
                            }}
                            style={"empty_input"}
                          />
                        }
                      </td>
                      <td
                        style={{
                          padding: "2px",
                          border: "1px solid #f3f2f2",
                          textAlign: "center",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            display: "flex",
                            border: "none",
                            height: "20px",
                            zIndex: "100",
                            position: "relative",
                            backgroundColor: "white",
                          }}
                          value={e.vendorName || ""}
                          disabled={e.payCreateAt ? true : false}
                          onChange={(e) =>
                            setBill((prev) => {
                              prev.payments[index].vendorName = e.target.value;
                              return { ...prev };
                            })
                          }
                        />
                      </td>
                      <td
                        style={{
                          padding: "2px",
                          border: "1px solid #f3f2f2",
                          textAlign: "center",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            display: "flex",
                            border: "none",
                            height: "20px",
                            zIndex: "100",
                            position: "relative",
                            backgroundColor: "white",
                          }}
                          value={
                            payments?.find((c) => c.id === e.payId)?.paid
                              ? "Paid"
                              : "Pending"
                          }
                          disabled={e.payCreateAt ? true : false}
                        />
                      
                      </td>

                      <td
                        style={{
                          // padding: "2px",
                          border: "1px solid #f3f2f2",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setBill((prev) => {
                            console.log(prev.payments[index]);
                            if (prev.payments[index].payId == null) {
                              prev.payments.pop();
                            } else {
                              prev.payments = prev.payments.filter(
                                (c) => c.payId !== e.payId
                              );
                              console.log(prev);
                            }

                            return { ...prev };
                          });
                        }}
                      >
                        <MdDeleteForever fill="red" size={"20px"} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      <div id="Invoice_print" ref={refComp}>
        <GlobalPaymentPrint bill={bill} />
        <div style={{ pageBreakAfter: "always" }}></div>
        {payments.map((e, index) => (
          <PaymentPrint
            id={e.id}
            vendorOrders={e.orders}
            vendorPrice={e.vendor}
            status={e.status}
            key={index}
          />
        ))}
      </div>
      <div id="" ref={refLabels}>
        <div style={{ pageBreakAfter: "always" }}></div>
        {payments.map((e, index) => (
          <PaymensLabel
            id={e.id.toLowerCase().replace(`${e.vendor.value.toLowerCase()}`,'')
          }
            vendorOrders={e.orders}
            vendorPrice={e.vendor}
            status={e.status}
            key={index}
          />
        ))}
      </div>
    </>
  );
};
