import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { addDoc, serverTimestamp } from "firebase/firestore";
import { citiesCollection } from "../../Config/Collections";

export default function DrawModal({ coordinates, open, setOpen }) {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleRegister = async () => {
    try {
      console.log(coordinates);
      setLoading(true);
      await addDoc(citiesCollection, {
        name,
        coordinates: [...coordinates],
        createdAt: serverTimestamp(),
        status: "pending",
        agents: [],
      });
      setLoading(false);
      setOpen(false);
      toast.info(`Thank you for registering ${name}`);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.warning(
        `Sorry ${name}, there was a problem; Please, try again later.`
      );
    }
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Tamata Agent Subscription Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tamata is Iraq's largest marketplace for intracity logistics,
            providing the fastest, cheapest, & easiest way to transport goods &
            deliver courier!
          </DialogContentText>
          <TextField
            // autoFocus
            margin="dense"
            placeholder="Al Karadah"
            id="name"
            label="City Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setName(x.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <LoadingButton loading={loading} onClick={handleRegister}>
            Add City
          </LoadingButton> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
