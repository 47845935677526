import Button from "@mui/material/Button";
import customStyles from "../../Assets/styles/custom_modal.json";
import Modal from "react-modal";
import {useContext, useState} from "react";
import {ReadExcel} from "../../Components/ReadExcel";
import dayjs from "dayjs";
import {CurrencyIqFormat} from "../../Components/Input/CurrencyIqFormat";
import {PointAuth} from "../../Middleware/TamataPoint";
import {FetchById} from "../../Middleware/FetchById";
import {AuthContext} from "../../Config/Context";
import {GroupBy} from "../../Helper/GroupBy";
import {ExportExcel} from "../../Components/ExportExcel";
import {ReurnInv} from "./ReturnInv";
import {
  getMultiDoc,
  getSingleDoc,
  updateMultiDoc,
} from "../../Middleware/FirebaseFunction";
import {ordersCollections} from "../../Config/Collections";
import {IoIosAlert, IoMdAlarm} from "react-icons/io";
import ReactToPrint from "react-to-print";
import {Order_status} from "../../Assets/DataSchema/OrderStatus";
import {toast} from "react-toastify";
import {serverTimestamp} from "firebase/firestore";
export const ReturnModal = ({open, setOpen}) => {
  const [returnOrders, setReturnOrders] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const {setLoading} = useContext(AuthContext);

  const CreateInvoce = async () => {
    setLoading(true)
    const unresolved = returnOrders.filter(e=>e.createAt)
    .map(async (e, index) => {
      console.log(e)
      let res = await updateMultiDoc(ordersCollections, e.barcode.toString(), {
        status: Order_status.return_vendor,
        updateAt: serverTimestamp(),
      });
      if (res) {
        toast.success(e.barcode);
        return;
      }
      toast.warning(`Error At ${index}`);
    });
    
    const resolved = await Promise.all(unresolved);
    setLoading(false)

    setIsComplete(true);
  };

  const fetchOrders = async (data) => {
    try {
      console.log(data)
      var newData = [],
        comments = [];
      const unresolved = data.map(async (e, index) => {
        try {
          setLoading(true);
          
          let res = await getSingleDoc(ordersCollections, e.id.toString());
          console.log(res);
          if (res) {
            newData.push({
              createAt: res.createAt || "",
              location: res.location || "",
              vendor: res.vendor || "",
              vendorId: res.vendorId || "",
              city: res.city || "",
              amount: res.amount || "",
              phone: res.phone || "",
              userName: res.phone || "",
              barcode: res.id,
              comment: "راجع",
            });
          } else {
            newData.push({
              barcode: e.id,
              comment: "راجع",
            });
          }
        } catch (error) {
          console.log(error, index, e);
        }
      });

      const resolved = await Promise.all(unresolved);

      for (let i = 0; i !== Math.ceil(newData.length / 100); i = i + 1) {
        let b = await FetchById("POST", {
          order_ids: [...newData.slice(i * 100, i * 100 + 100)].map(
            (e) => `T-${e.vendor}${e.barcode}`
          ),
        });
        comments = [...comments, ...b];
      }
      newData = newData.map((e, index) => ({
        ...e,
        comment:
          comments.find((r) => r.barcode == e.barcode)?.driver_comment || "",
        alert:
          comments.find((r) => r.barcode == e.barcode)?.status !== 3
            ? true
            : false,
      }));

      setLoading(false);
      // newData = GroupBy([...newData], "vendor");
      setReturnOrders([...newData]);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Server Error");
    }
  };

  const uploadCsv = (e) => {
    e.preventDefault();
    ReadExcel(e, (file) => {
      if (!file) return;
      console.log(file);
      fetchOrders([...file]);
    });
  };
  const Print = () => {



    ExportExcel([...returnOrders]);

    var content = document.getElementById("Invoice_print");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  return (
    <>
      <div>
        <Modal
          isOpen={open}
          contentLabel="Example Modal"
          ariaHideApp={false}
          style={customStyles}
        >
          <div>
            <div
              className="Modal_header"
              style={{
                borderBottom: "1px solid #eee",
                marginBottom: "10px",
                padding: "10px 0",
              }}
            >
              <Button
                onClick={() => CreateInvoce()}
                color="success"
                variant="contained"
              >
                Create Invocies
              </Button>

              <div>
                <input
                  onChange={(e) => uploadCsv(e)}
                  accept=".xlsx"
                  id="file"
                  type="file"
                ></input>
              </div>
              <img
                onClick={() => setOpen(false)}
                width="20"
                height="30"
                src="/Images/close_modal.svg"
                style={{cursor: "pointer"}}
              />
            </div>
            {isComplete && (
              <ReactToPrint
                trigger={() => (
                  <button className="print-button">
                    <span className="print-icon"></span>
                  </button>
                )}
                content={() => {
                  return Print();
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "10px",
              }}
            >
              <div>
                Total Vendors:
                <span style={{color: "red", fontWeight: "600"}}>
                  {Object.keys(GroupBy([...returnOrders], "vendor")).length ||
                    0}
                </span>
              </div>
              <div>
                Total Orders:
                <span style={{color: "red", fontWeight: "600"}}>
                  {returnOrders.length || ""}
                </span>
              </div>
            </div>
            <div className="payments_conatiner">
              <div className="table_container">
                <div className="thead">
                  <span className="small_width">Order Date</span>
                  <span className="small_width">Vendor</span>
                  <span className="small_width">Barcode</span>
                  <span className="small_width">Amount</span>
                  <span className="small_width">Return Date</span>
                  <span className="small_width">Comment</span>
                  <span className="small_width"></span>
                </div>
                {returnOrders.map((e, index) => (
                  <div className="tbody" key={index*Math.random()}>
                    <span className="small_width">
                      {e.createAt&&dayjs(e.createAt?.seconds * 1000).format("YYYY-MM-DD") ||
                        ""}
                    </span>
                    <span className="small_width">{e.vendor || ""}</span>
                    <span className="small_width">{e.barcode || ""}</span>
                    <span className="small_width">
                      <CurrencyIqFormat val={e.amount || 0} />
                    </span>
                    <span className="small_width">
                      {dayjs(new Date()).format("YYYY-MM-DD")}
                    </span>
                    <span className="small_width">{e.comment || ""}</span>
                    <span className="small_width">
                      {e.alert && <IoIosAlert fill="red" />}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div id="Invoice_print">
            {returnOrders.map((e) => (
              <ReurnInv id={e.barcode} orders={[{...e}]} />
            ))}
          </div>
        </Modal>
      </div>
    </>
  );
};
