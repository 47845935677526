import {
  arrayUnion,
  deleteDoc,
  doc,
  documentId,
  endAt,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import {ordersCollections} from "../Config/Collections";
import dayjs from "dayjs";

export const getAllDoc = async (collection) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(collection);
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const setDocumnet = async (collection, id, payload) => {
  console.log(id);
  if (!collection || !id) return;
  try {
    await setDoc(doc(collection, id), {
      ...payload,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const updateMultiDoc = async (collection, id, data) => {
  if (!collection || !id) return;
  try {
    await updateDoc(doc(collection, id), {
      ...data,
    });
  return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateDocument = async (collection, id, field, payload) => {
  if (!collection || !id) return;
  try {
    await updateDoc(doc(collection, id), {
      [field]: payload,
    });
  } catch (error) {
    console.log(error);
    return;
  }
};
export const updateArrayDoc = async (collection, id, field, payload) => {
  console.log(payload);
  if (!collection || !id || !payload) return;
  try {
    await updateDoc(doc(collection, id), {
      [field]: arrayUnion(...payload),
    });
  } catch (error) {
    console.log(error);
    return;
  }
};
export const getSingleDoc = async (collection, payload) => {
  if (!collection || !payload) return;
  var docSnap;
  try {
    var docSnap = await getDoc(doc(collection, payload));
    if (docSnap.exists()) return { ...docSnap.data(), id: docSnap.id };
  } catch (error) {
    console.log(error);
    return;
  }
};
export const getDocumnet = async (collection, payload) => {
  if (!collection || !payload) return;
  var data = [];
  try {
    const mydata = await getDocs(
      query(collection, where(documentId(), "==", payload))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data[0];
  } catch (error) {
    console.log(error);
    return;
  }
};
export const getMultiDoc = async (collection, payload, field) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(
      query(collection, where(field, "==", payload))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
};
export const getQustomQuery = async (collection, field,queryType,payload) => {
  if (!collection) return;
  var data = [];
  try {
    const mydata = await getDocs(
      query(collection, where(field, queryType, payload))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
};

export const LimitPagination = async (collection, payload, limitation) => {
  if (!collection) return;
  var data = [];
  try {
    const q = query(collection, orderBy(payload, "desc"), limit(limitation));
    const mydata = await getDocs(q);
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getDocArrayContains = async (
  collection,
  field,
  queryCond,
  payload,
  startDate,
  endDate
) => {
  if (!collection || !queryCond || !field) return;
  var data = [];

  try {

    const mydata = await getDocs(
        query(collection, where(field, queryCond, payload),orderBy("createAt"), startAt(startDate),endAt(endDate))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    console.log(data)
    return data;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const deleteDocument = async (collection, id) => {
  if (!collection || !id) return;
  try {
    await deleteDoc(doc(collection, id));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};


export const getQueryDocs = async (
  collection,
  field,
  queryCond,
  payload,

) => {
  if (!collection || !queryCond || !field) return;
  var data = [];
  try {
    while (payload.length) {
      const batch = payload.splice(0, 10);
      const mydata = await getDocs(
        query(
          collection,
          where(field, queryCond, [...batch])
        )
      );
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
    }
    return Promise.all(data).then((content) => content.flat());
  } catch (error) {
    console.log(error);
    return;
  }
};
export const  getVendorOrders=async(vendor,status,startDate,endDate,dateFilterType)=>{
  var data = [];
  console.log(vendor,status,startDate,endDate,dateFilterType)
  try {
    const mydata = await getDocs(
      query(ordersCollections,where("vendor","==",vendor),where("status","==",status),where(dateFilterType,">=",startDate),where(dateFilterType,"<=",endDate),orderBy(dateFilterType,"asc")));
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
}
export const  getVendorOrdersByDate=async(vendor,startDate,endDate,cursor,count)=>{
  var data = [];
  try {
    const queryConstraint=[
      ordersCollections,where("vendor","==",vendor),where("createAt",">=",dayjs(startDate).startOf("day").toDate()),where("createAt","<=",dayjs(endDate).add(1,"day").startOf("day").toDate()),orderBy("createAt","asc"),limit(count)
    ];
    if(cursor){
      queryConstraint.push(startAfter(cursor.createAt))
    };
    console.log(queryConstraint)
    const mydata = await getDocs(
      query(...queryConstraint)
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    console.log(data)
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
}
export const  getRangeOrderByDate=async(status,startDate,endDate)=>{
  var data = [];
  console.log(status,startDate,endDate)
  try {
    const mydata = await getDocs(
      query(ordersCollections,where("status","==",status),where("createAt",">=",startDate),where("createAt","<=",endDate))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
}
export const  getRangeDataByDate=async(collection,startDate,endDate)=>{
  var data = [];
  console.log(collection,startDate,endDate)
  try {
    const mydata = await getDocs(
      query(collection,where("createAt",">=",startDate),where("createAt","<=",endDate))
    );
    mydata.docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return [...data];
  } catch (error) {
    console.log(error);
    return;
  }
}