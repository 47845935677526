import dayjs from "dayjs";
import { onSnapshot, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { SearchInput } from "../../Components/Input/SearchInput";
import { GlobalBillingCollection } from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import { getAllDoc } from "../../Middleware/FirebaseFunction";
import { BillModal } from "./BillModal";
export const GlobalBill = () => {
  const [search, setSearch] = useState("");
  const [bills, setBills] = useState("");
  const [billView, setBillView] = useState({});
  const { setLoading, currentUser } = useContext(AuthContext);
  const [paymentDate, setPaymentDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const getData = async (e) => {
    console.log(e)
    const q = e
      ? query(GlobalBillingCollection, where("createAt", "==", paymentDate))
      : query(
          GlobalBillingCollection,
          where(
            "createAt",
            ">",
            dayjs(new Date()).subtract(10, "day").format("YYYY-MM-DD")
          )
        );
    const unsubscriber = onSnapshot(q, (snapshot) => {
      var a = [];
      snapshot.docs.map((doc) => {
        a.push({ ...doc.data(), id: doc.id });
      });
      a = a.sort((a, b) => new Date(b.createAt) - new Date(a.createAt));
      setBills([...a]);

    });
    return unsubscriber;
  };
  const openInvoice = (e) => {
    setIsOpen(true);
    setBillView(e);
  };
  useEffect(() => {
    getData(paymentDate);
  }, [paymentDate]);
  let data = bills;

  return (
    <>
      <div>
        <div className="Header_wrapper">
          <div className="flex">
            <p>Global Bill</p>
            <div className="search_container">
              <SearchInput
                placeholder="Search For Invoice"
                value={search}
                onchange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <span className="date_picker">
            <label> Payment Date : </label>
            <input
              id="meeting"
              type="date"
              onChange={(e) => {
                if (!e.target.value) {
                  setPaymentDate("");
                  return;
                }
                setPaymentDate(dayjs(e.target.value).format("YYYY-MM-DD"));
              }}
              value={paymentDate}
            />
          </span>
        </div>
        <div className="payments_conatiner">
          <div className="table_container">
            <div className="thead">
              <span>#</span>
              <span>Invoice Number</span>
              <span className="small_width">Total Payment</span>
              <span className="small_width">Total Price</span>
              <span className="small_width">Status</span>
              <span className="small_width"></span>
            </div>
            {data.length != 0 ? (
              data
                .map((e, index) => (
                  <div className="tbody" key={index + 1}>
                    <span>{index + 1}</span>
                    <span>{e.id}</span>
                    <span className="small_width">{e.payments.length}</span>
                    <span className="small_width">
                      <CurrencyIqFormat
                        val={e.payments.reduce((a, b) => a + b.totalPrice, 0)}
                      />
                    </span>
                    <span className="small_width">{e.status}</span>
                    <span
                      className="small_width view_btn"
                      onClick={() => openInvoice(e)}
                    >
                      View
                    </span>{" "}
                  </div>
                ))
            ) : (
              <div>NO paymnets</div>
            )}
          </div>
        </div>
        {isOpen && (
          <BillModal
            setIsOpen={setIsOpen}
            billView={billView}
            isOpen={isOpen}
          />
        )}
      </div>
    </>
  );
};
