import { useContext,useState } from "react";
import Button from "../Components/Button/Button";
import Input from "../Components/Input/Input";
import {  useNavigate } from "react-router";
import "../Assets/styles/loginStylee.scss";
import { auth } from "../Config/Firebase-config";
import { AuthContext } from "../Config/Context";
import { toast } from "react-toastify";
import { usersCollection } from "../Config/Collections";
import { getDocumnet } from "../Middleware/FirebaseFunction";
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setLoading } = useContext(AuthContext);

  const handelLogin = async (e) => {
    e.preventDefault();
    if (!password || !email) return;
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        getDocumnet(usersCollection, userCredential.user.uid).then((e) => {
            if (e.role.includes("ReceiptEntry")) {
            navigate("/tamata/receipts_entry");
            setLoading(false);
            return;
          }
          navigate("/");
          setLoading(false);
        });
      })
      .catch((error) => {
        toast.error("Invalid Login");
        setLoading(false);
      });
  };
 

  return (
    <>
      <div className="login-container">
        <div className="login-left-side">
          <h1 className="login-headding">Login</h1>
          <p className="login-text">
            Start using the app and track your order and cash flow
          </p>
          <div className="login-form" id="login-from">
            <form>
              <Input
                id="login-form-email"
                style="input"
                placeholder="Email"
                type="email"
                value={email}
                onchange={(e) => setEmail(e)}
              ></Input>
              <Input
                id="login-form-password"
                style="input"
                placeholder="Password"
                type="password"
                onchange={(e) => setPassword(e)}
                autocomplete={"on"}
                value={password}
              ></Input>

              <Button
                color="ceb0028"
                style="button"
                id="login-form-submit"
                title="Login"
                onclick={(e) => handelLogin(e)}
              ></Button>
            </form>
          </div>
        </div>
        <div className="logo">
          <img width="200" alt="logo" src="/Images/tamata_line2.svg" />
        </div>
      </div>
    </>
  );
};
export default Login;
