export const PointAuth = async () => {
  var raw = JSON.stringify({
    email: `${process.env.REACT_APP_LINE_PASSWORD}`,
    password: `${process.env.REACT_APP_LINE_EMAIL}`,
    returnSecureToken: true,
  });
  var requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
  };
  let response = await fetch(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.REACT_APP_TAMATA_POINT}`,
    requestOptions
  );
  let { idToken } = await response.json();
  if (!idToken) return;
  return idToken;
};

export const PointUpload = async (url, order, token) => {
  var raw = JSON.stringify(order);
  var requestOptions = {
    method: "PATCH",
    body: raw,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await fetch(url, requestOptions);
  let data = await response.json();
  console.log(data)
  return data;
};
