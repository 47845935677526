import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const getAgentStatusStyle = (status) => {
  switch (status) {
    case "New":
      return "bg-yellow-300 hover:bg-yellow-400 text-yellow-900";
 
    case "Approved":
      return "bg-green-500 hover:bg-green-600 text-white";
    case "Blocked":
      return "bg-red-300 hover:bg-red-400 text-red-900";
   
    default:
      return  "bg-yellow-300 hover:bg-yellow-400 text-yellow-900";
  }
};
export const getVendorStatusStyle = (status) => {
  switch (status) {
    case "New":
      return "bg-yellow-300 hover:bg-yellow-400 text-yellow-900";
 
    case "Approved":
      return "bg-green-500 hover:bg-green-600 text-white";
    case "Blocked":
      return "bg-red-300 hover:bg-red-400 text-red-900";
   
    default:
      return  "bg-yellow-300 hover:bg-yellow-400 text-yellow-900";
  }
};
