import dayjs from "dayjs";
import "../../Assets/styles/Invoice.scss";
import { MdOutlineNotListedLocation } from "react-icons/md";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";

import { useBarcode } from "react-barcodes";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { totalPaidPrice } from "../../Middleware/OrdersCal";
import { useEffect, useState } from "react";
import { getDocumnet, getMultiDoc } from "../../Middleware/FirebaseFunction";
import { ordersCollections, vendorCollection } from "../../Config/Collections";
export const ReurnInv = ({ id, orders = [] }) => {
  const { inputRef } = useBarcode({
    value: id,
    options: {
      background: "white",
      height: 50,
    },
  });
  // const fetchVendor = async () => {
  //   let vendor = await getMultiDoc(vendorCollection, name, "value");
  //   console.log(vendor);
  //   if (vendor.length == 0) return;
  //   setVendorDetails(vendor[0]);
  // };
  // useEffect(() => {
  //   fetchVendor();
  // }, []);
  return (
    <>
      <div
        style={{
          padding: "0 20px",
          width: "210mm",
          margin: "0 auto",
        }}
        key={id}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <p
                style={{
                  color: "#ff1d43",
                  fontWeight: "800",
                  fontSize: "42px",
                  display: "flex",
                  alignItems: "start",
                  lineHeight: "42px",
                }}
              >
                Hello
              </p>
            </div>
            <p
              style={{ color: "#ff1d43", fontWeight: "800", fontSize: "28px" }}
            >
              Return Invoice
            </p>
          </div>
          <div></div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span>
            <svg ref={inputRef} />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          <div>
            <p>Vendor Name : {orders[0]?.vendor}</p>
            {/* <p>Vendor Address :{vendorDetails?.address}</p>
            <p>Vendor Phone :{vendorDetails?.phone}</p> */}
          </div>
          <div>
            <p>Print Date :{dayjs(new Date()).format("YYYY-MM-DD")}</p>
            <p>Statement No :{id}</p>
            <p></p>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}></div>
        <table style={{ width: "100%", textAlign: "center" }}>
          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: "#EC1035",
                  color: "white",
                  padding: "2px",
                  border: "1px solid #f3f2f2",
                }}
              >
                Order Date
              </th>
              <th
                style={{
                  backgroundColor: "#EC1035",
                  color: "white",
                  padding: "2px",
                  border: "1px solid #f3f2f2",
                }}
              >
                vendor
              </th>
              <th
                style={{
                  backgroundColor: "#EC1035",
                  color: "white",
                  padding: "2px",
                  border: "1px solid #f3f2f2",
                }}
              >
                Barcode
              </th>
              <th
                style={{
                  backgroundColor: "#EC1035",
                  color: "white",
                  padding: "2px",
                  border: "1px solid #f3f2f2",
                }}
              >
                Amount
              </th>
              <th
                style={{
                  backgroundColor: "#EC1035",
                  color: "white",
                  padding: "2px",
                  border: "1px solid #f3f2f2",
                }}
              >
                Return Date
              </th>
              <th
                style={{
                  backgroundColor: "#EC1035",
                  color: "white",
                  padding: "2px",
                  border: "1px solid #f3f2f2",
                }}
              >
                Commnet
              </th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((e, index) => (
              <tr key={index}>
                <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                  {" "}
                  {dayjs(e.createAt?.seconds * 1000).format(
                    "YYYY-MM-DD"
                  ) ||""}
                </td>
                <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                  {e.vendor}
                </td>
                <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                  {e.barcode}
                </td>
                <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                  {" "}
                  <CurrencyIqFormat val={e.amount || 0} />
                </td>
                <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                  {" "}
                  {dayjs(new Date()).format("YYYY-MM-DD")}
                </td>
                <td style={{ padding: "2px", border: "2px solid #ddd" }}>
                  {e.comment || ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "50px",
          }}
        >
          <p>
            <span style={{ marginRight: "10px" }}>
              <MdOutlineNotListedLocation fill="red" />
            </span>
            Arasat Al Hindia, Baghdad Iraq
          </p>
          <p>
            {" "}
            <span style={{ marginRight: "10px" }}>
              <AiFillPhone fill="red" />
            </span>
            (+964) 780 423 1234
          </p>
          <p>
            <span style={{ marginRight: "10px" }}>
              <AiOutlineMail fill="red" />
            </span>
            support@tamata.com
          </p>
        </div>
        <div style={{ pageBreakAfter: "always" }}></div>
      </div>
    </>
  );
};
