import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getAllDoc, getSingleDoc } from "../Middleware/FirebaseFunction";
import { usersCollection, vendorCollection } from "./Collections";
import { auth } from "./Firebase-config";
import { Roles } from "./Role";

export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);

  const fetchVendors = async () => {
    let data = await getAllDoc(vendorCollection);
    setVendors([...data.filter((e) => e.value)]);
    return;
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getSingleDoc(usersCollection, user.uid).then(async (e) => {
          setCurrentUser(e);
          console.log(e);
          if (e.role === Roles.Vendor) {
            navigate("/vendor");
            setPending(false);
            setLoading(false);
            return;
          }
          await fetchVendors();

          setPending(false);
          setLoading(false);
        });
      } else {
        setPending(false);
        setLoading(false);
        setCurrentUser(null);
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, []);

  if (pending) {
    {
      return (
        <>
          <div className="loading_screen">
            <div>
              <img src="/Images/tamata_loading.gif" width="50%" />
            </div>
          </div>
        </>
      );
    }
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        setLoading,
        setCurrentUser,
        vendors,
        setVendors,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
