import {read,utils} from "xlsx/xlsx";

export const ReadExcel = async (fileInput,onLoadCallback) => {
  const reader = new FileReader();
   reader.onload =  (fileInput) => {
    const data = fileInput.target.result;
    const workbook = read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = utils.sheet_to_json(worksheet);
    onLoadCallback(json)

};
  reader.readAsArrayBuffer(fileInput.target.files[0]);
};