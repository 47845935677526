export const GetLatLong = async (location) => {
    let response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyDfefjb_ki4pyi6UaPo1EI5DoLGvcUEc4A`
    );
    let data = await response.json();
    return {
      lat: data.results[0]?.geometry.location.lat || "",
      long: data.results[0]?.geometry.location.lng || "",
    };
  };
  