import { create } from "zustand";
export const manageAgentStore = create((set, get) => ({
  addAgentModalOpen: false,
  setAddAgentModalOpen: (payload) => set({ addAgentModalOpen: payload }),
  allAgents:[],
  searchTermsFilter:"",
  setSearchTermFilter:(payload)=>set({searchTermsFilter:payload}),
  setAllAgents:(payload)=>{
    console.log(payload)
    set({
      allAgents:payload
    })
  },
  agentDetails: {},
  setAgentDetails: (payload) => {
    const lastData = get().agentDetails;
    set({
      agentDetails: {
        ...lastData,
        ...payload,
      },
    });
  },
  refetchGetAllAgent:true,
  setRefetchGetAllAgent:(payload)=>{
    set({
      refetchGetAllAgent:payload
    })
  }
}));

export const AgentStatus={
  New:"New",
  Approved:"Approved"
}