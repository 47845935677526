import {  Route, Routes } from "react-router-dom";
import {DeilveryPending} from "./DeliveryPending";
import { ReceiptsEntry } from "./ReceiptsEntry";

export const Operation = () => {
    return (
    <>
    <Routes>
      <Route path="/Pending_delivery" element={<DeilveryPending/>}/>
    </Routes>
  
    </>
  );
};
