
export const TaskAPI = async (endPoint,method = "POST", data) => {
    let response=await  fetch(`https://api.tamata.com/v2/${endPoint}`, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...data,  api_key:process.env.REACT_APP_API_TOOKAN}),
      })
      let res = await response.json();
      if(res.status==200) return res;
      return false;
        

      
  };
  