import {
  AiOutlineDashboard,
  AiOutlineDeliveredProcedure,
  AiFillCamera,
  AiFillPrinter,
  AiOutlineBarcode
} from "react-icons/ai";
import {
  GiReturnArrow
} from "react-icons/gi";
import {
  MdPayment,
  MdAccountBalance,
  MdHomeWork,
  MdOutlineBorderColor,
  MdSwitchAccount,
} from "react-icons/md";
import {
  FaTruckPickup,
  FaFileInvoiceDollar,
  FaPeopleCarry,
} from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { BiArchiveIn } from "react-icons/bi";
import { Roles } from "../../../Config/Role";
//
export const SidebarContent = [
  {
    title: "Home",
    url: "/Dashbaord",
    icon: <AiOutlineDashboard />,
    role: [Roles.Admin, Roles.Accounting, Roles.Operation],
  },
  {
    title: "Vendor Accounting",
    url: "/Vendoraccounting",
    role: [Roles.Admin, Roles.Accounting],
    icon: <MdAccountBalance />,
  },
  {
    title: "Payments",
    url: "/VendorsPayment",
    role: [Roles.Admin,  Roles.Accounting],
    icon: <MdPayment />,
  },
  {
    title: "Global Bill",
    url: "/GlobalBill",
    role: [Roles.Admin, Roles.Viewer, Roles.Accounting],
    icon: <FaFileInvoiceDollar />,
  },
  {
    title: "Pick Up",
    url: "/complete_pickup",
    role: [Roles.Admin, Roles.Operation, Roles.Accounting],
    icon: <FaTruckPickup />,
  },
  // {
  //   title: "Delivery Pending",
  //   url: "operation/Pending_delivery",
  //   role: [Roles.Admin, Roles.Operation, Roles.Accounting, Roles.DataEntry],
  //   icon: <AiOutlineDeliveredProcedure />,
  // },
  {
    title: "Data Entry",
    url: "/Data_Entry",
    role: [Roles.Admin, Roles.Operation, Roles.DataEntry],
    icon: <MdOutlineBorderColor />,
  },
  {
    title: "Capture Invoice",
    url: "/tamata/receipts_entry",
    role: [Roles.Admin, Roles.Operation, Roles.ReceiptEntry],
    icon: <AiFillCamera />,
  },
  {
    title: "Archives",
    url: "/Archive",
    role: [Roles.Admin, Roles.Operation],
    icon: <BiArchiveIn />,
  },
  {
    title: "Vendor Portal",
    url: "/vendor",
    role: [Roles.Vendor],
    icon: <BiArchiveIn />,
  },
  {
    title: "Print",
    url: "/Print",
    role: [Roles.Admin, Roles.Operation],
    icon: <AiFillPrinter />,
  },
  {
    title: "Return",
    url: "/Return",
    role: [Roles.Admin, Roles.Operation],
    icon: <GiReturnArrow />,
  },
  {
    title: "Statement of Account",
    url: "/Statement_Account",
    role: [Roles.Admin, Roles.Accounting],
    icon: <MdSwitchAccount />
},
  {
    title: "Manage Vendors",
    url: "/manage_vendors",
    role: [Roles.Admin, Roles.Operation, Roles.Accounting],
    icon: <BsFillPeopleFill />,
  },
  {
    title: "Barcodes",
    url: "/Barcodes",
    role: [Roles.Admin, Roles.Operation],
    icon: <AiOutlineBarcode />,
  },
  {
    title: "Agents",
    url: "/agents",
    role: [Roles.Admin,Roles.Viewer],
    icon: <BsFillPeopleFill fontSize="small" />,
  },
  {
    title: "Manage Agent",
    url: "/ManageAgent",
    role: [Roles.Admin,Roles.Viewer],
    icon: <BsFillPeopleFill fontSize="small" />,
  },
];
