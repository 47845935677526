import React, { useContext } from "react";
import {AuthContext} from "../Config/Context";

export const PermissionsGate = ({ children, permission }) => {
    const {currentUser}=useContext(AuthContext)
      const userHasPermission = currentUser && currentUser.permission?.includes(permission) ? true : false;
      if(userHasPermission){
        return children;
      } return<></>
  
  };