import dayjs from "dayjs";
import { useBarcode } from "react-barcodes";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";

export const GlobalPaymentPrint = ({ bill }) => {
  const { inputRef } = useBarcode({
    value: `Tamata Line ${bill.uid}` || 12,
    options: {
      background: "white",
      height: 50,
    },
  });
  return (
    <>
      <div style={{ padding: "0 70px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img src="/Images/tamata_line.svg" width={"200px"} />
          </div>
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            <p>Global Payment</p>
            <p>Print Date : {dayjs(new Date()).format("YYYY-MM-DD")}</p>
          </div>
        </div>
        <div className="barCode">
          <svg ref={inputRef} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border:"1px solid red"
          }}
        >
          <table id="customers" style={{width:"100%"}}>
            <thead>
              <tr >
                <th>#</th>
                <th>Vendor Name</th>
                <th>Phone Number</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {bill.payments.map((e, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{e.payId}</td>
                  <td>{e.vendorNum}</td>
                  <td>
                    <CurrencyIqFormat val={e.totalPrice || 0} />
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Total Vendors : {bill.payments.filter((c) => c.payId).length}
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Total Amount :{" "}
            <CurrencyIqFormat
              val={bill.payments
                .filter((c) => c.payId)
                .reduce((a, b) => a + b.totalPrice || 0, 0)}
            />
          </p>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            alignItems: "center",
            justifyContent: "space-around",
            fontSize: "22px",
          }}
        >
          <span>Manager Signature:</span>
          <span>accountant Signature:</span>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            alignItems: "center",
            justifyContent: "space-around",
            fontSize: "22px",
          }}
        >
          <span>Assist Signature:</span>
        </div>
      </div>
    </>
  );
};
