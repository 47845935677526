import { Button } from "../../../Components/ui/button";
import { Input } from "../../../Components/ui/input";
import { FaPlus } from "react-icons/fa";
import {manageAgentStore} from "../store";

export const Header = () => {
  const {setAddAgentModalOpen,searchTermsFilter,setSearchTermFilter,refetchGetAllAgent,setRefetchGetAllAgent}=manageAgentStore();
  return (
    <div className="flex items-end justify-between w-full px-4">
      <div>
        <Input
        value={searchTermsFilter}
        onChange={(e) => {
          if (e.target.value === "") {
            setRefetchGetAllAgent(!refetchGetAllAgent);

            setSearchTermFilter("");
            return;
          }
          setSearchTermFilter(e.target.value);
        }}          className="border border-[#D0D7DC] rounded-sm px-2 outline-none"
          placeholder="Search  "
          onKeyDown={(e) => {
            if (e.key === "Enter"&&searchTermsFilter.length>3) {
              setRefetchGetAllAgent(!refetchGetAllAgent);
            }
          }}
        />
      </div>
      <div>
        <Button className="w-fit px-2 h-8  bg-green-600  flex  justify-around items-end" onClick={()=>setAddAgentModalOpen(true)}>
          <span className="mx-2"> Add Driver</span>
          <span>
          
            <FaPlus />
          </span>
        </Button>
      </div>
    </div>
  );
};
