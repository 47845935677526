import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import Button from "../../Components/Button/Button";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { ReadExcel } from "../../Components/ReadExcel";
import { ExportExcel } from "../../Components/ExportExcel";
import { IoIosAlert } from "react-icons/io";
import Provinces from "../../Assets/DataSchema/Provinces.json";
import Select from "react-select";
import { toast } from "react-toastify";
import { AuthContext } from "../../Config/Context";
import { getSingleDoc, setDocumnet } from "../../Middleware/FirebaseFunction";
import { ordersCollections } from "../../Config/Collections";
import { TaskAPI } from "../../Middleware/TaskAPI";
import { PointAuth, PointUpload } from "../../Middleware/TamataPoint";
import { GetLatLong } from "../../Helper/GetLatLong";
import { storage } from "../../Config/Firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { Order_status } from "../../Assets/DataSchema/OrderStatus";
import { serverTimestamp } from "firebase/firestore";
import { FetchAPI } from "../../Middleware/FetchAPI";

export const BulkImport = ({ isOpen, setIsOpen }) => {
  const { setLoading, currentUser, vendors } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");

  const uploadCsv = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    ReadExcel(e, async (myFile) => {
      if (!myFile) return;

      myFile = myFile.map((r) => ({
        ...r,
        city:
          Provinces.filter((e) => r.Location?.includes(e.label))[0]?.value ||
          "",
      }));
      console.log(myFile);
      setTasks([...myFile]);
    });
  };
  const handelSubmit = async () => {
    setLoading(true);
    var Vendors_Obj = vendors.reduce((a, b) => ({ ...a, [b.value]: b }), {});

    try {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(
        `${Math.floor(Math.random() * 200000)}-${file.name}`
      );
      await fileRef.put(file);
      var url = await fileRef.getDownloadURL();

      var errors = [];
      var token = await PointAuth();
      if (!token) {
        toast.error("حدث خطا اعد التحيل");
        return;
      }
      const unresolved = tasks.map(async (e, index) => {
        let order = await getSingleDoc(ordersCollections, e.Line_task);
        if(!order){
          await setDocumnet(ordersCollections, e.Line_task, {
            city: e.city || "",
            amount: e.TotalPrice || 0,
            vendor: e.Vname || "",
            vendorId: e.vId || "",
            phone: e.phone1 || "",
            name: e.Cname || e.phone1 || "",
            lat: e.lat.toString() || "",
            long: e.long.toString() || "",
            image: url || "",
            location: e.Location || "",
            entry_status: true,
            note: e.Note || "",
            takeBy: "",
            createBy: currentUser?.name || "",
            status: Order_status.processing,
            job_description: `-`,
            createAt: serverTimestamp(),
            specialPrice:0,
            searchTerm: e.Line_task.split(/[-_]/),
          });
        }
      
        var raw = JSON.stringify({
          orderId: e.Line_task,
          merchant: e.Vname,
          cost: e.TotalPrice || 0,
          phone: `+964${e.phone1 || ""}`,
          userName: e.Cname?.toString() || e.phone1?.toString() || "",
          latitude: e.lat.toString() || "",
          longitude: e.long.toString() || "",
          address: e.Location || "",
          city: e.city || "",
          jobDescription: "-",
          note: e.Note?.toString() || "",
          shippingFee:
            Vendors_Obj[e.Vname].city == e.city
              ? Vendors_Obj[e.Vname].in_price
              : Vendors_Obj[e.Vname].out_price,
          isPickup: false,
        });
        var requestOptions = {
          method: "POST",
          headers: {
            "Authorization":`Bearer ${process.env.REACT_APP_LINEOPS_SECRET_POINT}`,
            "Content-Type":"application/json",
          },
          body: raw,
          redirect: "follow",
        };
        let response = await fetch(
          "https://us-central1-tamata-points.cloudfunctions.net/lineOps/line/fillOrder",
          requestOptions
        );
        let res = await response.json();

        if (!res) {
          errors = [...errors, e];
          console.log(errors);
          toast.error(`error at ${index + 1}`);

          return;
        }

        toast.success(index + 1);
      });
      const resolved = await Promise.all(unresolved);
      toast.success("successful upload");
      if (errors.length !== 0) ExportExcel([...errors], file.name);

      setIsOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Server Error");
      return;
    }
  };

  const updateCell = useCallback((index, cell, value) => {
    setTasks((prev) => {
      prev[index][cell] = value;
      return [...prev];
    });
  }, []);
  return (
    <>
      <Modal isOpen={isOpen} ariaHideApp={false} contentLabel="Example Modal">
        <div>
          <div className="Modal_header">
            <div className="title">
              <span> Bulk import</span>
            </div>
            <div>
              <input
                onChange={(e) => uploadCsv(e)}
                accept=".xlsx"
                id="file"
                type="file"
              ></input>
              <Button
                color="export"
                style="button"
                id="Upload"
                title="Upload"
                onclick={() => {
                  handelSubmit();
                }}
              />
            </div>
            <img
              onClick={() => setIsOpen(false)}
              width="20"
              height="30"
              src="/Images/close_modal.svg"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="work_space">
            <div className="">
              <table id="customers">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Vendor</th>
                    <th>Order</th>
                    <th>City</th>
                    <th>Location</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.length != 0 ? (
                    tasks.map((r, index) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{r.Vname}</td>
                        <td>{r.Line_task}</td>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            border: "none",
                          }}
                        >
                          <span style={{ width: "100%", border: "none" }}>
                            <Select
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  background: "transparent",
                                  minHeight: "100%",
                                }),
                              }}
                              onChange={(val) => {
                                if (!val) return;
                                setTasks((prev) => {
                                  prev[index].city = val.value;
                                  return [...prev];
                                });
                              }}
                              options={Provinces}
                              value={
                                r.city && {
                                  value: r.city,
                                  label: r.city,
                                }
                              }
                              isClearable={true}
                              placeholder="City"
                            />
                          </span>
                          <span className="alert">
                            {!r.city && <IoIosAlert />}
                          </span>
                        </td>
                        <td>
                          <span>
                            <input
                              onBlur={async (e) => {
                                let data =
                                  r.lat && r.long
                                    ? { long: r.long, lat: r.lat }
                                    : await GetLatLong(e.target.value);
                                setTasks((prev) => {
                                  prev[index].long = data?.long || "";
                                  prev[index].lat = data?.lat || "";
                                  return [...prev];
                                });
                              }}
                              onChange={(e) =>
                                updateCell(
                                  index,
                                  "customer_address",
                                  e.target.value
                                )
                              }
                              style={{
                                width: "80%",
                                height: "50px",
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              defaultValue={r.Location}
                            />
                          </span>
                          <span className="alert">
                            {(!r.long || !r.lat) && <IoIosAlert />}
                          </span>
                        </td>
                        <td>{<CurrencyIqFormat val={r.TotalPrice} />}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No Orders</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
