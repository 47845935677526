import { useContext, useState, useEffect } from "react";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { SearchInput } from "../../Components/Input/SearchInput";
import { PaidStatus } from "../../Components/paidStatus";
import {
  PaymentsCollections,
  GlobalBillingCollection,
  vendorCollection,
} from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import { totalPaidPrice } from "../../Middleware/OrdersCal";
import { Invoice } from "./Invoice";
import { ExportCSV } from "../../Components/ExportCSV";
import Select from "react-select";
import { custom_style_select } from "../../Assets/styles/custom_select";
import dayjs from "dayjs";
import {
  getAllDoc,
  getMultiDoc,
  getRangeDataByDate,
  setDocumnet,
} from "../../Middleware/FirebaseFunction";
import { limit, onSnapshot, query,  where } from "firebase/firestore";
import ClickNHold from "react-click-n-hold";
import DatePicker from "react-datepicker";
import {WebcamCapture} from "../../Components/WebcamCapture";

export const VendorsPayments = () => {
  const { setLoading, currentUser } = useContext(AuthContext);
  const [vendorsPay, setVendorPayment] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const [Payments, setPayments] = useState(null);
  const [search, setSearch] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
    const [vendorSelects, setVendorSelects] = useState([]);
  const [unPaidLength, setUnPaidLength] = useState(0);
  const getData = async () => {
    if (unPaidLength !== 0) {
      setVendorPayment((prev) => {
        prev = prev.slice(0, unPaidLength);
        return [...prev];
      });
      return;
    }
    getAllDoc(vendorCollection).then((e) => {
      setVendorSelects(e);
    });
    const q = query(PaymentsCollections, where("paid", "==", false));
    const unsubscriber = onSnapshot(q, (snapshot) => {
      var a = [];
      snapshot.docs.map((doc) => {
        a.push({ ...doc.data(), id: doc.id, isSelected: false });
      });
      a = a.sort((a, b) => new Date(b.createAt) - new Date(a.createAt));
      setUnPaidLength(a.length);
      setVendorPayment([...a]);
    });

    return unsubscriber;
  };

  const openInvoice = (e) => {
    setPayments(e);
    setIsOpen(true);
  };

  const Export_orders = async () => {
    setLoading(true);
    let orders = [];
    var data = await getRangeDataByDate(PaymentsCollections,dayjs(startDate).format("YYYY-MM-DD"),dayjs(endDate).format("YYYY-MM-DD"));
    console.log(data)
    data
      .filter((e) => e.status != "delete")
      .map((pay) => {
        pay.orders.map((od) => {
          orders.push({
            id: `T-${pay.name}${od.barcode}`,
            paymentId: pay.id||"",
            city: od.city||"",
            barcode:od.barcode||"",
            shipping:od.shipping||"",
            createAt: pay.createAt||"",
            Status: pay.paid||"",
            agent_received: od.agent_received||"",
            vendorName:pay.name||""
          });
        });
      });

    setLoading(false);
    ExportCSV(orders, "PaymentsVendors");
  };
  let options = [
    {
      label: "paid",
      value: "true",
    },
    {
      label: "un paid",
      value: "false",
    },
    {
      label: "Delete",
      value: "delete",
    },
    {
      label: "Return",
      value: "return",
    },
    {
      label: "Collection",
      value: 0,
    },
  ];
  const GeneGlobalpayments = async () => {
    setLoading(true);
    console.log("done");
    if (vendorsPay.length == 0) return;
    let mydata = vendorsPay.filter((pay) => pay.isSelected),
      pay = [];
    console.log(mydata.length);

    mydata = mydata.map((c) => {
      pay.push({
        payId: c.id,
        vendorName: c.vendor.value,
        vendorAdd: c.vendor.address,
        vendorNum: c.vendor.phone || "",
        payCreateAt: c.createAt,
        totalPrice:
          totalPaidPrice(c.orders) -
          c.orders.reduce((a, b) => a + parseInt(b.shipping), 0),
        shippingPrice:
          c.orders.reduce((a, b) => a + parseInt(b.shipping), 0),
      });
    });
    let id = `Tamata-Line-${dayjs(new Date()).format("YY-MM-DD")}-${Math.floor(
      Math.random() * 99999
    )}`;
    console.log(pay);
    await setDocumnet(GlobalBillingCollection, id, {
      createBy: currentUser.name || "",
      createAt: dayjs(new Date()).format("YYYY-MM-DD"),
      payments: [...pay],
      uid: id,
      status: "Not Paid",
    });
    setLoading(false);
  };

  const FetchVendorPay = async () => {
    setLoading(true);
    let a = await getMultiDoc(PaymentsCollections, vendor.value, "name");
    console.log(a);
    a = a.sort((a, b) => new Date(b.createAt) - new Date(a.createAt));
    setVendorPayment((prev) => [...prev, ...a]);
    setLoading(false);
  };
  useEffect( () => {
    if (!vendor) {
      getData();
      return;
    }
    FetchVendorPay();
  }, [vendor]);

  var data = vendorsPay
  .filter((e) =>
    paymentStatus?.label == "Collection"
      ? totalPaidPrice(e.orders) -
          e.orders.reduce((a, b) => a + parseInt(b.shipping), 0) <
        0
      : e.paid.toString().includes(paymentStatus?.value || e.paid)
  ).filter((e) => (startDate&&endDate ?  dayjs(startDate).format("YYYY-MM-DD")<=dayjs(e.createAt).format("YYYY-MM-DD")&& dayjs(e.createAt).format("YYYY-MM-DD")<=dayjs(endDate).format("YYYY-MM-DD") : e));
   return (
    <>
      <div>
        <div className="Header_wrapper">
          <div className="flex">
            <p>Payments</p>
            <div className="search_container">
              <SearchInput
                placeholder="Search For Invoice"
                value={search}
                onchange={(e) => setSearch(e.target.value)}
              />
            </div>
            <span style={{ fontSize: "15px", marginLeft: "20px" }}>
              <Select
                options={options}
                value={paymentStatus}
                onChange={(val) => {
                  setPaymentStatus(val);
                }}
                isClearable={true}
                styles={custom_style_select()}
              />
            </span>
            <span style={{ fontSize: "15px", marginLeft: "20px" }}>
              <Select
                options={vendorSelects}
                value={vendor}
                placeholder="Vendor Name"
                onChange={(val) => {
                  setVendor(val);
                }}
                isClearable={true}
                styles={custom_style_select()}
              />
            </span>
          </div>
          <div style={{ marginLeft: "50px" }}>
              <DatePicker
                className="datepicker-here form-control"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                withPortal
                placeholderText="From - To"
                isClearable={true}
              />
            </div>
          <div className="flex">
            <p className="Export_green" onClick={() => Export_orders()}>
              Export Orders
            </p>
            <p className="pending" onClick={() => GeneGlobalpayments()}>
              Generate Report
            </p>
          </div>
        </div>
        <div className="payments_conatiner">
          <div className="table_container">
            <div className="thead">
              <span className="small_width">
                <input
                  style={{ margin: "10px" }}
                  type={"checkbox"}
                  input={"true"}
                  onChange={(d) => {
                    setVendorPayment((prevState) => {
                      prevState = prevState.map((e) => ({
                        ...e,
                        isSelected:
                        dayjs(startDate).format("YYYY-MM-DD")<=dayjs(e.createAt).format("YYYY-MM-DD")&& dayjs(e.createAt).format("YYYY-MM-DD")<=dayjs(endDate).format("YYYY-MM-DD")
                            ? d.target.checked
                            : e.isSelected,
                      }));
                      return [...prevState];
                    });
                  }}
                />
              </span>
              <span>Payment Number</span>
              <span className="small_width">Total Orders</span>
              <span className="small_width">Paid Price</span>
              <span className="small_width">Shipping Price</span>
              <span className="small_width"> Vendor Received</span>
              <span className="small_width">Create at</span>
              <span className="small_width">Status</span>
              <span className="small_width"></span>
              <span className="small_width"></span>
            </div>
            {data.length != 0 ? (
              data
                .filter((e) => (vendor !== null ? e.name == vendor.value : e))
                .filter((e) => e.id.includes(search || ""))
                .map((e, index) => (
                  <ClickNHold
                    time={0.4}
                    onClickNHold={() => {
                      setVendorPayment((prevState) => {
                        prevState[
                          prevState.findIndex((c) => e.id == c.id)
                        ].isSelected =
                          !prevState[prevState.findIndex((c) => e.id == c.id)]
                            .isSelected;
                        return [...prevState];
                      });
                    }}
                    key={e.id + "-" + index}
                  >
                    <div
                      className={`tbody  ${e.isSelected ? "selected_row" : ""}`}
                      key={e.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span className="small_width">
                       {index + 1}
                      </span>
                      <span>{e.paymentId||e.id}</span>
                      <span className="small_width">{e.orders.length}</span>
                      <span className="small_width">
                        {<CurrencyIqFormat val={totalPaidPrice(e.orders)} />}
                      </span>
                      <span className="small_width">
                        {
                          <CurrencyIqFormat
                            val={e.orders.reduce(
                              (a, b) => a + parseInt(b.shipping),
                              0
                            )}
                          />
                        }
                      </span>
                      <span className="small_width">
                        {
                          <CurrencyIqFormat
                            val={
                              totalPaidPrice(e.orders) -
                              e.orders.reduce(
                                (a, b) => a + parseInt(b.shipping),
                                0
                              )
                            }
                          />
                        }
                      </span>
                      <span className="small_width">{e.createAt}</span>
                      <span className="small_width">
                        <PaidStatus text={e.paid} />
                      </span>
                      <span className="small_width paymentMonth">
                        {e.ordersMonth?.toString()}
                      </span>

                      <span
                        className="small_width view_btn"
                        onClick={() => openInvoice(e)}
                      >
                        View
                      </span>
                    </div>
                  </ClickNHold>
                ))
            ) : (
              <div>NO paymnets</div>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <Invoice setIsOpen={setIsOpen} isOpen={isOpen} payments={Payments} />
      )}
    <WebcamCapture isWebcamOpen={false}/>
    </>
  );
};
