import { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../Config/Context";
import Button from "../../Components/Button/Button";
import { PendingPickUp } from "./PendingPickUp";
import { FetchCpickups } from "../../Middleware/FetchCpickups";
import ReactToPrint from "react-to-print";

import { toast } from "react-toastify";
import { ReceiptPrint } from "../../Components/Layout/ReceiptPrint";
import "../../Assets/styles/A5stylee.css";
import { ExportExcel } from "../../Components/ExportExcel";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { Permission_type } from "../../Config/Permission";
import { IoIosAlert } from "react-icons/io";
import { getSingleDoc, setDocumnet } from "../../Middleware/FirebaseFunction";
import { entryArchive, ordersCollections } from "../../Config/Collections";
import { Order_status } from "../../Assets/DataSchema/OrderStatus";
import { serverTimestamp } from "firebase/firestore";
import { LineCoreStatus } from "../../Assets/DataSchema/LineCoreStatus";
import { useQuery } from "@tanstack/react-query";
import { fetchFmTasks } from "../../Services/Fetchers";
import DatePicker from "react-datepicker";
export const PickUp = () => {
  const { setLoading, currentUser, vendors } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [pickUps, setPickUps] = useState([]);
  const [invoicePrint, setInvoicePrint] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const refComp = useRef();

  const { refetch } = useQuery({
    queryKey: ["fetchFmTasks", LineCoreStatus.Completed,dateRange],
    queryFn: async () => {
      setLoading(true)
      const response = await fetchFmTasks(0, 1000, LineCoreStatus.Completed,startDate,endDate);
    
      if (response.length == 0) {
        setLoading(false)
        return "Error";
      }
      
      console.log(response);
      setPickUps(response.data);
      setLoading(false)
      return response;
    },
  });
  const UploadDelivery = async () => {
    let deliveries = pickUps.filter((r) => r.isSelected);
    if (deliveries.length == 0) return;
    try {
      setLoading(true);
      let errorUpload = [];
      var Vendors_Obj = vendors.reduce((a, b) => ({ ...a, [b.value]: b }), {});

      const unresolvedPoint = deliveries.map(async (e, index) => {
        console.log(e);

        var raw = JSON.stringify({
          orderId: e.barcode.trim(),
          merchant: e.vendor,
          cost: Number(e.amount) || 0,
          phone: `+964${e.phone || ""}`,
          userName: e.name?.toString() || e.phone?.toString() || "",
          latitude: e.lat.toString() || "",
          longitude: e.lang.toString() || "",
          address: e.location || "",
          city: e.city || "",
          jobDescription: `false`,
          note: "",
          shippingFee:0,
          isPickup: true,
        });
        var requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_LINEOPS_SECRET_POINT}`,
            "Content-Type": "application/json",
          },
          body: raw,
          redirect: "follow",
        };
        let response = await fetch(
          `${process.env.REACT_APP_POINT_BASE_URL}/lineOps/line/fillOrder`,
          requestOptions
        );
        let res = await response.json();
        let order = await getSingleDoc(ordersCollections, e.barcode);
        if (!order) {
          await setDocumnet(ordersCollections, e.barcode, {
            city: e.city,
            amount: e.amount || 0,
            vendor: e.vendor || "",
            vendorId: e.vendorId || "",
            phone: e.phone || 0,
            name: e.name || e.phone,
            lat: e.lat.toString() || "",
            long: e.lang.toString() || "",
            image: "",
            location: e.location || "",
            entry_status: true,
            takeBy: "",
            createBy: currentUser?.name || "",
            status: Order_status.processing,
            job_description: ``,
            createAt: serverTimestamp(),
            searchTerm: e.barcode.split(/[-_]/),
          });
        }

        if (order || !res) {
          errorUpload = [...errorUpload, e];
          toast.error(`error at ${index + 1}`);
          return;
        }
        deliveries[index].isNew = true;
        toast.success(index + 1);
      });
      const resolvedPoint = await Promise.all(unresolvedPoint);

      setPickUps((r) => {
        r = r.filter((e) => !e.isSelected);
        return [...r];
      });
      if (errorUpload.length !== 0) ExportExcel(errorUpload, "delivery");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.success("Server Error");
    }
  };
  const Print = () => {
    setInvoicePrint((prev) => [
      ...prev,
      ...pickUps.filter((e) => e.isSelected).map((e) => e.id),
    ]);

    localStorage.setItem(
      "receiptPrint",
      JSON.stringify([
        ...(JSON.parse(localStorage.getItem("receiptPrint")) || []),
        ...pickUps.filter((e) => e.isSelected).map((e) => e.id),
      ])
    );
    return refComp.current;
  };
  return (
    <>
      <div className="pick_up">
        <div className="p_header">
          <div className="flex">
            
            <p >Pick Up &#38; Delivery</p>
            <div style={{ marginLeft: "50px" }}>
              <DatePicker
                className="datepicker-here form-control"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                withPortal
                placeholderText="From - To"
                isClearable={true}
              />
            </div>
            <ReactToPrint
              trigger={() => (
                <button className="print-button">
                  <span className="print-icon"></span>
                </button>
              )}
              content={() => {
                return Print();
              }}
            />
          </div>

          <div>
            {/* <PermissionsGate permission={Permission_type.upload_task}> */}
            <Button
              style="button"
              color="c8597A5"
              title="Upload Delivery"
              onclick={() => UploadDelivery()}
            />
            {/* </PermissionsGate> */}

            <Button
              style="button"
              color="c70b44d"
              title="Pending  PickUps"
              onclick={() => setIsOpen(true)}
            />
          </div>
        </div>

        <div className="payments_conatiner">
          <div className="table_container">
            <div className="thead">
              <span className="small_width">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setPickUps((prevState) => {
                      prevState = prevState.map((d) => ({
                        ...d,
                        isSelected: e.target.checked,
                      }));
                      return [...prevState];
                    })
                  }
                />
              </span>
              <span>Order ID</span>
              <span>ِAmount</span>
              <span className="small_width">City</span>
              <span>Location</span>
              <span>Vendor</span>
              <span></span>
            </div>
            {pickUps?.length != 0 ? (
              pickUps.map((e, index) => (
                <div className="tbody" key={index + 1}>
                  <span className="small_width">
                    <input
                      type="checkbox"
                      defaultChecked={e.isSelected}
                      onChange={(d) =>
                        setPickUps((prevState) => {
                          prevState[
                            prevState.findIndex((c) => e.id == c.id)
                          ].isSelected = d.target.checked;
                          return [...prevState];
                        })
                      }
                    />
                  </span>
                  <span>{e.id}</span>
                  <span>{e.amount}</span>
                  <span className="small_width">{e.city}</span>
                  <span>{e.location}</span>
                  <span>{e.vendor}</span>
                  <span className="alert">
                    {invoicePrint.includes(e.id) && <IoIosAlert />}
                  </span>
                </div>
              ))
            ) : (
              <div>No Orders</div>
            )}
          </div>
        </div>
        {isOpen && <PendingPickUp setIsOpen={setIsOpen} isOpen={isOpen} />}
      </div>
      <div id="Invoice_print" ref={refComp}>
        {pickUps
          ?.filter((e) => e.isSelected)
          .map((e, index) => (
            <ReceiptPrint key={index} task={e} id={e.barcode} />
          ))}
      </div>
    </>
  );
};
