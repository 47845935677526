import React, { useState, useCallback, useContext } from "react";
import { PendingReceipt } from "./PendingReceipt";
import { Carousel } from "react-responsive-carousel";
import ImageViewer from "react-simple-image-viewer";
import { CurrencyInput } from "../../Components/Input/CurrencyInput";
import Select from "react-select";
import { custom_select_country } from "../../Assets/styles/custom_select";
import Provinces from "../../Assets/DataSchema/Provinces.json";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { toast } from "react-toastify";
import {
  getSingleDoc,
  setDocumnet,
  updateArrayDoc,
  updateDocument,
} from "../../Middleware/FirebaseFunction";
import {
  deilveryPCollection,
  entryArchive,
  ordersCollections,
} from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import dayjs from "dayjs";
import { IoIosAlert } from "react-icons/io";
import { BulkImport } from "./BulkImport";
import ImgsViewer from "react-images-viewer";
import { PointAuth, PointUpload } from "../../Middleware/TamataPoint";
import { TaskAPI } from "../../Middleware/TaskAPI";
import { GetLatLong } from "../../Helper/GetLatLong";
import { Order_status } from "../../Assets/DataSchema/OrderStatus";
import {serverTimestamp} from "firebase/firestore";
import {isValueBetweenValues} from "../../Helper/isValueBetweenValues";

export const DataEntry = () => {
  const { setLoading, currentUser ,vendors} = useContext(AuthContext);
  const [pendingOpen, setPendingOpen] = useState(false);
  const [bulkOpen, setBulkOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [Tasks, setTasks] = useState([]);
  const [batchID, setBatID] = useState(null);
  const[vendorInfo,setVendorInfo]=useState(null)

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const updateCell = useCallback((index, cell, value) => {
    setTasks((prev) => {
      prev[index][cell] = value;
      return [...prev];
    });
  }, []);
  const uploadData = async () => {

    var Vendors_Obj = vendors.reduce((a, b) => ({ ...a, [b.value]: b }), {});

    if (
      Tasks.length == 0 ||
      Tasks.filter(
        (r) =>
          !r.entry_status &&
          r.barcode &&
          (!r.phone || !r.city || !r.location || !r.longLat)
      ).length !== 0
    ) {
      toast.error("اكمل كل الحقول");
      return;
    }
    try {
      setLoading(true);
      let completeTasks = Tasks.map((e) => ({
        image: e.image,
        entry_status: (e.barcode && !e.entry_status ) || false,
      }));

      console.log(completeTasks);
      var errors = [];
   
      const unresolved = Tasks.filter(
        (e) => !e.entry_status && e.barcode 
      ).map(async (e, index) => {
    
        var raw = JSON.stringify({
          orderId: e.barcode.trim(),
          merchant: e.vendor,
          cost: e.amount || 0,
          phone: `+964${e.phone || ""}`,
          userName: e.name?.toString() || e.phone?.toString() || "",
          latitude: e.longLat.lat.toString() || "",
          longitude: e.longLat.long.toString() || "",
          address: e.location || "",
          city: e.city || "",
          jobDescription: `${e.isSpecial || ""}-${e.specialPrice || ""}`,
          note:  "",
          shippingFee:
          Vendors_Obj[e.vendor].city==e.city?Vendors_Obj[e.vendor].in_price:Vendors_Obj[e.vendor].out_price,
          isPickup: false,
        });
        var requestOptions = {
          method: "POST",
          headers: {
            "Authorization":`Bearer ${process.env.REACT_APP_LINEOPS_SECRET_POINT}`,
            "Content-Type":"application/json",
          },
          body: raw,
          redirect: "follow",
        };
        let response = await fetch(
          "https://us-central1-tamata-points.cloudfunctions.net/lineOps/line/fillOrder",
          requestOptions
        );
        let res = await response.json();

        let order = await getSingleDoc(ordersCollections, e.barcode);
        if(!order){
          await setDocumnet(ordersCollections, e.barcode, {
            city: e.city,
            amount: e.amount || 0,
            vendor: e.vendor || "",
            vendorId: e.vendorId || "",
            phone: e.phone || 0,
            name: e.name || e.phone,
            lat: e.longLat.lat.toString() || "",
            long: e.longLat.long.toString() || "",
            image: e.image,
            location: e.location || "",
            entry_status: true,
            takeBy: e.takeBy,
            job_description: `${e.isSpecial || ""}-${e.specialPrice || ""}`,
            createBy: currentUser?.name || "",
            status: Order_status.processing,
            specialPrice:e.specialPrice,
            createAt: serverTimestamp(),
            searchTerm: e.barcode.split(/[-_]/),
          });
        }

    
        if (!res ) {
          errors = [...errors, e];
          completeTasks.find(
            (order) => order.image == e.image
            ).entry_status = false;
            console.log(errors);
            toast.error(`error at ${index + 1}`);
          return;
        }


        toast.success(index + 1);
      });
      await updateDocument(
        entryArchive,
        batchID,
        "completeData",
        completeTasks
      );
      if (completeTasks.filter((e) => !e.entry_status).length == 0) {
        await updateDocument(entryArchive, batchID, "entry_complete", true);
      }
      const resolved = await Promise.all(unresolved);
      toast.success("Succssful");
      setTasks([]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Server Error");
    }
  };

  const getLocation = async (e, index) => {
    let { lat, long } = await GetLatLong(e);
    updateCell(index, "longLat", lat ? { lat, long } : null);
    if (!lat || !long) {
      toast.error("لم يتم تحديد الموقع");
      return;
    }
    toast.success("تم تحديد الموقع بنجاح");
  };

  let images = Tasks?.map((e) => e.image);
  window.addEventListener("beforeunload", (event) => {
    event.returnValue = `Are you sure you want to leave?`;
  });
  const checkBarcodeExsist = async (index, e) => {
    let barcodeExist = await getSingleDoc(ordersCollections, e);
    setTasks((prev) => {
      prev[index].alert = barcodeExist ? true : false;
      return [...prev];
    });
  };
  return (
    <>
      <div className="entry_menu">
        <div style={{display:"flex"}}>
          <p className="work">Work Space</p>

          <p className="export" onClick={() => uploadData()}>
            Upload Data
          </p>
        </div>
        <div  style={{display:"flex"}}>
          <p className="delivery" onClick={() => setBulkOpen(true)}>
            Bulk File
          </p>
          <p className="pending" onClick={() => setPendingOpen(true)}>
            Pending Receipt
          </p>
        </div>
      </div>

      <div className="work_space">
        {images.length != 0 && (
          <>
            <div className="work_container">
              <table id="customers">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Barcode</th>
                    <th>City</th>
                    <th>Location</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {Tasks?.map((e, index) => (
                    <tr key={index + 1} onClick={() => setCurrentImage(index)}>
                      <td
                        onClick={() => {
                          if (!e.entry_status) openImageViewer(index);
                        }}
                      >
                        {index + 1}
                      </td>
                      <td>
                        <input
                          disabled={e.entry_status}
                          className="row_task"
                          defaultValue={e.name || e.phone}
                          onChange={(e) =>
                            updateCell(index, "name", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          disabled={e.entry_status}
                          className="row_task"
                          defaultValue={e.phone}
                          onChange={(e) =>
                            updateCell(index, "phone", e.target.value)
                          }
                        />
                        {e.phone?.length !== 11 && <IoIosAlert />}
                      </td>
                      <td
                        className={`${
                          Tasks.filter(
                            (r) => r.barcode == e.barcode && r.barcode
                          ).length > 1 || e.alert
                            ? "alert_cell"
                            : ""
                        } row_task`}
                      >
                        <input
                          disabled={e.entry_status}
                          className="row_task"
                          defaultValue={e.barcode}
                          onBlur={(e) =>
                            checkBarcodeExsist(index, e.target.value)
                          }
                          onChange={(e) =>
                            updateCell(index, "barcode", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <Select
                          isDisabled={e.entry_status}
                          options={Provinces}
                          defaultValue={e.city}
                          onChange={(e) => {
                            if (!e) {
                              updateCell(index, "location", "");
                            }
                            updateCell(index, "city", e?.value);
                          }}
                          name="colors"
                          isClearable={true}
                          placeholder="Country"
                          styles={custom_select_country()}
                        />
                      </td>
                      <td>
                        <input
                          disabled={e.entry_status}
                          className="row_task"
                          onBlur={(r) => {
                            updateCell(
                              index,
                              "location",
                              r.target.value == "" ? e.city : r.target.value
                            );
                            getLocation(
                              r.target.value == "" ? e.city : r.target.value,
                              index
                            );
                          }}
                          onChange={(e) =>
                            updateCell(index, "location", e.target.value)
                          }
                          value={e.location || ""}
                        />

                        <span className="alert">
                          {(!e.location ||
                            !e.city ||
                            Object.keys(e.longLat || {}).length === 0) && (
                            <IoIosAlert />
                          )}
                        </span>
                      </td>
                      <td>
                        {
                          <CurrencyInput
                            style={`default_input`}
                            disabled={e.entry_status}
                            value={e.amount}
                            onchange={(e) =>
                              updateCell(index, "amount", e.floatValue)
                            }
                          />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {images.length !== 0 && (
          <div className="slider">
            <Carousel
              showArrows={true}
              autoFocus={true}
              selectedItem={currentImage}
            >
              {images?.map((e, index) => (
                <div key={index + 1} onClick={() => openImageViewer(index)}>
                  <img src={e} />
                  <p className="legend"> {index + 1}</p>
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </div>
      {pendingOpen && (
        <PendingReceipt
          isOpen={pendingOpen}
          setIsOpen={setPendingOpen}
          setTasks={setTasks}
          setBatID={setBatID}
          setVendorInfo={setVendorInfo}
        />
      )}
      {bulkOpen && <BulkImport isOpen={bulkOpen} setIsOpen={setBulkOpen} />}
      <ImgsViewer
        imgs={[...images.map((e) => ({ src: e }))]}
        isOpen={isViewerOpen}
        onClickPrev={() => setCurrentImage((e) => e - 1)}
        onClickNext={() => setCurrentImage((e) => e + 1)}
        onClose={() => closeImageViewer()}
        currImg={currentImage}
        backdropCloseable={true}
      />
    </>
  );
};
