import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};


const app = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = firebase.storage();
export const auth = app.auth();

const agentsFirebaseConfig = {
  apiKey: "AIzaSyAuS6ND_GQq0z3CMwYgloriSLwuaLiwawg",
  authDomain: "tamata-agent.firebaseapp.com",
  projectId: "tamata-agent",
  storageBucket: "tamata-agent.appspot.com",
  messagingSenderId: "607706971875",
  appId: "1:607706971875:web:bc0e44700029adf842cfd6",
};
const agentsApp = firebase.initializeApp(agentsFirebaseConfig, "secondary");
export const agentsDb = getFirestore(agentsApp);
export const agentsStorage = firebase.storage(agentsApp);
